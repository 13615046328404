import React, { useContext } from "react";
import { StateContext } from "../../../context/StateContext";
import { Dropdown, Table } from "react-bootstrap";
import LoadingScreen from "../../../components/LoadingScreen/V1";
import { useEffect } from "react";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import { _delete } from "../../../utils/apiFunctions";

export default function Index() {
  const history = useHistory();
  const { productTypeList, isLoading, fetchProductTypeList } =
    useContext(StateContext);
  useEffect(() => {
    fetchProductTypeList();
  }, []);
  const handelDelete = async (productTypeId) => {
    await _delete(`/admin/product-type?product_type_id=${productTypeId}`).then(
      (_) => {
        fetchProductTypeList();
        alert.toString(`deleted - ${productTypeId}`);
      }
    );
  };
  return (
    <>
      {productTypeList?.length > 0 ? (
        <Table striped hover responsive>
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Type Id</th>
              <th>Product Type Name</th>
              <th>Perishable</th>
              <th>Subscription</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {productTypeList?.map((productType, idx) => {
              return (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{productType?.product_type_id}</td>
                  <td>{productType?.name}</td>
                  <td>
                    {productType?.is_perishable
                      ? "Perishable"
                      : "Non-Perishable"}
                  </td>
                  <td>
                    {productType?.is_subscriptionable ? "Enabled" : "Disabled"}
                  </td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="dark" id="dropdown-basic">
                        <ThreeDotsVertical />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            history.push(
                              `/editProductType/${productType?.product_type_id}`
                            )
                          }
                        >
                          <div className="text-dark">Edit</div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            // handelDelete(productType?.product_type_id)
                            alert("delete is not activated")
                          }
                        >
                          <div className="text-danger">Delete</div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : isLoading ? (
        <LoadingScreen />
      ) : (
        <div>No Catagories</div>
      )}
    </>
  );
}
