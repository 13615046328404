import React, { createContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userAttributes, setUserAttributes] = useState(null);
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [token, setToken] = useState({})
  const [idToken, setIdToken] = useState(null);

  async function fetchUser() {
    try {
      const session = await Auth.currentSession(); //returns tokens
      setToken(session?.idToken)
      setIdToken(session?.idToken?.jwtToken);
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      setUser(authenticatedUser);
      // console.log("fetch user > ", authenticatedUser);
      setIsAuthenticated(true);
      setUserAttributes(authenticatedUser?.attributes);
      return authenticatedUser;
    } catch (error) {
      console.log("fetchUser >", error);
    }
  }
  useEffect(() => {
    //prevent logged in user from log out
    fetchUser().then((_) => setIsAuthenticating(false));
  }, []);

  async function signIn(username, password) {
    try {
      const user = await Auth.signIn(username, password);
      setUser(user);
      setUserAttributes(user?.attributes);
      setIdToken(user?.signInUserSession?.idToken?.jwtToken);
      // console.log("signIn > ", user);
      console.log(user);
      if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
        return {
          statusCode: 201,
          user,
        };
      } else {
        setIsAuthenticated(true);
      }
      return { statusCode: 200, user };
    } catch (error) {
      console.log("error signing in", error);
      return { statusCode: 400, error };
    }
  }

  const signOut = async () => {
    await Auth.signOut()
      .then((res) => {
        console.log("signout res", res);
        setIsAuthenticated(false);
        setUser(null);
      })
      .catch((err) => {
        console.log("err in signout", err);
      });
  };

  async function newPasswordChallenge(newPassword) {
    console.log("user new password challenge > ", user);
    if (user) {
      try {
        const response = await Auth.completeNewPassword(user, newPassword);
        console.log("newPasswordChallenge > ", response);
        return { statusCode: 200, response };
      } catch (error) {
        console.log("error newPasswordChallenge in", error);
        return { statusCode: 400, error };
      }
    } else {
      const error = "error in newPasswordChallenge > user not found ";
      console.log(error);
      return { statusCode: 400, error };
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        userAttributes,
        isAuthenticated,
        isAuthenticating,
        setIsAuthenticated,
        signIn,
        signOut,
        newPasswordChallenge,
        tokens: {
          idToken,
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
