import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "../../context/StateContext";
import { AreaContext } from "../../context/AreaContext";
import CountCard from "../../components/HomeWidget/Card";
import "./style.css";
import { Link } from "react-router-dom";
import { get } from "../../utils/apiFunctions";
import TopSellingProducts from "./widget/topSellingProducts";

export default function Index() {
  const { count, setCount } = useContext(StateContext);
  async function fetchHomeWidgetData() {
    await get(`/admin/home/widgets`).then(([status, res]) => {
      setCount(res);
      console.log("homewidget", res);
    });
  }
  useEffect(() => {
    fetchHomeWidgetData();
  }, []);
  return (
    <div>
      <div className="count-card-wrapper">
        <Link to="/inventory">
          <CountCard count={count?.["products"]} title="Inventory" bg="red" />
        </Link>

        <Link to="/salesOrder/new">
          <CountCard
            count={count?.["new_orders"]}
            title="New Orders"
            bg="yellow"
          />
        </Link>
        <Link to="/salesOrder/approved">
          <CountCard
            count={count?.["approved_products"]}
            title="Approved Orders"
            bg="black"
          />
        </Link>
        <Link to="/salesOrder/dispatched">
          <CountCard
            count={count?.["dispatched_products"]}
            title="Dispatched Orders"
            bg="purple"
          />
        </Link>
        {/* <Link
        // to="/pendingSalesOrders"
        >
          <CountCard
            count={count?.["this_month_sale"]}
            title="Monthly Sales"
            bg="green"
          />
        </Link> */}
        <Link
        // to="/pendingSalesOrders"
        >
          <CountCard
            count={count?.["active_customers"]}
            title="Active Customers"
            bg="blue"
          />
        </Link>
      </div>
      {count?.top_selling_product?.length > 0 && (
        <TopSellingProducts products={count?.top_selling_product} />
      )}
    </div>
  );
}
