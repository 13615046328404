import React from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import NumberField from "../../../components/InputField/Number";
import { useContext } from "react";
import { StateContext } from "../../../context/StateContext";
import { AreaContext } from "../../../context/AreaContext";
import { useEffect } from "react";
import { addProduct } from "../../../utils/apiFunctions/inventory";
import { AuthContext } from "../../../context/AuthContext";

export default function Index() {
  const history = useHistory();
  const { productTypeList, fetchProductTypeList } = useContext(StateContext);
  const { siteList, fetchSiteList } = useContext(AreaContext);
  const { userAttributes } = useContext(AuthContext);
  const schema = yup.object().shape({
    product_type_id: yup.string().required("Field required"),
    qty: yup.number().required("Field required"),
    site_id: yup.number().required("Field required"),
    for_sales: yup.number().required("Field required"),
    production_cost_per_unit: yup.number().required("Field required"),
    expiry_date: yup.string(),
    added_by: yup.string(),
    retail_price: yup.number().required("Field required")
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur"
  });

  const hookFormProp = { errors: errors, reg: register };
  useEffect(() => {
    fetchProductTypeList();
    fetchSiteList();
    setValue("added_by", userAttributes?.sub);
  }, [userAttributes]);

  const onSubmit = async (formData, e) => {
    await addProduct(formData).then(history.push("/inventory"));

    // console.log(formData);
  };

  return (
    <div>
      <div className="m-page-title mb-3">Add Product</div>
      <Link to="/inventory">
        <Button variant="outline-danger" block>
          Inventory
        </Button>
      </Link>
      <hr />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Product Type</Form.Label>

            <Form.Control
              as="select"
              type="number"
              placeholder="Product Type"
              name="product_type_id"
              {...register("product_type_id")}
              className={`${errors?.product_type_id && "form-error-field"}`}
            >
              <option value="" key="0">
                Select Product Type
              </option>
              {productTypeList.map((productType, index) => (
                <option value={productType?.product_type_id} key={index}>
                  {productType?.name} ({productType?.product_type_id})
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <NumberField {...hookFormProp} name="qty" label="Quantity" />
        </Form.Row>

        <Form.Row>
          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Site</Form.Label>

            <Form.Control
              as="select"
              type="number"
              placeholder="For Sales"
              name="site_id"
              {...register("site_id")}
              className={`${errors?.site_id && "form-error-field"}`}
            >
              <option value="" key="0">
                Select Site
              </option>
              {siteList.map((site, index) => (
                <option value={site?.site_id} key={index}>
                  {site?.site_name} ({site?.site_code})
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <NumberField
            inputProp={{ step: "0.01" }}
            name="production_cost_per_unit"
            label="Process or Production cost / unit"
            {...hookFormProp}
          />

          <NumberField
            inputProp={{ step: "0.01" }}
            name="retail_price"
            label="Retail Price Per Unit"
            {...hookFormProp}
          />
        </Form.Row>

        <Form.Row>
          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Expiry date</Form.Label>

            <Form.Control
              type="date"
              placeholder="Expiry Date"
              name="expiry_date"
              {...register("expiry_date")}
              className={`${errors?.expiry_date && "form-error-field"}`}
            />
          </Form.Group>

          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>For Sales</Form.Label>

            <Form.Control
              as="select"
              type="number"
              placeholder="For Sales"
              name="for_sales"
              {...register("for_sales")}
              className={`${errors?.for_sales && "form-error-field"}`}
            >
              <option value={0} key="0">
                No
              </option>
              <option value={1} key="1">
                Yes
              </option>
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Button variant="success" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}
