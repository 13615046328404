import React, { createContext, useContext, useState } from "react";
import { useFormState } from "react-hook-form";
import { get } from "../utils/apiFunctions";
import { AuthContext } from "./AuthContext";

export const EcomContext = createContext();

export const EcomProvider = ({ children }) => {
  const { tokens } = useContext(AuthContext);
  // const [newProducts, setNewProducts] = useState([]);
  const [existingProducts, setExistingProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // async function fetchNewProductsFromInventory() {
  //   setIsLoading(true);
  //   // const query = ["new_product=1"].join("&");
  //   const res = await get(
  //     `/admin/ecommerce?new_product=1`,
  //     tokens?.idToken
  //   ).then(([status, res]) => {
  //     if (status === 200) {
  //       setNewProducts(res);
  //       setIsLoading(false);
  //       return res;
  //     }
  //     console.log(status, res);
  //   });
  //   return res;
  // }
  async function fetchExistingProducts() {
    setIsLoading(true);
    // const query = [].join("&");
    const res = await get(`/admin/ecommerce`, tokens?.idToken).then(
      ([status, res]) => {
        if (status === 200) {
          setExistingProducts(res);
          setIsLoading(false);
          return res;
        }
        console.log(status, res);
      }
    );
    return res;
  }

  return (
    <EcomContext.Provider
      value={{
        isLoading,
        existingProducts,
        fetchExistingProducts,
      }}
    >
      {children}
    </EcomContext.Provider>
  );
};
