import React, { useContext } from "react";
import "./style.css";
import profileImgPlaceholder from "../../assets/images/account_img_placeholdere.png";
import { AuthContext } from "../../context/AuthContext";
export default function Index() {
  const { userAttributes } = useContext(AuthContext);
  return (
    <>
      <div className="m-page-title">My Profile</div>
      <div className="container emp-profile">
        <div className="row">
          <div className="col-md-4">
            <div className="profile-img">
              <img src={profileImgPlaceholder} alt="" />
            </div>
          </div>
          <div className="col-md-6 ml-5">
            <div className="profile-head">
              <h5>{userAttributes?.name}</h5>
              <h6>{userAttributes?.["custom:custom:role"]}</h6>
              {/* <p className="proile-rating">
                RANKINGS : <span>8/10</span>
              </p> */}
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <span
                    className="nav-link active text-dark"
                    id="home-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    About
                  </span>
                </li>
                {/* <li className="nav-item">
                  <span
                    className="nav-link text-dark"
                    id="profile-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Timeline
                  </span>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-md-2">
            {/* <input
              type="submit"
              className="profile-edit-btn"
              name="btnAddMore"
              value="Edit Profile"
            /> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            {/* <div className="profile-work">
              <p>WORK LINK</p>
              <a href="">Website Link</a>
              <br />
              <a href="">Bootsnipp Profile</a>
              <br />
              <a href="">Bootply Profile</a>
              <p>SKILLS</p>
              <a href="">Web Designer</a>
              <br />
              <a href="">Web Developer</a>
              <br />
              <a href="">WordPress</a>
              <br />
              <a href="">WooCommerce</a>
              <br />
              <a href="">PHP, .Net</a>
              <br />
            </div> */}
          </div>
          <div className="col-md-8 mt-3">
            <div className="tab-content profile-tab" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="row">
                  <div className="col-md-6">
                    <label>User Id</label>
                  </div>
                  <div className="col-md-6">
                    <p>{userAttributes?.sub}</p>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-md-6">
                    <label>Email</label>
                  </div>
                  <div className="col-md-6">
                    <p>{userAttributes?.email}</p>
                  </div>
                </div>
                
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div className="row">
                  <div className="col-md-6">
                    <label>Experience</label>
                  </div>
                  <div className="col-md-6">
                    <p>Expert</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>Hourly Rate</label>
                  </div>
                  <div className="col-md-6">
                    <p>10$/hr</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>Total Projects</label>
                  </div>
                  <div className="col-md-6">
                    <p>230</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>English Level</label>
                  </div>
                  <div className="col-md-6">
                    <p>Expert</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>Availability</label>
                  </div>
                  <div className="col-md-6">
                    <p>6 months</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label>Your Bio</label>
                    <br />
                    <p>Your detail description</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
