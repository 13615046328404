export const getTime = (hrs = 24) => {
  const temp = new Date();
  let time;
  if (hrs === 24) {
    time = temp.toTimeString().split(" ")[0];
  } else {
    time = temp.toLocaleTimeString();
  }
  return time;
};

export const getTimeStamp = () => {
  const temp = new Date();
  return temp.getTime();
};

export const dbTimeToLocal = (datetime: any) => {
  if (datetime) {
    let date = datetime?.replace(/-/g, "/");
    const d = new Date(date?.split(".")[0] + " UTC");
    return { date: d.toLocaleDateString(), time: d.toLocaleTimeString() };
  } else {
    return { date: "", time: "" };
  }
};

interface convertDateProps {
  date: Date;
  format?: "yyyy-mm-dd" | "dd-mm-yyyy" | "mm-dd-yyyy";
}
export const convertDate = ({ date, format }: convertDateProps) => {
  if (date) {
    let month = (date.getMonth() + 1).toString();
    let d = {
      dd: date.getDate(),
      yyyy: date.getFullYear(),
      mm: month?.length === 1 ? `0${month}` : month
    };
    switch (format) {
      case "yyyy-mm-dd":
        return `${d.yyyy}-${d.mm}-${d.dd}`;
      case "dd-mm-yyyy":
        return `${d.dd}-${d.mm}-${d.yyyy}`;
      case "mm-dd-yyyy":
        return `${d.mm}-${d.dd}-${d.yyyy}`;
      default:
        return `${d.yyyy}-${d.mm}-${d.dd}`;
    }
  }
};

export const getDate = (format: "yyyy-mm-dd" | "dd-mm-yyyy" | "mm-dd-yyyy") => {
  return convertDate({ date: new Date(), format: format });
};
