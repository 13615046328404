import { Button } from "react-bootstrap";
import React, { useState } from "react";
import "./style.css";
import CarousalList from "./componenets/CarousalList";
import AddCarousalModal from "./componenets/AddCarousalModal";
export default function Index() {
  const [showAddCarousalModal, setShowAddCarousalModal] = useState(false);
  return (
    <>
      <div className="m-page-title mb-2">Carousal</div>
      <Button
        variant="outline-dark"
        block
        onClick={() => {
          setShowAddCarousalModal(true);
        }}
      >
        + Add Carousal
      </Button>

      <CarousalList />
      <AddCarousalModal
        isVisible={showAddCarousalModal}
        handleVisible={() => setShowAddCarousalModal(false)}
      />
    </>
  );
}
