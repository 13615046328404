import React from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TextField from "../../../components/InputField/Text";
import NumberField from "../../../components/InputField/Number";
import { useContext } from "react";
import { StateContext } from "../../../context/StateContext";
import { useEffect } from "react";
import { useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { patch, post } from "../../../utils/apiFunctions";

export default function Index({ productTypeId }) {
  const history = useHistory();
  const { userAttributes } = useContext(AuthContext);
  const {
    categoryList,
    fetchUnitList,
    fetchCategoryList,
    unitList,
    productTypeList
  } = useContext(StateContext);
  const [attributesList, setAttributesList] = useState([]);

  useEffect(() => {
    fetchCategoryList();
    fetchUnitList();
  }, []);

  const schema = yup.object().shape({
    category_id: yup.number(),
    unit_id: yup.number(),
    name: yup.string().required("field required"),
    image_url: yup.string(),
    added_by: yup.string(),
    is_subscriptionable: yup.number(),
    is_perishable: yup.number(),
    inventory_based: yup.number(),
    is_packed: yup.number(),
    package_qty: yup.number(),
    package_qty_unit: yup.number()
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur"
  });
  const isPacked = watch("is_packed");
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      console.log(value, name, type)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (productTypeId) {
      const temp = productTypeList?.filter(
        (product) => product?.product_type_id == productTypeId
      )[0];
      setValue("category_id", temp?.category_id || "");
      setValue("unit_id", temp?.unit_id || "");
      setValue("name", temp?.name || "");
      setValue("image_url", temp?.image_url || "");
      setValue("added_by", temp?.added_by || "");
      setValue("is_subscriptionable", temp?.is_subscriptionable || 0);
      setValue("is_perishable", temp?.is_perishable || 0);
      setValue("inventory_based", temp?.inventory_based || 0);
      setValue("is_packed", temp?.is_packed || 0);
      setValue("package_qty", temp?.package_qty || 0);
      setValue("package_qty_unit", temp?.package_qty_unit || 0);
    } else {
      setValue("added_by", userAttributes?.sub || "");
    }
  }, []);

  const hookFormProp = { errors: errors, reg: register };

  const onSubmit = async (formData, e) => {
    if (productTypeId) {
      // console.log(formData);
      await patch(
        `/admin/product-type?product_type_id=${productTypeId}`,
        formData
      ).then((_) => {
        history.push("/productType");
      });
    } else {
      await post(`/admin/product-type`, formData).then((_) =>
        history.push("/productType")
      );
    }
    // console.log(formData);
  };

  return (
    <div>
      <div className="m-page-title mb-3">Add Product Type</div>
      <Link to="/productType">
        <Button variant="outline-danger" block>
          Product Type
        </Button>
      </Link>
      <hr />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Category</Form.Label>

            <Form.Control
              as="select"
              type="number"
              placeholder="Category"
              name="category_id"
              {...register("category_id")}
              className={`${errors?.category_id && "form-error-field"}`}
            >
              <option disabled key="-1">
                Select Category
              </option>
              {categoryList.map((category, index) => (
                <option value={category?.category_id} key={index}>
                  {category?.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <TextField {...hookFormProp} name="name" label="Product Type Name" />
        </Form.Row>

        <Form.Row>
          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Unit</Form.Label>

            <Form.Control
              as="select"
              type="number"
              placeholder="Category"
              name="unit_id"
              {...register("unit_id")}
              className={`${errors?.unit_id && "form-error-field"}`}
            >
              <option disabled value="" key="-1">
                Select Category
              </option>
              {unitList.map((unit, index) => (
                <option value={unit?.unit_id} key={index}>
                  {unit?.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Inventory Based</Form.Label>

            <Form.Control
              as="select"
              type="number"
              placeholder="Inventory Based"
              name="inventory_based"
              {...register("inventory_based")}
              className={`${errors?.inventory_based && "form-error-field"}`}
            >
              <option value={0} key="0">
                No
              </option>
              <option value={1} key="1">
                Yes
              </option>
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Perishable</Form.Label>

            <Form.Control
              as="select"
              type="number"
              placeholder="Perishable"
              name="is_perishable"
              {...register("is_perishable")}
              className={`${errors?.is_perishable && "form-error-field"}`}
            >
              <option value={0} key="0">
                No
              </option>
              <option value={1} key="1">
                Yes
              </option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Enable Subscription</Form.Label>

            <Form.Control
              as="select"
              type="number"
              placeholder="Enable Subscription"
              name="is_subscriptionable"
              {...register("is_subscriptionable")}
              className={`${errors?.is_subscriptionable && "form-error-field"}`}
            >
              <option value={0} key="0">
                No
              </option>
              <option value={1} key="1">
                Yes
              </option>
            </Form.Control>
          </Form.Group>{" "}
        </Form.Row>

        <Form.Row>
          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Packaged</Form.Label>

            <Form.Control
              as="select"
              type="number"
              placeholder="`"
              name="is_packed"
              {...register("is_packed")}
              // onChange={(e) => setIsPacked(e?.target?.value)}
              className={`${errors?.is_packed && "form-error-field"}`}
            >
              <option value={0} key="0">
                No
              </option>
              <option value={1} key="1">
                Yes
              </option>
            </Form.Control>
          </Form.Group>
        </Form.Row>

        {isPacked === "1" && (
          <Form.Row>
            <NumberField
              {...hookFormProp}
              name="package_qty"
              label="Package Qty"
            />

            <Form.Group className="col-xs-12 col-sm-6 col-md-6">
              <Form.Label>Package Unit</Form.Label>

              <Form.Control
                as="select"
                type="number"
                placeholder="Category"
                name="package_qty_unit"
                {...register("package_qty_unit")}
                className={`${errors?.package_qty_unit && "form-error-field"}`}
              >
                <option disabled value="" key="-1">
                  Select Unit
                </option>
                {unitList.map((unit, index) => (
                  <option value={unit?.unit_id} key={index}>
                    {unit?.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
        )}

        <Button variant="success" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}
