import { apiEndPoint } from "../const";

export const getCountryList = async () => {
  const res = await fetch(`${apiEndPoint}/admin/country`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api getCountryList >", error));
  console.log("country - ", res);
  return res;
};

export const getStateList = async () => {
  const res = await fetch(`${apiEndPoint}/admin/state`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api getStateList >", error));
  console.log("state - ", res);
  return res;
};

export const getRegionList = async () => {
  const res = await fetch(`${apiEndPoint}/admin/region`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api getRegionList >", error));
  console.log("region - ", res);
  return res;
};

export const addRegion = async (data) => {
  const finalData = {
    desc: "",
    region_code: null,
    state_id: null,
    name: "",
    regional_director: null,
    ...data,
  };
  const res = await fetch(`${apiEndPoint}/admin/region`, {
    method: "POST",
    mode: "no-cors",
    body: JSON.stringify(finalData),
  })
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api addRegion >", error));
  console.log(res);
  return res;
};

export const getTerritoryList = async () => {
  const res = await fetch(`${apiEndPoint}/admin/territory`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api getTerritoryList >", error));
  console.log("territory - ", res);
  return res;
};

export const getDistrictList = async () => {
  const res = await fetch(`${apiEndPoint}/admin/districts`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api getDistrictList >", error));
  console.log("district - ", res);
  return res;
};

export const getPincodeList = async () => {
  const res = await fetch(`${apiEndPoint}/admin/pincodes`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api getPincodeList >", error));
  console.log("pincode - ", res);
  return res;
};

// export const addTerritory = async (data) => {
//   const finalData = {
//     ...data,
//   };
//   const res = await fetch(`${apiEndPoint}/admin/territory`, {
//     method: "POST",
//     mode: "no-cors",
//     body: JSON.stringify(finalData),
//   })
//     .then((res) => {
//       return res;
//     })
//     .catch((error) => console.log("error in api addTerritory >", error));
//   console.log(res);
//   return res;
// };

// ---------------------
export const get = async (apiUri) => {
  const res = await fetch(`${apiUri}`, {
    method: "GET",
    mode: "cors",
  })
    .then(async (res) => {
      const json = await res.json()
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => console.log(`error in api ${apiUri} >`, error));
  return res;
};

// ${apiEndPoint}/admin/site

// export const getSiteList = async () => {
//   const res = await fetch(`${apiEndPoint}/admin/site`, {
//     method: "GET",
//     mode: "cors",
//   })
//     .then((res) => {
//       console.log(res?.status);
//       return res.json();
//     })
//     .then((res) => {
//       return res;
//     })
//     .catch((error) => console.log("error in api getSiteList >", error));
//   console.log("site - ", res);
//   return res;
// };

export const addSite = async (data) => {
  const finalData = {
    manager_key: null,
    site_code: null,
    territory_id: null,
    name: "",
    ...data,
  };
  const res = await fetch(`${apiEndPoint}/admin/site`, {
    method: "POST",
    mode: "no-cors",
    body: JSON.stringify(finalData),
  })
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api addSite >", error));
  console.log(res);
  return res;
};
