/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TextField from "../../../components/InputField/Text";
import { useContext } from "react";
import { StateContext } from "../../../context/StateContext";
import { addCategory } from "../../../utils/apiFunctions/category";
import { useEffect } from "react";
import { patch, uploadImage } from "../../../utils/apiFunctions";
import { v4 } from "uuid";

export default function Index({ categoryId }) {
  const history = useHistory();
  const { categoryList, fetchCategoryList } = useContext(StateContext);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [localImageUrl, setLocalImageUrl] = useState(null);
  const [image, setImage] = useState(null);
  const [image_key, setImageKey] = useState(v4());

  const schema = yup.object().shape({
    parent_id: yup.number(),
    for_sales: yup.number(),
    name: yup
      .string()
      .required("Field required")
      .min(1, "Min. 3 char required")
      .max(50, "Max. 50 char allowed"),
    image_url: yup.string()
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur"
  });

  useEffect(() => {
    if (categoryList?.length === 0) {
      fetchCategoryList();
    }
    if (categoryId) {
      const temp = categoryList?.filter(
        // eslint-disable-next-line eqeqeq
        (category) => category?.category_id == categoryId
      )[0];
      setValue("parent_id", temp?.parent || 0);
      setValue("for_sales", temp?.for_sales || 0);
      setValue("show_on_top", temp?.show_on_top || 0);
      setValue("name", temp?.name || "");
      setValue("image_url", temp?.image_url || "");
      setLocalImageUrl(temp?.image_url || null);
    }
  }, []);

  const hookFormProp = { errors: errors, reg: register };

  useEffect(() => {
    setImageKey(v4());
  }, []);

  const getFileExtension = (fileName) => {
    const nameList = fileName?.split(".");
    return nameList[nameList?.length - 1];
  };
  const handleImageChange = (files) => {
    const file = files[0];
    const fileExt = getFileExtension(file?.name);
    setLocalImageUrl(URL.createObjectURL(file));
    setImage(file);
    console.log(file);
    setValue("image_url", image_key);
    setValue(
      "image_url",
      `https://d3rnckbcoykp.cloudfront.net/${image_key}.${fileExt}`
    );
    setIsBtnDisabled(false);
  };

  const validate = () => {
    return !Boolean(localImageUrl);
  };

  useEffect(() => {
    setIsBtnDisabled(validate());
  }, [image]);

  const onSubmit = async (formData, e) => {
    console.log(errors);
    setIsBtnDisabled(true);
    if (image) {
      await uploadImage(image_key, image);
    }
    if (categoryId) {
      //update category

      await patch(`/admin/category?category_id=${categoryId}`, formData).then(
        (_) => {
          console.log("category update ", categoryId);
          history.push("/category");
        }
      );
    } else {
      //add new category
      await addCategory(formData).then(history.push("/category"));
    }
    setIsBtnDisabled(false);
  };

  return (
    <div>
      <div className="m-page-title mb-3">Add Category</div>
      <Link to="/category">
        <Button variant="outline-danger" block>
          Category
        </Button>
      </Link>
      <hr />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Parent Category</Form.Label>

            <Form.Control
              as="select"
              type="number"
              placeholder="Parent Category"
              name="parent_id"
              {...register("parent_id")}
              className={`${errors?.parent_id && "form-error-field"}`}
            >
              <option value={0} key="0">
                New Category
              </option>
              {categoryList.map((category, index) => (
                <option value={category?.category_id} key={index}>
                  {category?.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <TextField {...hookFormProp} name="name" label="Category Name" />
        </Form.Row>

        <Form.Row>
          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>For Sales</Form.Label>

            <Form.Control
              as="select"
              type="number"
              placeholder="For Sales"
              name="for_sales"
              {...register("for_sales")}
              className={`${errors?.for_sales && "form-error-field"}`}
            >
              <option value={0} key="0">
                No
              </option>
              <option value={1} key="1">
                Yes
              </option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Show On Top</Form.Label>

            <Form.Control
              as="select"
              type="number"
              placeholder="Show On Top"
              name="show_on_top"
              {...register("show_on_top")}
              className={`${errors?.show_on_top && "form-error-field"}`}
            >
              <option value={0} key="0">
                No
              </option>
              <option value={1} key="1">
                Yes
              </option>
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Select Category Image</Form.Label>
            <Form.File
              name="productImage"
              label="Product image"
              onChange={(_) => handleImageChange(_.target.files)}
              custom
            />
            <span className="text-muted">
              Image should be in 180 x 180px and below 40kb
            </span>
          </Form.Group>
        </Form.Row>

        {/* image */}
        {localImageUrl ? (
          <div className="d-flex justify-content-center">
            <img
              style={{ height: "150px", overflow: "hidden" }}
              src={localImageUrl}
              alt=""
            />
          </div>
        ) : (
          ""
        )}

        <Button variant="success" type="submit" disabled={isBtnDisabled}>
          Submit
        </Button>
      </Form>
    </div>
  );
}
