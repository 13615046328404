import React from "react";
import { useContext } from "react";
import { StateContext } from "../../../context/StateContext";
import { Table, Dropdown } from "react-bootstrap";
import LoadingScreen from "../../../components/LoadingScreen/V1";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";

export default function Index() {
  const history = useHistory();
  const { unitList, isLoading } = useContext(StateContext);

  return (
    <>
      {unitList?.length > 0 ? (
        <Table striped hover responsive>
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Name</th>
              <th>Symbol</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {unitList?.map((unit, idx) => {
              return (
                <tr key={unit?.unit_id}>
                  <td>{idx + 1}</td>
                  <td>{unit?.name}</td>
                  <td>{unit?.symbol}</td>
                  <Dropdown>
                    <Dropdown.Toggle variant="dark" id="dropdown-basic">
                      <ThreeDotsVertical />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          history.push(`/editUnit/${unit?.unit_id}`);
                        }}
                      >
                        <div className="text-dark">Edit</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : isLoading ? (
        <LoadingScreen />
      ) : (
        <div>No Units</div>
      )}
    </>
  );
}
