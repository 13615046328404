import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { get, patch, _delete } from "../../../../../utils/apiFunctions";
interface CarouselModalProps {
  isVisible: Boolean;
  handleClose: (e: { refresh: boolean }) => {};
  carousalData?: any;
}
export default function Index({
  isVisible,
  handleClose,
  carousalData
}: CarouselModalProps) {
  console.log(carousalData?.carousal_id);
  const [data, setData] = useState<any>(carousalData);
  useEffect(() => {
    setData(carousalData);
  }, [carousalData]);

  const fetchCarousalData = async () => {
    await get(`/admin/ecom-carousals/${data?.carousal_id}`).then(
      ([status, res]) => {
        if (status === 200) {
          setData(res);
        }
      }
    );
  };

  const handleBtnClick = async (carousalData: any) => {
    // console.log(data?.carousal_id);
    await patch(
      `/admin/ecom-carousals/${data?.carousal_id}`,
      carousalData
    ).then(([status, res]) => {
      if (status === 200) {
        alert("success");
        fetchCarousalData();
      } else {
        alert("error occured");
        console.log(res);
      }
    });
  };

  const deleteCarousel = async () => {
    await _delete(`/admin/ecom-carousals/${data?.carousal_id}`).then(
      ([status, res]) => {
        if (status === 200) {
          alert("success");
          handleClose({ refresh: false });
        } else {
          alert("Error while deleting carousel");
        }
      }
    );
  };
  return (
    <Modal show={isVisible} onHide={handleClose} centered backdrop="static">
      <img src={data?.image} alt="carousal" width="100%" />
      <div className="m-2">
        <div className="f-b">Actions</div>
        <div className="d-flex flex-row justify-content-around">
          <button
            className={`btn btn-${data?.is_active ? "warning" : "success"}`}
            onClick={() =>
              handleBtnClick({ is_active: data?.is_active ? 0 : 1 })
            }
          >
            {data?.is_active ? "Disable" : "Enable"}
          </button>

          <button className="btn btn-danger" onClick={() => deleteCarousel()}>
            Delete
          </button>

          <button
            className="btn btn-light"
            onClick={() => handleClose({ refresh: false })}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}
