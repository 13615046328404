import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-number-input";
import "./style.css";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { apiEndPoint } from "../../utils/const";
import { uploadResume } from "../../utils/apiFunctions";
import jobPositions from "../../data/jobPosition";
export default function Index() {
  const history = useHistory();
  const REQUEST_PARAMS = {
    method: "GET",
    headers: {
      "X-CSCAPI-KEY": "QW94aFRROVVKNHJBdXZmSFZKeEtabHc3dlhzenBCQVJYWENHRkJxNA=="
    }
  };

  const STREAM = {
    Bachelors: [
      "B.Com - Marketing",
      "B.Com - Accountancy",
      "B.Com - Finance",
      "B.Com - Accounting and Finance",
      "B.Com - Banking and Finance",
      "B.Com - Banking Management",
      "B.Com - Business Administration",
      "B.Com - Commerce",
      "B.Com - Management Studies",
      "B.Com - Computer Science",
      "BBA - Accounting and Finance",
      "BBA - Banking and Finance",
      "BBA - Marketing and Finance",
      "BBA - Others",
      "B.Sc - Banking and Finance",
      "B.Sc - Animation",
      "B.Sc - Animation & Multimedia Technology",
      "B.Sc - Others",
      "B.A - English",
      "B.A - English Literature",
      "B.A - History",
      "B.A - Economics",
      "B.A - Sociology",
      "B.A - Political Science",
      "B.A - Geography",
      "B.A - Communication Studies",
      "B.A - Psychology",
      "B.A - Philosophy",
      "B.A - Biomedical Sciences",
      "B.A - Animation & Multi Media",
      "B.A - Human Resource Management",
      "B.A -Others",
      "B.E - Computer Science Engineering",
      "B.E - Mechanical Engineering",
      "B.E - InfoTech",
      "B.E - Electronic and Communication Engineering",
      "B.E - Electrical and Electronics Engineering",
      "B.E Others",
      "B.Tech - Computer Science Engineering",
      "B.Tech - Mechanical Engineering",
      "B.Tech - InfoTech",
      "B.Tech - Electrical and Electronics Engineering",
      "B.Tech - Electronic and Communication Engineering",
      "B.Pharma"
    ],
    Masters: [
      "M.A - Political Science",
      "M.A - Economics",
      "M.A - History",
      "M.A - English",
      "M.A - English Literature",
      "M.A - Sociology",
      "M.A - Geography",
      "M.A - Communication Studies",
      "M.A - Psychology",
      "M.A - Philosophy",
      "M.A - Biomedical Sciences",
      "M.A - Animation & Multi Media",
      "M.A - Human Resource Management",
      "M.A - Others",
      "MBA - Accounting and Finance",
      "MBA - Banking and Finance",
      "MBA - Marketing and Finance",
      "MBA - Others",
      "M.Sc - Banking and Finance",
      "M.Sc - Animation",
      "M.Sc - Animation & Multimedia Technology",
      "M.Sc - Others",
      "M.Com - Marketing",
      "M.Com - Accountancy",
      "M.Com - Finance",
      "M.Com - Accounting and Finance",
      "M.Com - Banking and Finance",
      "M.Com - Banking Management",
      "M.Com - Business Administration",
      "M.Com - Commerce",
      "M.Com - Management Studies",
      "M.Com - Computer Science",
      "M.Com - Others",
      "MS",
      "MBA",
      "M.Sc - Agree",
      "M.Sc - Computer Science",
      "M.Sc - Mathematics",
      "M.Sc - Physics",
      "M.Sc - Chemistry",
      "M.Sc - Others",
      "M.Veterinary Science",
      "M.Computer Application",
      "M.E - Computer Science Engineering",
      "M.E - Mechanical Engineering",
      "M.E - InfoTech",
      "M.E - Electronic and Communication Engineering",
      "M.E - Others",
      "M.Tech - Computer Science Engineering",
      "M.Tech - Mechanical Engineering",
      "M.Tech - InfoTech",
      "M.Tech - Electronic and Communication Engineering",
      "M.Tech - Others",
      "M.Pharm",
      "M.B.B.S"
    ]
  };

  const noExperience = ["Student", "No Experience"];

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [currentCountry, setCurrentCountry] = useState("");

  const [degree, setDegree] = useState();
  const [jobPreference, setJobPreference] = useState(null);
  const [showIndustry, setShowIndustry] = useState(false);

  const [resume, setResume] = useState("");
  const [resumeError, setResumeError] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const initialFormData = {
    firstName: "-",
    lastName: "-",
    addressLine1: "-",
    addressLine2: "-",
    city: "-",
    collegeName: "-",
    country: "-",
    degree: "-",
    email: "-",
    experience: "-",
    phoneNumber: "-",
    resumeFileName: "-",
    state: "-",
    stream: "-",
    zipCode: "-",
    industry: "-",
    lookingFor: "-",
    preferredLocation: "-"
  };
  useEffect(() => {
    if (formSubmitSuccess) {
      alert("Your data submitted successfully");
      history.push("/");
    }
  }, [formSubmitSuccess]);

  // aws api gateway endpoint
  // const postData = async (userData) => {
  //   const url =
  //     `${apiEndPoint}/apply`;
  //   await fetch(url, {
  //     method: "POST",
  //     mode: "cors",
  //     body: JSON.stringify({
  //       ...userData,
  //     }),
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res?.statusCode === 200) {
  //         console.log("posted successfully");
  //       } else {
  //         console.log(res?.error);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };
  // const uploadFile = async (file, userData, event) => {
  //   await fetch(
  //     "https://sebnfh0jkd.execute-api.ap-southeast-1.amazonaws.com/dev/apply/resume",
  //     {
  //       method: "POST",
  //       mode: "cors",
  //       body: JSON.stringify({
  //         key: "File",
  //       }),
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((response) => {
  //       const bucketUrl = response.url;
  //       const _fileName = response.file_name;
  //       fetch(bucketUrl, {
  //         method: "PUT",
  //         mode: "cors",
  //         body: file,
  //       })
  //         .then((res) => {
  //           if (res.status === 200) {
  //             const id = _fileName.substring(0, _fileName.length - 4); //remove '.pdf' from file name
  //             postData({ ...userData, id });
  //             console.log("form submitted successfully");
  //             setFormSubmitSuccess(true);
  //           } else {
  //             setIsUploading(false);
  //             alert("Failed");
  //             console.log("error while uploading file");
  //           }
  //           console.log(`success ${res.status} - ${res.statusText}`);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           console.log(
  //             `error on bucket url ${err.status} - ${err.statusText}`
  //           );
  //         });
  //     })
  //     .catch((err) => console.log("error while fetching api endpoint", err));
  // };

  // formSubmit
  const onSubmit = (formData, e) => {
    if (resume === "") {
      setResumeError(true);
    } else {
      console.log("starting");
      setIsUploading(true);
      const data = {
        ...initialFormData,
        ...formData,
        resumeFileName: resume["name"]
      };
      // uploadFile(resume, data, e);
      uploadResume(resume, data)
        .then(() => {
          setFormSubmitSuccess(true);
          setIsUploading(false);
        })
        .catch(() => {
          setIsUploading(false);
        });
      // reset();
    }
    console.log("data", formData);
    console.log("err", errors);
  };

  const getCountries = () => {
    return fetch(
      "https://api.countrystatecity.in/v1/countries/",
      REQUEST_PARAMS
    )
      .then((_) => _.json())
      .then((response) => {
        // console.log(response);
        return response;
      });
  };
  const getStatesByCountry = (countryIso2) => {
    return fetch(
      `https://api.countrystatecity.in/v1/countries/${countryIso2}/states`,
      REQUEST_PARAMS
    )
      .then((_) => _.json())
      .then((response) => {
        // console.log(response);
        return response;
      });
  };
  const getCitiesByStateAndCountry = (countryIso2, stateIso2) => {
    return fetch(
      `https://api.countrystatecity.in/v1/countries/${countryIso2}/states/${stateIso2}/cities`,
      REQUEST_PARAMS
    )
      .then((_) => _.json())
      .then((response) => {
        // console.log(response);
        return response;
      });
  };

  const handleCountryChange = (e) => {
    const iso2 = e.target.value.split("-")[1];
    // console.log(e.target.value)
    setCurrentCountry(e.target.value);
    // console.log(iso2);
    getStatesByCountry(iso2).then((_) => {
      setStates(_);
    });
  };
  const handleStateChange = (e) => {
    const iso2 = e.target.value.split("-")[1];
    const countryIso = currentCountry.split("-")[1];
    // console.log(iso2);
    getCitiesByStateAndCountry(countryIso, iso2).then((_) => setCities(_));
  };
  const handleResumeChange = (files) => {
    const file = files[0];
    if (file["size"] <= "2097152" && file["type"] === "application/pdf") {
      setResumeError(false);
      setResume(file);
    } else {
      setResumeError(true);
    }
  };

  useEffect(() => {
    getCountries().then((_) => setCountries(_));
  }, []);

  // yup schema
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required("Field cannot be empty")
      .min(2, "Min. 2 char Required"),
    lastName: yup
      .string()
      .required("Field cannot be empty")
      .min(2, "Min. 2 char Required"),
    email: yup
      .string()
      .required("Email cannot be empty")
      .email("Enter valid mail Id"),
    phoneNumber: yup.string().required("Field cannot be empty"),
    addressLine1: yup.string().required("Address Field cannot be empty"),
    addressLine2: yup.string().required("Address Field cannot be empty"),
    country: yup
      .string()
      .required("Country Field cannot be empty")
      .not(["-"], "select country"),
    city: yup.string().not(["-"], "select city"),
    state: yup.string().not(["-"], "select state"),
    zipCode: yup.string().required("ZipCode Field cannot be empty"),
    collegeName: yup.string().required("College Name Field cannot be empty"),
    degree: yup
      .string()
      .required("College Name Field cannot be empty")
      .not(["Choose your degree"], "Select your degree"),
    stream: yup
      .string()
      .default("-")
      .not(["Choose your stream"], "Select your stream"),
    experience: yup
      .string()
      .required("Field cannot be empty")
      .not(["Choose an option"], "Select Experience"),
    industry: yup.string().not(["Choose an option"], "Select Industry"),
    lookingFor: yup
      .string()
      .required("Field cannot be empty")
      .not(["Choose an option"], "Select position you looking for"),
    preferredLocation: yup.string().default("Any Where")
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur"
  });

  const hookFormProp = { errors: errors, reg: register };
  return (
    <>
      <form
        className="row g-3 apply-form-row"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete={"nope"}
      >
        {/* first name */}
        <div className="col-lg-6 col-md-6 col-sm-6">
          <label htmlFor="firstName" className="form-label">
            First name
          </label>
          <input
            id="firstName"
            name="firstName"
            placeholder="First Name"
            type="text"
            //             className={`form-control ${errors.firstName?'border-danger':''}`}

            className={`form-control ${
              errors.firstName ? "border-danger" : ""
            }`}
            {...register("firstName")}
          />
          <div>
            <span className="text-danger">{errors.firstName?.message}</span>
          </div>
        </div>

        {/* last name */}
        <div className="col-lg-6 col-md-6 col-sm-6">
          <label htmlFor="lastName" className="form-label">
            Last name
          </label>
          <input
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            type="text"
            className={`form-control ${errors.lastName ? "border-danger" : ""}`}
            {...register("lastName")}
          />
          <div>
            <span className="text-danger">{errors.lastName?.message}</span>
          </div>
        </div>

        {/* emailId */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <label htmlFor="email" className="form-label">
            Email Id
          </label>
          <input
            id="email"
            placeholder="Mail Id"
            name="email"
            type="text"
            className={`form-control ${errors.email ? "border-danger" : ""}`}
            {...register("email")}
          />
          <div>
            <span className="text-danger">{errors.email?.message}</span>
          </div>
        </div>

        {/* phoneNumber */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <label htmlFor="phoneNumber" className="form-label">
            Phone Number
          </label>
          <PhoneInput
            placeholder="Mobile Number (with code eg:- +91)"
            name="phoneNumber"
            {...register("phoneNumber")}
            onChange={(_) => setValue("phoneNumber", _)}
            className={`phoneNumberInput form-control ${
              errors.phoneNumber ? "border-danger" : ""
            }`}
          />
          <div>
            <span className="text-danger">{errors.phoneNumber?.message}</span>
          </div>
        </div>

        {/* address line */}
        <div className="col-lg-12 col-md-12 col-sm-12">
          <label htmlFor="addressLine1" className="form-label">
            Address Line 1
          </label>
          <input
            id="addressLine1"
            name="addressLine1"
            placeholder="House / Building No., "
            type="text"
            className={`form-control ${
              errors.addressLine1 ? "border-danger" : ""
            }`}
            {...register("addressLine1")}
          />
          <div>
            <span className="text-danger">{errors.addressLine1?.message}</span>
          </div>
        </div>

        {/* address line */}
        <div className="col-lg-12 col-md-12 col-sm-12">
          <label htmlFor="addressLine2" className="form-label">
            Address Line 2
          </label>
          <input
            id="addressLine2"
            name="addressLine2"
            placeholder="Street / colony name"
            type="text"
            className={`form-control ${
              errors.addressLine2 ? "border-danger" : ""
            }`}
            {...register("addressLine2")}
          />
          <div>
            <span className="text-danger">{errors.addressLine2?.message}</span>
          </div>
        </div>

        <br />
        {/* country */}
        <div className="row col-12 mt-3  ">
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <label htmlFor="country" className="form-label">
              Country
            </label>
            {/* <select
              id="country"
              name="country"
              className={`form-select ${errors.country ? "border-danger" : ""}`}
              aria-label="Default select example"
              {...register("country")}
              // valuesValue={{label:'Select Country', value:'-'}}
              // defaultValue={'-'}
              onChange={(_) => {
                // setCurrentCountry(_.target.value);
                handleCountryChange(_);
              }}
            > */}
            <Form.Group>
              <Form.Control
                as="select"
                id="country"
                name="country"
                className={errors.country ? "border-danger" : ""}
                {...register("country")}
                // valuesValue={{label:'Select Country', value:'-'}}
                // defaultValue={'-'}
                onChange={(_) => {
                  // setCurrentCountry(_.target.value);
                  handleCountryChange(_);
                }}
              >
                {/* <option selected={userGeoInfo.country?false:true}>-</option> */}
                <option value="-">-</option>
                {countries
                  ? countries.map((country) => (
                      <option
                        key={country.id}
                        value={`${country.name}-${country.iso2}`}
                        // selected={userGeoInfo.country===country.name?true:false}
                      >
                        {country.name}
                      </option>
                    ))
                  : ""}
              </Form.Control>
            </Form.Group>
            {/* </select> */}

            <div>
              <span className="text-danger">{errors.country?.message}</span>
            </div>
          </div>
          {states.length > 0 && (
            <div
              className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
              style={{ paddingLeft: "70px" }}
            >
              <label htmlFor="state" className="form-label">
                State
              </label>
              <br />
              <Form.Group>
                <Form.Control
                  as="select"
                  id="state"
                  name="state"
                  className={` ${errors.state ? "border-danger" : ""}`}
                  {...register("state")}
                  onChange={(_) => {
                    handleStateChange(_);
                  }}
                >
                  <option value="-">-</option>
                  {states
                    ? states.map((state) => (
                        <option
                          key={state.id}
                          value={`${state.name}-${state.iso2}`}
                        >
                          {state.name}
                        </option>
                      ))
                    : ""}
                </Form.Control>
              </Form.Group>

              <div>
                <span className="text-danger">{errors.state?.message}</span>
              </div>
            </div>
          )}

          {cities.length > 0 && (
            <div
              className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
              style={{ paddingLeft: "20px" }}
            >
              <label htmlFor="city" className="form-label">
                City
              </label>
              <br />

              <Form.Group>
                <Form.Control
                  as="select"
                  id="city"
                  name="city"
                  className={` ${errors.city ? "border-danger" : ""}`}
                  {...register("city")}
                  onChange={(_) => {
                    setValue("city", _.target.value);
                  }}
                >
                  <option value="-">-</option>
                  {/* {console.log(cities.length)} */}
                  {cities.length > 0
                    ? cities.map((city) => (
                        <option key={city.id} value={city.name}>
                          {city.name}
                        </option>
                      ))
                    : ""}
                </Form.Control>
              </Form.Group>

              <div>
                <span className="text-danger">{errors.city?.message}</span>
              </div>
            </div>
          )}
        </div>

        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <label htmlFor="zipCode" className="form-label">
            Zip Code
          </label>
          <input
            autoComplete={"nope"}
            id="zipCode"
            name="zipCode"
            placeholder="Enter Zip code / Postal Code"
            type="text"
            className={`form-control ${errors.zipCode ? "border-danger" : ""}`}
            {...register("zipCode")}
          />
          <div>
            <span className="text-danger">{errors.zipCode?.message}</span>
          </div>
        </div>

        {/* college */}
        <div className="row col-12 mt-3  ">
          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <label htmlFor="collegeName" className="form-label">
              College
            </label>
            <input
              id="collegeName"
              name="collegeName"
              placeholder="College Name"
              type="text"
              className={`form-control ${
                errors.collegeName ? "border-danger" : ""
              }`}
              {...register("collegeName")}
            />
            <div>
              <span className="text-danger">{errors.collegeName?.message}</span>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <label htmlFor="degree" className="form-label">
              Degree
            </label>
            <br />

            <Form.Group>
              <Form.Control
                as="select"
                id="degree"
                name="degree"
                className={`${errors.degree ? "border-danger" : ""}`}
                {...register("degree")}
                onChange={(_) => {
                  setDegree(_.target.value);
                }}
              >
                <option value="Choose your degree">Choose your degree</option>
                <option value="Bachelors">Bachelors </option>
                <option value="Masters"> Masters</option>
                <option value="Diploma"> Diploma</option>
                <option value="ITI"> ITI</option>
                <option value="Others"> Others</option>
              </Form.Control>
            </Form.Group>

            <div>
              <span className="text-danger">{errors.degree?.message}</span>
            </div>
          </div>
          {degree && Object.keys(STREAM).includes(degree) ? (
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <label htmlFor="stream" className="form-label">
                Stream
              </label>

              <Form.Group>
                <Form.Control
                  as="select"
                  id="stream"
                  name="stream"
                  className={`form-select ${
                    errors.stream ? "border-danger" : ""
                  }`}
                  aria-label="Default select example"
                  {...register("stream")}
                >
                  <option value="Choose your stream">Choose your stream</option>
                  {STREAM[degree].map((_, idx) => (
                    <option value={_} key={idx}>
                      {_}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <div>
                <span className="text-danger">{errors.stream?.message}</span>
              </div>
            </div>
          ) : null}
        </div>

        {/* experience */}
        <div className="row col-12 mt-3  ">
          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <label htmlFor="experience" className="form-label">
              Experience
            </label>
            <br />

            <Form.Group>
              <Form.Control
                as="select"
                id="experience"
                name="experience"
                className={`form-select ${
                  errors.experience ? "border-danger" : ""
                }`}
                aria-label="Default select example"
                {...register("experience")}
                onChange={(_) => {
                  if (noExperience.includes(_.target.value)) {
                    setShowIndustry(false);
                  } else {
                    setShowIndustry(true);
                  }
                }}
              >
                <option value="Choose an option">Choose an option</option>
                <option value="Student">Student</option>
                <option value="No Experience">No Experience</option>
                <option value="less-than-1yr"> Less than 1yr</option>
                <option value="1-2yrs">1 to 2 yrs</option>
                <option value="2-5yrs">2 to 5 yrs</option>
                <option value="5+yrs">above 5+ yrs</option>
              </Form.Control>
            </Form.Group>

            <div>
              <span className="text-danger">{errors.experience?.message}</span>
            </div>
          </div>

          {showIndustry && (
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <label htmlFor="industry" className="form-label">
                Industry
              </label>

              <Form.Group>
                <Form.Control
                  as="select"
                  id="industry"
                  name="industry"
                  className={`form-select ${
                    errors.industry ? "border-danger" : ""
                  }`}
                  aria-label="Default select example"
                  {...register("industry")}
                >
                  <option value="Choose an option">Choose an option</option>
                  <option value="Marketing">Marketing</option>
                  <option value="Finance">Finance</option>
                  <option value="Engineering">Engineering</option>
                  <option value="Sales">Sales</option>
                  <option value="Human Resources">Human Resources</option>
                  <option value="Analytics">Analytics</option>
                  <option value="Telecommunication">Telecommunication</option>
                  <option value="Banking">Banking</option>
                  <option value="Customer Experience">
                    Customer Experience
                  </option>
                  <option value="Cloud Computing">Cloud Computing</option>
                  <option value="Information Technology">
                    Information Technology
                  </option>
                  <option value="Others">Others</option>
                </Form.Control>
              </Form.Group>

              <div>
                <span className="text-danger">{errors.industry?.message}</span>
              </div>
            </div>
          )}
          {/* Looking for */}
          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <label htmlFor="lookingFor" className="form-label">
              Looking For
            </label>

            <Form.Group>
              <Form.Control
                as="select"
                id="lookingFor"
                name="lookingFor"
                className={`form-select ${
                  errors.lookingFor ? "border-danger" : ""
                }`}
                aria-label="Default select example"
                {...register("lookingFor")}
                onChange={(_) => {
                  setJobPreference(_.target.value);
                }}
              >
                <option value="Choose an option">Choose an option</option>
                {degree === "Bachelors" || degree === "Masters" ? (
                  <>
                    <option value="Sales Manager">Sales Manager</option>
                    <option value="Procurement Manager">
                      Procurement Manager
                    </option>
                    <option value="Production Manager">
                      Production Manager
                    </option>
                    <option value="Territory Bussiness Manager">
                      Territory Business Manager
                    </option>
                    <option value="Business Manager">Business Manager</option>
                    <option value="Regional Manager">Regional Manager</option>
                    <option value="Sales VP">Sales VP</option>
                    <option value="Accountant">Accountant</option>
                  </>
                ) : null}
                <option value="Associate Trainee Sales">
                  Associate Trainee Sales
                </option>
                <option value="Associate Sales">Associate Sales</option>
                <option value="ssociate Trainee Production">
                  Associate Trainee Production
                </option>
                <option value="Associate Production">
                  Associate Production
                </option>
                <option value="Associate Trainee Procurement">
                  Associate Trainee Procurement
                </option>
                <option value="Associate Trainee Store">
                  Associate Trainee Store
                </option>
                <option value="Associate Procurement">
                  Associate Procurement
                </option>
                <option value="Welding, Plumbing, Electrician">
                  Welding, Plumbing, Electrician
                </option>
              </Form.Control>
            </Form.Group>

            <div>
              <span className="text-danger">{errors.lookingFor?.message}</span>
            </div>
          </div>

          {/* prefered location */}
          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <label htmlFor="lookingFor" className="form-label">
              Preferred Location
            </label>

            <Form.Group>
              <Form.Control
                as="select"
                id="preferredLocation"
                name="preferredLocation"
                className={`form-select ${
                  errors.preferredLocation ? "border-danger" : ""
                }`}
                aria-label="Default select example"
                {...register("preferredLocation")}
              >
                <option value="Any Where">Any Where</option>
                {jobPreference !== "Regional Manager" &&
                jobPreference !== "Sales VP" ? (
                  <>
                    <option value="Coimbatore">Coimbatore (TN)</option>
                    <option value="Kanyakumari">Kanyakumari (TN)</option>
                    <option value="Madurai">Madurai (TN)</option>
                    <option value="Tirunelveli">Tirunelveli (TN)</option>
                    <option value="Thoothukkudi">Thoothukkudi (TN)</option>
                    <option value="Virudhunagar">Virudhunagar (TN)</option>
                    <option value="Tenkasi">Tenkasi (TN)</option>
                    <option value="Associate Sales">
                      Associate Sales (TN)
                    </option>
                  </>
                ) : null}
              </Form.Control>
            </Form.Group>

            <div>
              <span className="text-danger">
                {errors.preferredLocation?.message}
              </span>
            </div>
          </div>
        </div>

        {/* resume */}
        <div className="mb-3">
          <label htmlFor="formFile" className="form-label">
            Resume / CV
          </label>
          <input
            className={`form-control ${resumeError ? "border-danger" : ""}`}
            onChange={(_) => handleResumeChange(_.target.files)}
            type="file"
            id="formFile"
            name="resume"
          />
          <div>
            <span className={`text-${resumeError ? "danger" : "muted"}`}>
              File must be in pdf format and less than 2MB
            </span>
          </div>
        </div>

        <div className="col-12 d-flex justify-content-center">
          <button className="btn btn-success m-3" disabled={isUploading}>
            submit
          </button>
        </div>
      </form>
      {/* <button onClick={()=>{
          navigate('/')
      }}>
          sd
      </button> */}
    </>
  );
}
