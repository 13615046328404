import React, { createContext, useContext, useState } from "react";
import {
  getCountryList,
  getRegionList,
  getSiteList,
  getStateList,
  getTerritoryList,
  get,
  getDistrictList,
  getPincodeList
} from "../utils/apiFunctions/area";
import { apiEndPoint } from "../utils/const";
import { AuthContext } from "./AuthContext";

export const AreaContext = createContext();

export const AreaProvider = ({ children }) => {
  const { tokens } = useContext(AuthContext);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [territoryList, setTerritoryList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [pincodeList, setPincodeList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchCountryList() {
    setIsLoading(true);
    await getCountryList(tokens?.idToken).then((res) => {
      setCountryList(res);
      setIsLoading(false);
    });
  }
  async function fetchStateList() {
    setIsLoading(true);
    await getStateList(tokens?.idToken).then((res) => {
      setStateList(res);
      setIsLoading(false);
    });
  }
  async function fetchRegionList() {
    setIsLoading(true);
    await getRegionList(tokens?.idToken).then((res) => {
      setRegionList(res);
      setIsLoading(false);
    });
  }
  async function fetchTerritoryList() {
    setIsLoading(true);
    await getTerritoryList(tokens?.idToken).then((res) => {
      setTerritoryList(res);
      setIsLoading(false);
    });
  }
  async function fetchDistrictList() {
    setIsLoading(true);
    await getDistrictList(tokens?.idToken).then((res) => {
      setDistrictList(res);
      setIsLoading(false);
    });
  }
  async function fetchPincodeList() {
    setIsLoading(true);
    await getPincodeList(tokens?.idToken).then((res) => {
      setPincodeList(res);
      setIsLoading(false);
    });
  }
  async function fetchSiteList() {
    setIsLoading(true);
    await get(`${apiEndPoint}/admin/site`).then(([status, res]) => {
      setSiteList(res);
      setIsLoading(false);
    });
  }

  return (
    <AreaContext.Provider
      value={{
        isLoading,
        fetchCountryList,
        fetchStateList,
        fetchRegionList,
        fetchTerritoryList,
        fetchDistrictList,
        fetchPincodeList,
        fetchSiteList,
        countryList,
        stateList,
        regionList,
        territoryList,
        districtList,
        pincodeList,
        siteList,
        isLoading,
      }}
    >
      {children}
    </AreaContext.Provider>
  );
};
