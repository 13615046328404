import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function Index(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.product?.display_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          <div className="row">
            <div style={{ width: "25%" }} className="column">
              <img
                src={props?.product?.image_url}
                style={{ width: "100%" }}
                alt=""
                srcset=""
              />
            </div>
            <div className="ml-5 column">
              <table>
                <tr>
                  <th>product price - </th>
                  <tb>₹ {props?.product?.product_price}</tb>
                </tr>
                {props?.product?.is_offer_active ? (
                  <>
                    <tr>
                      <th>Offer price - </th>
                      <tb>₹ {props?.product?.offer_price}</tb>
                    </tr>

                    <tr>
                      <th>Offer Start on- </th>
                      <tb>₹ {props?.product?.offer_start_date}</tb>
                    </tr>
                    <tr>
                      <th>Offer End on - </th>
                      <tb>₹ {props?.product?.offer_end_date}</tb>
                    </tr>
                  </>
                ) : null}
                <tr>
                  <th>Qty in inventory- </th>
                  <tb>₹ {props?.product?.inventory_qty}</tb>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            props.onHide();
          }}
          variant="danger"
        >
          Close
        </Button>
        {/* <Button type="submit" variant="success">
          Transfer
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
}
