import React, { useContext, useState } from "react";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import LoadingScreen from "../../../components/LoadingScreen/V1";
import { ThreeDotsVertical } from "react-bootstrap-icons";

import { get, patch } from "../../../utils/apiFunctions";
import { AuthContext } from "../../../context/AuthContext";
import { StateContext } from "../../../context/StateContext";
import { useEffect } from "react";

export default function Index() {
  const { isLoading, setIsLoading } = useContext(StateContext);
  const [productionList, setProductionList] = useState([]);
  async function fetchProductionList() {
    setIsLoading(true);
    await get(`/admin/inventory/production`).then(([status, res]) => {
      setProductionList(res);
    });
    setIsLoading(false);
  }
  useEffect(() => {
    fetchProductionList();
  }, []);
  return (
    <>
      {productionList?.length > 0 ? (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Lot Key</th>
              <th>Category</th>
              <th>Name</th>
              <th>Qty</th>
              <th>Site</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {productionList?.map((product, idx) => {
              return (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td
                    className={
                      product?.is_approved ? "text-success" : "text-danger"
                    }
                  >
                    {product?.lot_key}
                  </td>
                  <td>{product?.category_name}</td>
                  <td>{product?.product_type_name}</td>
                  <td>{product?.qty}</td>
                  <td>{product?.site_name}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="dark" id="dropdown-basic">
                        <ThreeDotsVertical />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <div className="text-dark">View</div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : isLoading ? (
        <LoadingScreen />
      ) : (
        <div>No product is produced</div>
      )}
    </>
  );
}
