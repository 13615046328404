import React from "react";
import "./style.css";
export default function WindowCard({ image,bgColor, children, _style }) {
  const _bg = image ? `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url('${image}')` : ''
  const _bgColor = bgColor?bgColor:'#fff'
  return (
    <div
      className="jumbotron jumbotron-fluid"
      style={{
        background:_bg,
        backgroundColor:_bgColor,
        backgroundPosition:'center',
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat',
        height:'100vh',
        padding:'2.5rem',
        ..._style
      }}
    >
      {/* <div className="window-container" style={{
        padding:'2.5rem',
        height:'100%'
      }}>{children}</div> */}
      {children}
    </div>
  );
}
