import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/form.css";
import "./style/main.css";
// import 'react-bootstrap-icons'
import Amplify from 'aws-amplify'
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { COGNITO_APP_CLIENT_ID, COGNITO_REGION, COGNITO_USER_POOL_ID } from "./utils/const";

// useEffect(() => {
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: COGNITO_REGION,
    userPoolId: COGNITO_USER_POOL_ID,
    userPoolWebClientId: COGNITO_APP_CLIENT_ID,
  },
}); //configure Amplyfy with cognito cred
// }, []);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
