import React, { useContext, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import NumberField from "../../../../../components/InputField/Number";
// import TextField from "../../../../../components/InputField/Text";

import "./style.css";
import { AuthContext } from "../../../../../context/AuthContext";
import { dbTimeToLocal } from "../../../../../helpers/date";
import { patch } from "../../../../../utils/apiFunctions";

interface ModalProps {
  onHide: () => void;
  show: boolean;
  product: any;
}

export default function Index({ onHide, show, product }: ModalProps) {
  const { userAttributes } = useContext(AuthContext);

  const schema = yup.object().shape({
    product_price: yup
      .number()
      .min(1)
      .max(9999999999, "Enter value below 1 Billion")
      .default(product?.product_price),
    offer_price: yup
      .number()
      .required("enter 0 if there is no offer")
      .typeError("number only"),
    is_offer_active: yup.number(),
    employee_id: yup.string().required().default(userAttributes?.sub),
    ecommerce_site_id: yup.number()
  });
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit
    // reset,
    // setError,
    // getValues
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur"
  });

  useEffect(() => {
    setValue("product_price", product?.product_price);
    setValue("offer_price", product?.offer_price);
    setValue("is_offer_active", product?.is_offer_active);
    setValue("employee_id", product?.employee_id);
    setValue("ecommerce_site_id", product?.ecommerce_site_id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  // const clearForm = () => {
  //   reset();
  // };
  const hookFormProp = { errors: errors, reg: register };

  const onSubmit = async (e: any) => {
    await patch(`/admin/ecommerce/${product?.ecommerce_site_id}/price`, e).then(
      (_) => {
        onHide();
      }
    );
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <div className="price-change-modal">
        <div className="title">{product?.display_name}</div>
        <div className="title">{product?.ecommerce_site_id}</div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
            <NumberField name="product_price" label="Price" {...hookFormProp} />
            <NumberField name="offer_price" label="Offer Price" {...hookFormProp} />

            <Form.Group className="col-xs-12 col-sm-6 col-md-6">
              <Form.Label>Activate Offer</Form.Label>
              <Form.Control
                as="select"
                type="number"
                placeholder="Activate Offer"
                {...register("is_offer_active")}
                className={`${errors?.is_offer_active && "form-error-field"}`}
              >
                <option value={0} key="0">
                  No
                </option>
                <option value={1} key="1">
                  Yes
                </option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          {/* <Form.Row>
          <TextField
            inputProp={{ type: "date" }}
            name="offer_start_date"
            label="Offer Start Date"
            {...hookFormProp}
          />
          <TextField
            inputProp={{ type: "date" }}
            name="offer_end_date"
            label="Offer End Date"
            {...hookFormProp}
          />
        </Form.Row> */}
          <Button
            // disabled={isBtnDisabled}
            className="float-right"
            type="submit"
            variant="dark"
          >
            Update
          </Button>
        </Form>
      </div>
    </Modal>
  );
}
// product_price
// offer_price
// is_offer_active
// offer_start_date
// offer_end_date
// employee_id
// ecommerce_site_id
