import React from "react";
import "./style.css";
export default function Index({ count, title, bg }) {
  const bg_list = {
    red: "rgba(146, 29, 29, 0.789)",
    yellow: "rgba(218, 200, 69, 0.919)",
    blue: "rgb(60, 142, 197)",
    green: "rgba(60, 197, 94, 0.823)",
    purple:"rgba(151, 101, 226, 0.96)",
    black:"rgba(49, 49, 50, 0.96)",
    brown:"rgba(169, 107, 74, 0.96)"
  };
  return (
    <div className="count-card" style={{backgroundColor:bg_list[bg]}}>
      <div className="title">{title}</div>
    
      <div className="count">{count}</div>
    </div>
   
  );
}
