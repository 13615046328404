import React, { createContext, useContext } from "react";
import { getHomeWidgetData } from "../utils/apiFunctions/home";
import { getProducts } from "../utils/apiFunctions/products";
import { AuthContext } from "./AuthContext";

export const APIContext = createContext();

export const APIProvider = ({ children }) => {
  const { tokens } = useContext(AuthContext);

  async function fetchHomeWidgetData() {
    return await getHomeWidgetData(tokens?.idToken)
    // .then((res) => {
    //   return res;
    // });
  }

//   async function fetchProducts() {
//     await getProducts().then((res)=>{

//     });
//   }

  return (
    <APIContext.Provider value={{ fetchHomeWidgetData }}>
      {children}
    </APIContext.Provider>
  );
};
