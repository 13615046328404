import React from "react";
import { StateProvider } from "./context/StateContext";
import { AreaProvider } from "./context/AreaContext";
import { AuthProvider } from "./context/AuthContext";
import Router from "./Router";
import { APIProvider } from "./context/APIContext";
import { CustomerProvider } from "./context/CustomerContext";
import { EcomProvider } from "./context/EcomContext";

export default function App() {
  return (
    <AuthProvider>
      <APIProvider>
        <AreaProvider>
          <StateProvider>
            <EcomProvider>
              <CustomerProvider>
                <Router />
              </CustomerProvider>
            </EcomProvider>
          </StateProvider>
        </AreaProvider>
      </APIProvider>
    </AuthProvider>
  );
}
