import React, {  useEffect, useState } from "react";
import LoadingScreen from "../../components/LoadingScreen/V1";
import OrderComp from "../../components/order";
import "./style.css";
import { get } from "../../utils/apiFunctions";

export default function Index({ orderStatus }) {
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("Orders");
  const [currentList, setCurrentList] = useState([]);

  async function fetchOrders(status) {
    setIsLoading(true);
    await get(`/admin/sales-orders?order_status=${status}`).then(
      ([status, res]) => {
        setIsLoading(false);
        setCurrentList(res);
        console.log(status);
      }
    );
  }

  useEffect(() => {
    if (orderStatus === "new") {
      setTitle("New Orders");
      fetchOrders("'PLACED'");
    } else if (orderStatus === "approved") {
      setTitle("Approved Orders");
      fetchOrders("'APPROVED'");
    } else if (orderStatus === "dispatched") {
      setTitle("Dispatched Orders");
      fetchOrders("'DISPATCHED'");
    } else if (orderStatus === "delivered") {
      setTitle("Delivered Orders");
      fetchOrders("'DELIVERED'");
    } else if (orderStatus === "cancelled") {
      setTitle("Cancelled Orders");
      fetchOrders("'CANCELLED','ECOM_ORDER_CANCELLED'");
    }
  }, [orderStatus]);

  return (
    <>
      {currentList?.length > 0 ? (
        <>
          <div className="m-page-title ml-2 pb-4">{title}</div>
          <div className="sales-order-wrapper">
            {currentList.map((order, idx) => (
              <OrderComp order={order} key={idx} />
            ))}
          </div>
        </>
      ) : isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="w-100 h-100 d-flex justify-content-center">
          No items to display
        </div>
      )}
    </>
  );
}
