import React, { useContext } from "react";
import { StateContext } from "../../../context/StateContext";
import { Dropdown } from "react-bootstrap";
import LoadingScreen from "../../../components/LoadingScreen/V1";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";

export default function Index() {
  const history = useHistory();
  const { categoryList, isLoading } = useContext(StateContext);
  return (
    <>
      {categoryList?.length > 0 ? (
        <div>
          <div className="rounded shadow-sm w-100 d-flex flex-row m-2 align-items-center f-b text-align-center">
            <div className="col-1">S.No</div>

            <div className="col-3">Image</div>

            <div className="col-6">Category Name</div>

            <div className="col-2">Actions </div>
          </div>

          {categoryList?.map((category, idx) => {
            return (
              <div className="rounded shadow-sm w-100 d-flex flex-row m-2 align-items-center">
                <div className="col-1 d-flex align-items-center">{idx + 1}</div>

                <div
                  className="col-3"
                  style={{ height: "100px", overflow: "hidden" }}
                >
                  <img
                    src={category?.image_url}
                    alt=""
                    srcset=""
                    height="100%"
                  />
                </div>

                <div className="col-6">{category?.name}</div>

                <div className="col-2">
                  <Dropdown>
                    <Dropdown.Toggle variant="dark" id="dropdown-basic">
                      <ThreeDotsVertical />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          history.push(
                            `/editCategory/${category?.category_id}`
                          );
                        }}
                      >
                        <div className="text-dark">Edit</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            );
          })}
        </div>
      ) : isLoading ? (
        <LoadingScreen />
      ) : (
        <div>No Catagories</div>
      )}
    </>
  );
}
