import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Chart } from "react-google-charts";

export default function Index({ products }) {
  const [data, setData] = useState([]);
  useEffect(() => {
      let temp = [["Products", "Sales"]]
      products?.map(product=>{
          temp.push([product?.name, product?.count])
      })
      setData([ ...temp])
  }, []);
  return (
    <div className="mt-4">
      <Chart
        width={"500px"}
        height={"300px"}
        chartType="Bar"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
          // Material design options
          chart: {
            title: "Top Selling",
            subtitle: `Top ${Object.keys(products)?.length} Products Sold last 2 months`,
          },
        }}
        // For tests
        rootProps={{ "data-testid": "2" }}
      />
    </div>
  );
}
