import React, { useContext, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { ArrowLeft, MenuApp, PersonCircle } from "react-bootstrap-icons";
import { AuthContext } from "../../context/AuthContext";
import { Button } from "react-bootstrap";

export default function Index() {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <div className="nav_bar_dashboard d-flex justify-content-between align-items-center">
      <div className="navbar_logo_height" >
        <Link to="/">
          <img
          style={{height:'90%'}}
            src="https://d3rnckbcoykp.cloudfront.net/logo.png"
            alt="Muthiahs logo"
          />
        </Link>
      </div>

      <div className="float-right d-flex flex-row mr-3">
        <div style={{ textDecoration: "none", paddingLeft: "30px" }}>
          <Link to="/apply">Apply</Link>
        </div>
        <br />
        <div style={{ textDecoration: "none", paddingLeft: "30px" }}>
          <Link to="/career">Career</Link>
        </div>
        <br />
        {isAuthenticated ? (
          <div style={{ textDecoration: "none", paddingLeft: "30px" }}>
            <Link to="/dashboard">Dashboard</Link>
          </div>
        ) : (
          <div style={{ textDecoration: "none", paddingLeft: "30px" }}>
            <Link to="/login">Login</Link>
          </div>
        )}
      </div>

      {/* <div className="Apply">
        <ul className="ullink">          
          <li style={{ textDecoration: 'none',paddingLeft:'30px'}}><Link to="/apply">Apply</Link></li><br/>
          <li style={{ textDecoration: 'none',paddingLeft:'30px'}}><Link to="/career">Career</Link></li><br/>
          {isAuthenticated ?
          <li style={{ textDecoration: 'none',paddingLeft:'30px'}}><Link to="/dashboard">Dashboard</Link></li>
        :  <li style={{ textDecoration: 'none',paddingLeft:'30px'}}><Link to="/login">Login</Link></li>
        }
        </ul>
      </div> */}
    </div>
  );
}
