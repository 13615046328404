import React, { createContext, useContext, useState } from "react";
import { getCustomer } from "../utils/apiFunctions/customermanagement";
import { AuthContext } from "./AuthContext";

export const CustomerContext = createContext();

export const CustomerProvider = ({ children }) => {
  const { tokens } = useContext(AuthContext);
  const [customerData, setCustomerData] = useState({});

  async function fetchCustomerData(customerKey) {
      await getCustomer(customerKey).then((res) => {
        setCustomerData(res);
      });
  }

  return (
    <CustomerContext.Provider value={{ customerData,fetchCustomerData }}>
      {children}
    </CustomerContext.Provider>
  );
};
