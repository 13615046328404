import React, { useContext } from "react";
import InventoryList from "./InventoryList";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

export default function Index() {
  
  return (
    <>
      <div className="m-page-title mb-3"> Inventory List</div>
      <Link to="/inventory/addProduct">
        <Button variant="outline-dark" block>
          + Add Product
        </Button>
      </Link>
      <hr />
      <InventoryList />
    </>
  );
}
