import { apiEndPoint } from "../const";

export const getUnitList = async () => {
  const path = "/admin/unit";
  const res = await fetch(`${apiEndPoint}${path}`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api getUnitList >", error));
  console.log(res);
  return res;
};

export const addUnit = async (data) => {
  const path = "/admin/unit";
  const res = await fetch(`${apiEndPoint}${path}`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(data)
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api addUnit >", error));
    
};
