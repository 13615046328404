// import { Button } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import ExistingProducts from "./tabs/existingProduct";
import { useState } from "react";

// import { Link } from "react-router-dom";
// import { useContext } from "react";
// import { StateContext } from "../../context/StateContext";
// import { useEffect } from "react";

export default function Index() {
  const [filterKey, setFilterKey] = useState("");
  return (
    <div>
      <div className="m-page-title mb-3">Products</div>
      <Link to="/addToEcom">
        <Button variant="outline-dark" block>
          + Add Product To Ecom
        </Button>
      </Link>
      <hr />
      {/* <Tabs defaultActiveKey="newProducts" transition={false} variant="tabs">
        <Tab
          title="New Products"
          eventKey="newProducts"
          tabClassName="text-dark"
        >
          <div className="m-2">
            <NewProducts />
          </div>
        </Tab>
        <Tab
          title="Existing Product"
          eventKey="existingProduct"
          tabClassName="text-dark"
        >
        </Tab>
      </Tabs> */}
      <Form>
        <Form.Control
          name="filter key"
          onChange={(e) => setFilterKey(e.target.value)}
          value={filterKey}
          placeholder="Filter by name, type, price"
        />
      </Form>
      <ExistingProducts filterKey={filterKey} />
    </div>
  );
}
