import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProductionList from "./ProductionList";

export default function Index() {
  return (
    <>
      <div className="m-page-title mb-3">Production History</div>
      <Link to="/production/createProduct">
        <Button variant="outline-dark" block>
          + Create Product
        </Button>
      </Link>
      <ProductionList />
    </>
  );
}
