import React, { useContext, useState } from "react";
import { Key } from "react-bootstrap-icons";
import { AuthContext } from "../../../context/AuthContext";
import { useHistory } from "react-router-dom";
import "./style.css";

export default function Index() {
  const passwordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  const { newPasswordChallenge } = useContext(AuthContext);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password === confirmPassword && passwordRegx.test(password)) {
      await newPasswordChallenge(password).then(({ statusCode, response }) => {
        if (statusCode === 200) {
          history.push("/");
        }else{
          setError('Something went wrong')
          console.log(response)
        }
      });
    }else{
      setError('Password does not matched')
    }
  };

  return (
    <>
      <div className="my-container w-100">
        <div className="d-flex justify-content-center h-100">
          <div className="card">
            <div className="card-header">
              <h3>Create New Password</h3>
              {/* <div className="d-flex justify-content-end social_icon">
              <span>
                
                <i className="fab fa-facebook-square"></i>
              </span>
              <span>
                <i className="fab fa-google-plus-square"></i>
              </span>
              <span>
                <i className="fab fa-twitter-square"></i>
              </span>
            </div> */}
            </div>
            <div className="card-body">
              <div className="login-logo">
                {/* <img src={logo} alt="Muthiahs" /> */}
              </div>
              <form onSubmit={handleSubmit}>
                {error && (
                  <div className=" alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                <div className="input-group form-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <Key />
                      <i className="fas fa-user"></i>
                    </span>
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="input-group form-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <Key />

                      <i className="fas fa-key"></i>
                    </span>
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirm password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                {/* <div className="row align-items-center remember">
                <input type="checkbox" />
                Remember Me
              </div> */}
                <div className="row">
                  <div className="form-group col-12">
                    <input
                      type="submit"
                      value="Change Password"
                      className="btn float-right login_btn w-100"
                    />
                  </div>
                </div>
              </form>
            </div>
            {/* <div className="card-footer">
            <div className="d-flex justify-content-center links">
              Don't have an account?<a href="#">Sign Up</a>
            </div>
            <div className="d-flex justify-content-center">
              <a href="#">Forgot your password?</a>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
