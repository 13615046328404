import { apiEndPoint } from "../const";

const path = `/admin/purchase`;

export const getPurchaseList = async () => {
  const res = await fetch(`${apiEndPoint}${path}`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api getPurchaseList >", error));
  return res;
};

export const getPurchaseByKey = async (purchase_key) => {
  const path = `/admin/purchase/purchaseview`;
  const res = await fetch(`${apiEndPoint}${path}?purchase_key=${purchase_key}`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api getPurchaseByKey >", error));
  return res;
};

export const addPurchase = async (data) => {
  console.log('addapi')
    const finalData = {
  
      ...data,
    };
    const res = await fetch(`${apiEndPoint}${path}`, {
      method: "POST",
      mode: "no-cors",
      body: JSON.stringify(finalData),
    })
      .then((res) => {
        return res;
      })
      .catch((error) => console.log("error in api addPurchase >", error));
    return res
    // console.log('upload data - ',data)
  };

  