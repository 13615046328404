import React, { useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import TextField from "../../../components/InputField/Text";
import { addUnit } from "../../../utils/apiFunctions/unit";
import { StateContext } from "../../../context/StateContext";
import { patch } from "../../../utils/apiFunctions";

export default function Index({unitId}) {
  const history = useHistory();
  const { unitList, fetchUnitList } = useContext(StateContext);

  const schema = yup.object().shape({
    name: yup
      .string()
      .required("Field Required")
      .min(1, "Min. 3 char required")
      .max(20, "Max. 20 char required"),
    symbol: yup
      .string()
      .required("Field Required")
      .min(1, "Min. 3 char required")
      .max(10, "Max. 10 char required"),   
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  useEffect(() => {
    if(unitList.length == 0){
      fetchUnitList()
    }
    if (unitId) {
      const temp = unitList?.filter(
        (unit) => unit?.unit_id == unitId
      )[0];
      {console.log(temp)}
      setValue("name", temp?.name || 0);
      setValue("symbol", temp?.symbol || 0);
    }
  }, [])

  const hookFormProp = { errors: errors, reg: register };
    const onSubmit = async (formData, e) => {
      if(unitId){
          await patch(`/admin/unit?unit_id=${unitId}`, formData).then(
            (_)=>{ console.log("unit update ", unitId)
            history.push("/unit")}
          );
      }
      else{     
          await addUnit(formData).then(history.push("/unit"));
      }
      console.log(formData);
    };
  return (
    <>
      <div className="m-page-title mb-3">Add Units</div>
      <Link to="/unit">
        <Button>Units</Button>
      </Link>
      <hr />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <TextField required name="name" label="Unit Name" {...hookFormProp} />
          <TextField
            required
            name="symbol"
            label="Unit Symbol"
            {...hookFormProp}
          />
        </Form.Row>
        <Button variant="success" type="submit">
          Save
        </Button>
      </Form>
    </>
  );
}
