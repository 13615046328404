

// export const phoneRegx = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
export const phoneRegx = /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/

// prod
const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
export const apiEndPoint = REACT_APP_API_ENDPOINT
export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID
export const COGNITO_APP_CLIENT_ID = process.env.REACT_APP_COGNITO_APP_CLIENT_ID


// dev
// export const apiEndPoint = 'http://localhost:3001'
// export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION
// export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID
// export const COGNITO_APP_CLIENT_ID = process.env.REACT_APP_COGNITO_APP_CLIENT_ID