import React from "react";
import { useContext, useEffect } from "react";
import { StateContext } from "../../../context/StateContext";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import LoadingScreen from "../../../components/LoadingScreen/V1";

import "./style.css";
import { get } from "../../../utils/apiFunctions";

export default function Index() {
  const [inventoryHistory, setInventoryHistory] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 20;
  const pageCount = Math.ceil(totalRecords / itemsPerPage);

  async function fetchInventoryHistory(offset = 0) {
    setIsLoading(true);
    await get(`/admin/inventory-history?limit=${itemsPerPage}&offset=${offset}`)
      .then(([status, res]) => {
        console.log(res?.data);
        setInventoryHistory(res?.data);
        setTotalRecords(res?.total_records);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(true);
      });
  }
  useEffect(() => {
    fetchInventoryHistory();
  }, []);

  const changePage = ({ selected }) => {
    setInventoryHistory([]);
    console.log(selected);
    fetchInventoryHistory(itemsPerPage * selected);
  };
  return (
    <div>
      <h2>Inventory History</h2>
      <>
        <Table striped hover responsive>
          <thead>
            <tr>
              <th>S. No.</th>
              <th>ProductType Code</th>
              <th>ProductType Name</th>
              <th>From Site</th>
              <th>To Site</th>
              <th>Initial Qty</th>
              <th>Transfered Qty</th>
              <th>Production Cost/Unit</th>
              <th>Status</th>
              <th>Created On</th>
            </tr>
          </thead>
          {inventoryHistory?.length > 0 ? (
            <tbody>
              {inventoryHistory.map((inventory, idx) => {
                return (
                  <tr key={idx}>
                    <td>{inventory.inventory_history_id}</td>
                    <td>{inventory.product_type}</td>
                    <td>{inventory.product_type_name}</td>
                    <td>{inventory.from_site}</td>
                    <td>{inventory.destination_site}</td>
                    <td>{inventory.qty}</td>
                    <td>{inventory.send_qty}</td>
                    <td>{inventory.production_cost_per_unit}</td>
                    <td>{inventory.status}</td>
                    <td>{inventory.timestamp}</td>
                  </tr>
                );
              })}
            </tbody>
          ) : isLoading ? (
            <LoadingScreen />
          ) : null}
        </Table>
      </>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBtns"}
        previousClassName={"previousBtns"}
        nextClassName={"nextBtn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />
    </div>
  );
}
