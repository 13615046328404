import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { get, patch } from "../../../utils/apiFunctions";
import { formatTime } from "../../../utils/functions";
import LoadingScreen from "../../../components/LoadingScreen/V1";
import { AuthContext } from "../../../context/AuthContext";
import "./style.css";
import { useContext } from "react";

// modals
import ApproveModal from "../StatusModal/approve";

// const Address = ({ title, data }) => {
//   return (
//     <table className="table table-borderless">
//       <thead>
//         <th>
//           {title}
//           {data?.address_type && (
//             <span className="address-type m-1">
//               {data?.address_type}
//             </span>
//           )}
//         </th>
//       </thead>
//       <tbody>
//         <tr>
//           <td>{data?.name}</td>
//         </tr>
//         <tr>
//           <td>{data?.phone}</td>
//         </tr>
//         <tr>
//           <td>{data?.alternate_phone}</td>
//         </tr>
//         <tr>
//           <td>{data?.address_line_1}</td>
//         </tr>
//         <tr>
//           <td>{data?.address_line_2}</td>
//         </tr>
//         <tr>
//           <td>{data?.city}</td>
//         </tr>
//         <tr>
//           <td>
//             {data?.state} {data?.postalcode}
//           </td>
//         </tr>
//         <tr>
//           <td>{data?.country}</td>
//         </tr>
//       </tbody>
//     </table>
//   );
// };

const Menu = ({ options, title, variant }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant={variant || "success"} id="dropdown-basic">
        {title || "Menu"}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {Object.entries(options)?.map((option, index) => (
          <Dropdown.Item key={index} className="text-dark" {...option[1]}>
            {option[0]}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default function Index({ orderKey }) {
  const { userAttributes } = useContext(AuthContext);
  const [billingAddress, setBillingAddress] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [patchData, setPatchData] = useState({});

  // status modal
  const [showApproveModal, setShowApproveModal] = useState(false);

  const fetchOrderDetails = async () => {
    setIsLoading(true);
    await get(`/admin/sales-orders/order?sales_order_id=${orderKey}`).then(
      ([status, res]) => {
        const { shipping_address, billing_address, ...data } = res;
        setShippingAddress(shipping_address);
        console.log(billing_address);
        setBillingAddress(billing_address);
        setData(data);
        setPatchData({
          sales_order_id: orderKey,
          comment: "",
          updated_by: userAttributes?.sub,
          current_order_status: data?.order_status,
          order_qty: data?.qty
        });
        setIsLoading(false);
      }
    );
  };

  const handleStatusUpdate = async (status) => {
    await patch(`/admin/sales-orders?order_status=${status}`, patchData).then(
      (_) => {
        fetchOrderDetails();
      }
    );
    console.log(patchData);
    alert(status);
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [orderKey]);

  const statusOptions = {
    PLACED: {
      color: "secondary",
      options: {
        Approve: {
          onClick: () => setShowApproveModal(true)
        },
        cancel: {
          onClick: () => handleStatusUpdate("CANCELLED"),
          className: "text-danger"
        }
      }
    },
    APPROVED: {
      color: "primary",
      options: {
        Dispatch: {
          onClick: () => handleStatusUpdate("DISPATCHED")
        },
        cancel: {
          onClick: () => handleStatusUpdate("CANCELLED"),
          className: "text-danger"
        }
      }
    },
    DISPATCHED: {
      color: "warning",
      options: {
        Delivered: {
          onClick: () => handleStatusUpdate("DELIVERED")
        },
        cancel: {
          onClick: () => handleStatusUpdate("CANCELLED"),
          className: "text-danger"
        }
      }
    },
    DELIVERED: {
      color: "success",
      options: {
        // Approve: {},
        // cancel: {
        //   onClick: () => {
        //     console.log("cancelled");
        //   },
        //   className: "text-danger",
        // },
      }
    },
    CANCELLED: {
      color: "danger",
      options: {
        // Approve: {},
        // cancel: {
        //   onClick: () => {
        //     console.log("cancelled");
        //   },
        //   className: "text-danger",
        // },
      }
    },
    ECOM_ORDER_CANCELLED: {
      color: "danger",
      options: {
        // Approve: {},
        // cancel: {
        //   onClick: () => {
        //     console.log("cancelled");
        //   },
        //   className: "text-danger",
        // },
      }
    }
  };

  return (
    <>
      {data ? (
        <div className="view-order-detail-wrapper">
          <div className="container row m-2">
            <div className="column col-10 col-xs-10 col-sm-10 col-md-10 col-lg-11">
              <div className="view-order-detail-order-status">
                <span
                  className={`badge badge-${
                    statusOptions?.[data?.order_status]?.color
                  }`}
                >
                  {data?.order_status}
                </span>
              </div>

              <div className="view-order-customer-detail">
                <div className="view-order-customer-name">
                  {data?.salutation} {data?.customer_first_name}
                  {data?.customer_last_name}
                </div>
                <div className="view-order-customer-phone">{data?.phone}</div>
                <div className="view-order-customer-email">{data?.mail}</div>
              </div>
            </div>
            <div className="view-detail-menu-btn d-flex justify-content-center align-items-center ">
              <Menu options={statusOptions?.[data?.order_status]?.options} />
            </div>
          </div>
          <center>
            <div className="view-order-detail-address">
              <div className="shipping">
                <div className="title">
                  Shipping Address
                  {shippingAddress?.address_type && (
                    <span className="address-type m-1">
                      {shippingAddress?.address_type}
                    </span>
                  )}
                </div>

                <div className="content fl">
                  <div className="name">{shippingAddress?.name}</div>
                  <div className="name">{shippingAddress?.phone}</div>
                  <div className="name">{shippingAddress?.alternate_phone}</div>
                  <div className="name">{shippingAddress?.address_line_1}</div>
                  <div className="name">{shippingAddress?.address_line_2}</div>
                  <div className="name">{shippingAddress?.city}</div>
                  <div className="name">
                    {shippingAddress?.state} {shippingAddress?.postalcode}
                  </div>
                  <div className="name">{shippingAddress?.country}</div>
                </div>
              </div>
              <div className="billing">
                <div className="title">
                  Billing Address
                  {billingAddress?.address_type && (
                    <span className="address-type m-1">
                      {billingAddress?.address_type}
                    </span>
                  )}
                </div>

                <div className="content">
                  <div className="name">{billingAddress?.name}</div>
                  <div className="name">{billingAddress?.phone}</div>
                  <div className="name">{billingAddress?.alternate_phone}</div>
                  <div className="name">{billingAddress?.address_line_1}</div>
                  <div className="name">{billingAddress?.address_line_2}</div>
                  <div className="name">{billingAddress?.city}</div>
                  <div className="name">
                    {billingAddress?.state} {billingAddress?.postalcode}
                  </div>
                  <div className="name">{billingAddress?.country}</div>
                </div>
              </div>
            </div>
          </center>
          <div className="view-order-detail-content">
            <div className="img">
              <img src={data?.image_url} alt={data?.display_name} />
            </div>
            <div className="content">
              <div className="product-name">{data?.display_name}</div>

              <div className="product-quantity">
                <span className="text-muted">Qty - </span>
                {data?.qty}
              </div>

              <div className="price">
                <div className="view-order-detail-product-price">
                  <span className="text-muted">Price / Unit : </span>
                  Rs. {data?.price_per_unit}
                </div>
              </div>
              <div className="product-quantity">
                <span className="text-muted">Total Price - </span>
                Rs.{data?.qty * data?.price_per_unit}
              </div>

              <div className="view-order-detail-date">
                <div className="order-date date">
                  <span className="text-muted">Ordered Date - </span>
                  {formatTime(data?.ordered_at)}
                </div>
                <div className="order-date date">
                  <span className="text-muted">Requested Delivery Date - </span>
                  {formatTime(data?.req_delivery_time)}
                </div>
              </div>

              {data?.comments && (
                <>
                  <div className="title mt-3">Comments</div>
                  <div className="product-description text-muted ml-2">
                    {data?.comments}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : isLoading ? (
        <LoadingScreen />
      ) : (
        "No data"
      )}
      <ApproveModal
        show={showApproveModal}
        handleClose={() => {
          setShowApproveModal(false);
        }}
        patchData={patchData}
        order={data}
      />
    </>
  );
}
