import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { v4 } from "uuid";
import { patch, uploadImage } from "../../../../../utils/apiFunctions";
interface ModalProps {
  onHide: () => void;
  show: boolean;
  product: any;
}
export default function Index({ onHide, show, product }: ModalProps) {
  const [image, setImage] = useState<any>(null);
  const [image_key, setImageKey] = useState<any>(v4());
  const [isBtnDisabled, setIsBtnDisabled] = useState<any>(true);
  const [values, setValues] = useState<any>({});
  const [localImageUrl, setLocalImageUrl] = useState<any>(null);
  useEffect(() => {
    setImageKey(v4());
  }, []);
  const getFileExtension = (fileName: any) => {
    const nameList = fileName?.split(".");
    return nameList[nameList?.length - 1];
  };

  const handleImageChange = (files: any) => {
    setIsBtnDisabled(false);
    const file = files[0];
    const fileExt = getFileExtension(file?.name);
    setLocalImageUrl(URL.createObjectURL(file));
    setImage(file);
    console.log(file);
    // setValue("imageUrl", image_key);
    setValues({
      imageUrl: `https://d3rnckbcoykp.cloudfront.net/${image_key}.${fileExt}`
    });
    setIsBtnDisabled(false);
  };

  const onSubmit = async () => {
    if (image) {
      await uploadImage(image_key, image);
      await patch(
        `/admin/ecommerce/${product?.ecommerce_site_id}/image`,
        values
      ).then((_: any) => {
        onHide();
      });
    }
  };
  return (
    <Modal show={show} onHide={onHide} size="lg" centered className="p-3">
      <div className="m-3">
        {/* <Form> */}

        {/* image */}
        {image ? (
          <div className="d-flex justify-content-center">
            <img
              style={{ height: "150px", overflow: "hidden" }}
              src={localImageUrl}
              alt=""
            />
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <img
              style={{ height: "150px", overflow: "hidden" }}
              src={product?.image_url}
              alt=""
            />
          </div>
        )}

        <div>
          <Form.Row className="mb-2 m-2">
            <Form.Label>Select Image</Form.Label>
            <Form.File
              name="productImage"
              label="Product image"
              onChange={(_: any) => handleImageChange(_.target.files)}
              custom
            />
          </Form.Row>
          <div className="d-flex justify-content-center">
            <Button onClick={onSubmit} disabled={isBtnDisabled}>
              Submit
            </Button>
          </div>
        </div>
        {/* </Form> */}
      </div>
    </Modal>
  );
}
