import React from "react";
import { Form } from "react-bootstrap";

export default function Index({
  name,
  hideErrorText = false,
  required,
  label,
  placeholder,
  formGroupProp,
  errors,
  inputProp,
  reg,
  rows
}) {
  const asterisk = <span className="form-error-text ml-1">*</span>;
  return (
    <Form.Group className="col-12" {...formGroupProp}>
      <Form.Label>
        {label}
        {required && asterisk}
      </Form.Label>
      <Form.Control
      rows={rows||3}
        as="textarea"
        placeholder={placeholder || label}
        {...reg(name)}
        name={name}
        className={`${errors?.[name] && "form-error-field"}`}
        {...inputProp}
      />
      {!hideErrorText && (
        <Form.Text className="form-error-text">
          {errors?.[name]?.message}
        </Form.Text>
      )}
    </Form.Group>
  );
}
