import { Button, Form, Modal } from "react-bootstrap";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import NumberField from "../../../components/InputField/Number";
import { AreaContext } from "../../../context/AreaContext";
import { useEffect } from "react";
import { useState } from "react";
import { transfer_to_site } from "../../../utils/apiFunctions/inventory";
import { StateContext } from "../../../context/StateContext";

export default function TransferToSite(props) {
  const { userAttributes } = useContext(AuthContext);
  const { fetchInventoryList } = useContext(StateContext);
  const { siteList, fetchSiteList } = useContext(AreaContext);
  const [transferProduct, setTransferProduct] = useState(props.product);
  useEffect(() => {
    setTransferProduct(props.product);
  }, [props]);

  const schema = yup.object().shape({
    send_qty: yup
      .number()
      .typeError("Qty must be a number")
      .min(1, "Invalid")
      .max(transferProduct?.qty, "Transfer Qty exceeds Inventory qty"),
    destination_site: yup.number(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  useEffect(() => {
    if (props?.show) {
      fetchSiteList();
    }
  }, [props]);

  const hookFormProp = { errors: errors, reg: register };

  const onSubmit = async (formData) => {
    const data = {
      ...formData,
      added_by: userAttributes?.sub,
      inventory_id: transferProduct?.inventory_id,
    };
    await transfer_to_site(data).then(() => {
      fetchInventoryList();
      props.onHide();
      reset();
    });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Transfer to other site
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Lot Key</th>
                <th>Category</th>
                <th>Name</th>
                <th>Qty</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{transferProduct?.lot_key}</td>
                <td>{transferProduct?.category_name}</td>
                <td>{transferProduct?.product_type_name}</td>
                <td>{transferProduct?.qty}</td>
              </tr>
            </tbody>
          </table>

          <Form.Row>
            <NumberField name="send_qty" label="Qty" {...hookFormProp} />

            <Form.Group className="col-xs-12 col-sm-6 col-md-6">
              <Form.Label>Transfer to</Form.Label>

              <Form.Control
                as="select"
                type="number"
                placeholder="Site"
                name="destination_site"
                {...register("destination_site")}
                className={`${errors?.destination_site && "form-error-field"}`}
              >
                <option value="" key="0">
                  Select Site
                </option>
                {siteList
                  ?.filter((site) => site?.site_id !== transferProduct?.site_id)
                  ?.sort((a, b) => a?.site_name > b?.site_name)
                  ?.map((site, index) => (
                    <option value={site?.site_id} key={index}>
                      {site?.site_name} ({site?.site_code})
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.onHide();
              reset();
            }}
            variant="danger"
          >
            Close
          </Button>
          <Button type="submit" variant="success">
            Transfer
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
