import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Modal, Button, Table, Form } from "react-bootstrap";
import { get, patch } from "../../../../utils/apiFunctions";
import { useHistory } from "react-router-dom";

export default function Index({ show, handleClose, patchData, order }) {
  const history = useHistory();
  const [data, setData] = useState(patchData);
  const [showInventoryModal, setShowInventoryModal] = useState(false);
  const [inventoryList, setInventoryList] = useState([]);
  const [assignedList, setAssignedList] = useState([]);
  const [assignedQty, setAssignedQty] = useState(0);
  const [showInventoryItemModal, setShowInventoryItemModal] = useState(false);
  const [tempQty, setTempQty] = useState("");
  const [selectedInventoryItem, setSelectedInventoryItem] = useState({});

  const getAssignedQty = (assignedList) => {
    //   get assigned qty from inventory
    let temp = 0;
    if (assignedList?.length > 0) {
      assignedList?.map((_) => {
        temp = temp + _?.qty;
      });
    }
    setAssignedQty(temp);
  };

  useEffect(() => {
    getAssignedQty(assignedList);
  }, [assignedList]);

  const fetchInventoryList = async (inventoryId = null) => {
    // &site_id=${SITE_ID}
    let query = `is_approved=1&product_type_id=${order?.product_type_id}`;
    if (inventoryId) {
      query = query + `&inventory_id=${inventoryId}`;
      console.log("inventory_id = ", inventoryId);
    }
    await get(`/admin/inventory?${query}`).then(([status, res]) => {
      if (status === 200) {
        setInventoryList(res);
      }
      console.log(status, res);
    });
  };

  useEffect(() => {
    if (show) {
      fetchInventoryList(order?.inventory_id);
    }
    console.log(order?.product_type_id);
    setData(patchData);
  }, [show]);
  const removeFromProduct = (inventoryId) => {
    const temp = assignedList?.filter((_) => _?.inventory_id !== inventoryId);
    setAssignedList(temp);
  };

  const onSubmit = async () => {
    let inventory = [];
    assignedList?.map((_) => {
      inventory = [
        ...inventory,
        {
          id: _?.inventory_id,
          qty: _?.qty
        }
      ];
    });
    await patch(`/admin/sales-orders?order_status=APPROVED`, {
      ...data,
      inventory
    }).then((_) => {
      history.push("/salesOrder/new");
    });
  };
  return (
    <>
      {/* main - assigned inventory */}
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>Assign Inventory</Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-row m-2">
            <div className="flex-column col-7 lead float-left">
              <div className="row">Ordered Qty - {order?.qty}</div>
              <div className="row">Assigned Qty - {assignedQty}</div>
            </div>
            <div className="flex-column col-7 float-right ml-1">
              <Button onClick={() => setShowInventoryModal(true)}>
                + Assign
              </Button>
            </div>
          </div>
          {assignedList?.length > 0 && (
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Lot key</th>
                  <th> Name</th>
                  <th>Total Qty</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {assignedList?.map((product, idx) => {
                  return (
                    <tr key={product?.inventory_id}>
                      <td>{idx + 1}</td>
                      <td>{product?.lot_key}</td>
                      <td>{product?.product_type_name}</td>
                      <td>{product?.qty}</td>
                      <td>
                        <Button
                          variant="danger"
                          onClick={() => {
                            removeFromProduct(product?.inventory_id);
                          }}
                        >
                          -
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            disabled={order?.qty !== assignedQty}
            onClick={() => onSubmit()}
          >
            Approve
          </Button>
        </Modal.Footer>
      </Modal>

      {/* inventory list */}
      <Modal
        show={showInventoryModal}
        onHide={() => setShowInventoryModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>Inventory</Modal.Header>
        {inventoryList?.length > 0 ? (
          <>
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Lot key</th>
                  <th> Name</th>
                  <th>Quantity</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {inventoryList
                  .filter(
                    ({ inventory_id: id1 }) =>
                      !assignedList.some(({ inventory_id: id2 }) => id2 === id1)
                  )
                  ?.map((product, idx) => {
                    return (
                      <tr key={product?.inventory_id}>
                        <td>{idx + 1}</td>
                        <td>{product?.lot_key}</td>
                        <td>{product?.product_type_name}</td>
                        <td>{product?.qty}</td>
                        <td>
                          <Button
                            onClick={() => {
                              setSelectedInventoryItem(product);
                              setShowInventoryItemModal(true);
                            }}
                            variant="primary"
                          >
                            +
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </>
        ) : (
          "No Item In Inventory"
        )}
      </Modal>

      {/* qty */}
      <Modal
        show={showInventoryItemModal}
        onHide={() => {
          setShowInventoryItemModal(false);
          setTempQty("");
        }}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedInventoryItem?.product_type_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped hover responsive>
            <thead>
              <tr>
                <th>Lot key</th>
                <th>Quantity </th>
              </tr>
            </thead>

            <tbody>
              <tr key={selectedInventoryItem?.inventory_id}>
                <td>{selectedInventoryItem?.lot_key}</td>
                <td>{selectedInventoryItem?.qty}</td>
              </tr>
            </tbody>
          </Table>
          <Form.Row>
            <Form.Control
              type="number"
              onChange={(_) => {
                setTempQty(_.target.value);
              }}
              value={tempQty}
            />
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              !tempQty || tempQty > selectedInventoryItem?.qty || tempQty == 0
            }
            variant="success"
            onClick={() => {
              setShowInventoryItemModal(false);
              setShowInventoryModal(false);
              setAssignedList([
                ...assignedList,
                {
                  inventory_id: selectedInventoryItem?.inventory_id,
                  product_type_name: selectedInventoryItem?.product_type_name,
                  qty: parseInt(tempQty),
                  lot_key: selectedInventoryItem?.lot_key
                }
              ]);

              setTempQty("");
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
