import React, { useState } from "react";
import "./style.css";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Card from "./Card";
import Header from "../../components/Header-dashboard";
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});
export default function Index() {
    const history = useHistory()
    const classes = useStyles();

  return (
    <>
    <Header/>
      <div style={{backgroundColor:'#EEF9FC'}}>
        <center>
          <h2 className="heading"  style={{paddingTop:'10px'}}>Your Journey Begins Here</h2>
        </center>
    
        <div>
          {/* <Card className={classes.root}>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Associate Trainee
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                to="/career/positions/${jobPosition}"
              >
                Open Position
              </Button>
            </CardActions>
          </Card> */}
          
          {/* {Object.entries(jobPositions)?.map((entry) => (
              <div onClick={()=>{history.push(`/career/${entry[0]}`)}}>{entry[1].title}
          
            </div>
          ))}
          {console.log(jobPositions)} */}
             
          
       
<div class="card-content">
          <Card />
    </div>
          
         
        
        </div>
      </div>
      <br/>
        <br/>
    </>
  );
}
