import React, { useContext, useState } from "react";
import { StateContext } from "../../../context/StateContext";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import LoadingScreen from "../../../components/LoadingScreen/V1";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import TransferToSite from "../Modal/TransferToSite";
import { patch } from "../../../utils/apiFunctions";
import { AuthContext } from "../../../context/AuthContext";
import { useEffect } from "react";

export default function Index() {
  const { userAttributes } = useContext(AuthContext);
  const { inventoryList, isLoading, fetchInventoryList } =
    useContext(StateContext);
  const [showTransferToSite, setShowTransferToSite] = useState(false);
  const [transferProduct, setTransferProduct] = useState({});
  useEffect(() => {
    fetchInventoryList();
  }, []);
  return (
    <>
      {inventoryList?.length > 0 ? (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Lot Key</th>
              <th>Category</th>
              <th>Name</th>
              <th>Qty</th>
              <th>Site</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {inventoryList?.map((inventory, idx) => {
              return (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td
                    className={
                      inventory?.is_approved ? "text-success" : "text-danger"
                    }
                  >
                    {inventory?.lot_key}
                  </td>
                  <td>{inventory?.category_name}</td>
                  <td>{inventory?.product_type_name}</td>
                  <td>{inventory?.qty}</td>
                  <td>{inventory?.site_name}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="dark" id="dropdown-basic">
                        <ThreeDotsVertical />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {!inventory?.is_approved && (
                          <Dropdown.Item
                            onClick={async () => {
                              await patch("/admin/inventory?function=approve", {
                                approved_by: userAttributes?.sub,
                                inventory_id: inventory?.inventory_id
                              }).then((_) => {
                                console.log("updated");
                                fetchInventoryList();
                              });
                              console.log("approved");
                            }}
                          >
                            <div className="text-dark">Approve</div>
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item
                          onClick={() => {
                            setTransferProduct(inventory);
                            setShowTransferToSite(true);
                          }}
                        >
                          <div className="text-dark">
                            Transfer to other site
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : isLoading ? (
        <LoadingScreen />
      ) : (
        <div>No product in inventory</div>
      )}
      <TransferToSite
        show={showTransferToSite}
        product={transferProduct}
        onHide={() => setShowTransferToSite(false)}
      />
    </>
  );
}
