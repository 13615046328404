/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { v4, validate } from "uuid";
import { AuthContext } from "../../../../../context/AuthContext";
import { getDate } from "../../../../../helpers/date";
import { post, uploadImage } from "../../../../../utils/apiFunctions";

interface AddCarousalModalProps {
  isVisible: Boolean;
  handleVisible: () => void;
}
export default function Index({
  isVisible,
  handleVisible
}: AddCarousalModalProps) {
  const { userAttributes } = useContext(AuthContext);
  const initialValues = {
    image: "",
    valid_till: "",
    added_by: userAttributes?.sub
  };
  const [values, setValues] = useState(initialValues);
  const setValue = (key: string, value: any) => {
    setValues({ ...values, [key]: value });
  };
  const [image, setImage] = useState<any>(null);
  const [image_key, setImageKey] = useState(v4());
  const [localImageUrl, setLocalImageUrl] = useState<any>(null);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  useEffect(() => {
    setImageKey(v4());
  }, []);
  const getFileExtension = (fileName: string) => {
    const nameList = fileName?.split(".");
    return nameList[nameList?.length - 1];
  };
  const handleImageChange = (files: any) => {
    const file = files[0];
    const fileExt = getFileExtension(file?.name);
    setLocalImageUrl(URL.createObjectURL(file));
    setImage(file);
    console.log(file);
    setValue(
      "image",
      `https://d3rnckbcoykp.cloudfront.net/${image_key}.${fileExt}`
    );
    setIsBtnDisabled(false);
  };
  const onSubmit = async () => {
    console.log(values);
    await uploadImage(image_key, image);
    await post("/admin/ecom-carousals", values).then(([status, res]) => {
      if (status === 200) {
        alert("success");
        handleVisible();
      } else {
        console.log(res);
        alert("error ");
      }
    });
  };

  const handleClose = () => {
    setLocalImageUrl(null);
    setImage(null);
    setValues(initialValues);
    handleVisible();
  };

  const validate = () => {
    return Boolean(values?.valid_till && values?.image && values?.image);
  };
  useEffect(() => {
    setIsBtnDisabled(!validate());
  }, [values]);
  return (
    <Modal backdrop="static" show={isVisible} onHide={handleVisible} centered>
      <div className="p-3">
        {/* image */}
        {image ? (
          <div className="d-flex justify-content-center">
            <img
              style={{ height: "150px", overflow: "hidden" }}
              src={localImageUrl}
              alt=""
            />
          </div>
        ) : (
          ""
        )}
        <div className="d-flex flex-column">
          <input
            type="file"
            name="File"
            className="form-control m-1"
            title="carousal image"
            onChange={(e) => handleImageChange(e.target.files)}
          />

          <div className="m-1">
            <label htmlFor="s">Description</label>
            <input
              type="text"
              name="s"
              className="form-control"
              title="description"
              onChange={(e) => setValue("description", e.target.value)}
            />
          </div>
          <div className="m-1">
            <label htmlFor="validTill">Valid Till</label>
            <input
              type="date"
              name="validTill"
              min={getDate("yyyy-mm-dd")}
              className="form-control"
              title="validTill"
              onChange={(e) => setValue("valid_till", e.target.value)}
            />
          </div>
        </div>

        <div className="d-flex flex-row justify-content-around mt-2">
          <button
            className="btn btn-success"
            onClick={onSubmit}
            disabled={isBtnDisabled}
          >
            Upload
          </button>
          <button className="btn btn-light" onClick={handleClose}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}
