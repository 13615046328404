import React, { useContext } from "react";
import CategoryList from "./CategoryList";
import AddCategory from "./AddCategory";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { StateContext } from "../../context/StateContext";
import { useEffect } from "react";

export default function Index() {
  const { fetchCategoryList } = useContext(StateContext);
  useEffect(() => {
    fetchCategoryList();
  }, []);
  return (
    <>
      <div className="m-page-title mb-3"> Category List</div>
      <Link to="/addCategory">
        <Button variant="outline-dark" block>
          + Add Category
        </Button>
      </Link>
      <hr />
      <CategoryList />
    </>
  );
}
