import React from "react";
import Header from "../../components/Header-dashboard";
import "./style.css";

export default function Index() {
  return (
    <>
      <Header />

      {/* <div className="landertext">
        <div className="maincontent"></div> */}
      {/* <img className="bg" src={Bg}/> */}

      {/* <center>
          <img className="image" src="https://d3rnckbcoykp.cloudfront.net/logo.png" />
          <h1 className="landerh1">
            Content goes here.....
          </h1>
        </center>
      </div> */}

      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h1>Agriculture with New Perspective</h1>
              <h2>Adding Green to your Life</h2>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <a href="#about" className="btn-get-started scrollto">
                  Get Started
                </a>
              </div>
            </div>
            <div
              className="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img
                className="img-fluid animated"
                src="https://d3rnckbcoykp.cloudfront.net/frontend/landingpage/farm-house.png"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about">
        <div className="container-fluid" data-aos="fade-up">
          <div className="section-title">
            <h2>About Us</h2>
          </div>

          <div className="row">
            <div className="col-lg-7 flex flex-column justify-content align-items-stretch  order-2 order-lg-1">
              <div className="content">
                <h3>About Muthiahs</h3>
                Look at the yield of the farmers without any intermediators in
                the idea of the increasing the income of the farmers to uplift
                the economy of rural areas, we give them the real price and get
                the yield directly. The goal of the company is to make quality
                products available to all people equally at an affordable price
                without making too much profit.
              </div>
            </div>
            <div
              className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
              style={{
                backgroundImage:
                  "url('https://d3rnckbcoykp.cloudfront.net/frontend/landingpage/skills.png')",
              }}
              data-aos="zoom-in"
              data-aos-delay="150"
            >
              &nbsp;
            </div>
          </div>
        </div>
      </section>

      <section id="why-us" className="why-us section-bg">
        <div className="container-fluid" data-aos="fade-up">
          <div className="section-title">
            <h2>Join Us</h2>
          </div>
          <div className="row">
            <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
              <div className="content">
                <h3>
                  Join <strong>Our Team</strong>
                </h3>
                Join us as a salesperson or customer to uplift not only our
                quality but also the quality and economic status of everyone
                around us. In Muthiahs Farm Product Private Limited, Goats,
                Cows, Poultry, Coconuts, Fruits, Vegetables, Oils, Dairy and
                Bakery products are all available to at wholesale and retail
                prices. Our company was started with the idea of improving the
                livelihood and quality of life of the people in the rural areas.
                Its first attempt is to take this company to all the villages
                with the idea of creating employment opportunities for the rural
                youths near where they are.
              </div>
            </div>

            <div
              className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
              style={{
                backgroundImage:
                  "url( 'https://d3rnckbcoykp.cloudfront.net/frontend/landingpage/join-us.png')",
              }}
              data-aos="zoom-in"
              data-aos-delay="150"
            >
              &nbsp;
            </div>
          </div>
        </div>
      </section>

      <footer id="footer">
        <div className="container footer-bottom clearfix">
          <div className="copyright">
            &copy; Copyright{" 2021 "}
            <strong>
              <span>Muthiahs.com</span>
            </strong>
            . All Rights Reserved.
          </div>
          <div>
            Designed & Maintained by{" "}
            <a target="_blank" href="https://xbi4.com/">
              Xbi4
            </a>
          </div>
        </div>
      </footer>
    </>
  );
}
