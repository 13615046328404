import { apiEndPoint } from "../const";

export const getInventoryList = async () => {
  const res = await fetch(`${apiEndPoint}/admin/inventory`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api getCategoryList >", error));
  console.log(res);
  return res;
};

export const addProduct = async (data) => {
  const finalData = {
    product_type_id: null,
    qty: 0,
    site_id: null,
    for_sales: 0,
    production_cost_per_unit: 0,
    expiry_date: null,
    ...data,
  };
  const res = await fetch(`${apiEndPoint}/admin/inventory?function=add`, {
    method: "POST",
    mode: "no-cors",
    body: JSON.stringify(finalData),
  })
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api addProduct >", error));
  console.log(res);
  return res;
};


export const transfer_to_site = async (data) => {
  const finalData = {
    "inventory_id": null, 
    "send_qty": null,
    "destination_site": null , 
    "added_by": null, 
    ...data,
  };
  const res = await fetch(`${apiEndPoint}/admin/inventory?function=transfer_to_site`, {
    method: "POST",
    mode: "no-cors",
    body: JSON.stringify(finalData),
  })
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api transfer_to_site >", error));
  return res;
};
