import React, { useEffect, useState } from "react";
import { get } from "../../../../../utils/apiFunctions";
import CarousalModal from "../CarousalModal";

export default function Index() {
  const [carousalList, setCarousalList] = useState([]);
  const [selectedCarousal, setSelectedCarousal] = useState(null);
  const [showCarousalModal, setShowCarousalModal] = useState(false);
  const openCarousalModal = (data: any) => {
    setShowCarousalModal(true);
    setSelectedCarousal(data);
  };
  const closeCarousalModal = async (e: any) => {
    setShowCarousalModal(false);
    setSelectedCarousal(null);
    if (e?.refresh) {
      await fetchCarousalList();
    }
  };
  const fetchCarousalList = async () => {
    await get("/admin/ecom-carousals").then(([status, res]) => {
      if (status === 200) {
        setCarousalList(res?.data);
      }
    });
  };

  useEffect(() => {
    fetchCarousalList();
  }, []);

  return (
    <div className="container">
      <div className="d-flex flex-column w-100">
        {carousalList?.length > 0 &&
          carousalList?.map((carousal: any, idx) => {
            return (
              <div
                className="d-flex flex-row m-2 cursor-p"
                key={idx}
                onClick={() => openCarousalModal(carousal)}
              >
                <div className="mx-2" style={{ width: "200px" }}>
                  <img src={carousal.image} alt="" width="100%" />
                </div>
                <div className="">
                  <div>Valid till - {carousal?.valid_till}</div>
                  <div>Description - {carousal?.description}</div>
                  {carousal?.is_active ? (
                    <div className="badge badge-success ">Enabled</div>
                  ) : (
                    <div className="badge badge-warning ">Disabled</div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      <CarousalModal
        isVisible={showCarousalModal}
        handleClose={(e) => closeCarousalModal(e)}
        carousalData={selectedCarousal}
      />
    </div>
  );
}
