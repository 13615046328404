import React from 'react'
import './style.css'
export default function Index() {
    return (
        <div className='loading-screen-main'>
            <div className="loading-screen-loader">
                
            </div>
        </div>
    )
}
