import { apiEndPoint } from "../const";
const path = "/admin/category";

export const getCategoryList = async () => {
  const res = await fetch(`${apiEndPoint}${path}`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api getCategoryList >", error));
  console.log(res);
  return res;
};

export const addCategory = async (data) => {
  const finalData = {
    image_url: "",
    parent_id: 0,
    for_sales: 0,
    name: "",
    ...data,
  };
  const res = await fetch(`${apiEndPoint}${path}`, {
    method: "POST",
    mode: "no-cors",
    body: JSON.stringify(finalData),
  })
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api addCategory >", error));
  console.log(res);
  return res;
};
