import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { EcomContext } from "../../../context/EcomContext";
import LoadingScreen from "../../../components/LoadingScreen/V1";
import { useState } from "react";
import ExistingProductModal from "../modal/existingProduct";
// import { useHistory } from "react-router-dom";
import { PriceChangeModal } from "./components";
import { ImageChangeModal } from "./components";
import { patch } from "../../../utils/apiFunctions";

import { v4 } from "uuid";

export default function Index({ filterKey }) {
  // const history = useHistory();

  const [showProduct, setShowProduct] = useState(false);
  const [product, setProduct] = useState(null);
  const [showPriceChangeModal, setShowPriceChangeModal] = useState(false);
  const [showImageChangeModal, setShowImageChangeModal] = useState(false);

  const { existingProducts, fetchExistingProducts, isLoading } =
    useContext(EcomContext);
  useEffect(() => {
    fetchExistingProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ? filter functions
  const [filteredProducts, setFilteredProducts] = useState(existingProducts);
  useEffect(() => {
    setFilteredProducts(existingProducts);
  }, [existingProducts]);

  const handleFilter = () => {
    if (filterKey === "") {
      setFilteredProducts(existingProducts);
    } else {
      const filteredProducts = existingProducts.filter((product) => {
        const keyToCheck =
          product.display_name +
          product.product_type_id +
          product.offer_price +
          product.product_price;
        return keyToCheck.toLowerCase().includes(filterKey.toLowerCase());
      });
      setFilteredProducts(filteredProducts);
    }
  };
  useEffect(() => {
    handleFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterKey]);

  // ? change image function
  const [image, setImage] = useState(null);
  const [image_key, setImageKey] = useState(v4());
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [values, setValues] = useState({});
  const [localImageUrl, setLocalImageUrl] = useState(null);
  useEffect(() => {
    setImageKey(v4());
  }, []);
  const getFileExtension = (fileName) => {
    const nameList = fileName?.split(".");
    return nameList[nameList?.length - 1];
  };

  const handleImageChange = (files) => {
    const file = files[0];
    const fileExt = getFileExtension(file?.name);
    setLocalImageUrl(URL.createObjectURL(file));
    setImage(file);
    console.log(file);
    // setValue("imageUrl", image_key);
    setValues({
      imageUrl: `https://d3rnckbcoykp.cloudfront.net/${image_key}.${fileExt}`
    });
    setIsBtnDisabled(false);
  };

  // ------------------------

  const disableProduct = async (ecommerce_site_id) => {
    await patch(`/admin/ecommerce/${ecommerce_site_id}?f=disable`, {
      is_disable: 1
    }).then(() => fetchExistingProducts());
  };
  const enableProduct = async (ecommerce_site_id) => {
    await patch(`/admin/ecommerce/${ecommerce_site_id}?f=disable`, {
      is_disable: 0
    }).then(() => fetchExistingProducts());
  };
  const deleteProduct = async (ecommerce_site_id) => {
    await patch(`/admin/ecommerce/${ecommerce_site_id}?f=delete`, {
      is_delete: 1
    }).then(() => fetchExistingProducts());
  };

  return (
    <>
      {filteredProducts?.length > 0 ? (
        <>
          {filteredProducts?.map((e, idx) => {
            return (
              <div
                className={`rounded shadow-sm w-100 d-flex flex-row m-2 align-items-center ${
                  e?.is_disabled && "alert-danger"
                }`}
                key={idx}
              >
                <div className="col-1 d-flex align-items-center">{idx + 1}</div>

                <div
                  className="col-3"
                  style={{ height: "100px", overflow: "hidden" }}
                >
                  <img src={e?.image_url} alt="" srcset="" height="100%" />
                </div>

                <div className="col-6">
                  <div className="f-b">{e?.display_name}</div>
                  <div>
                    Price: {e?.currency} {e?.product_price}
                  </div>
                  {e?.is_offer_active ? (
                    <>
                      <div>
                        Offer Price : {e?.currency} {e?.offer_price}
                      </div>
                    </>
                  ) : null}
                  <div>Type: {e?.product_type_id}</div>
                </div>

                <div className="col-2">
                  <Dropdown>
                    <Dropdown.Toggle variant="dark" id="dropdown-basic">
                      <ThreeDotsVertical />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <Dropdown.Item href="#/action-2">
                          <div className="text-dark">Edit</div>
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          <div className="text-dark">Send for processing</div>
                        </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => {
                          setProduct(e);
                          setShowProduct(true);
                        }}
                      >
                        <div className="text-dark">View</div>
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => {
                          setProduct(e);
                          setShowPriceChangeModal(true);
                        }}
                      >
                        <div className="text-dark">Change Price</div>
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => {
                          setProduct(e);
                          setShowImageChangeModal(true);
                        }}
                      >
                        <div className="text-dark">Change Image</div>
                      </Dropdown.Item>

                      {e?.is_disabled ? (
                        <Dropdown.Item
                          onClick={() => enableProduct(e?.ecommerce_site_id)}
                        >
                          <div className="text-success">Enable</div>
                        </Dropdown.Item>
                      ) : null}

                      <Dropdown.Item
                        onClick={() => {
                          if (e?.is_disabled) {
                            deleteProduct(e?.ecommerce_site_id);
                          } else {
                            disableProduct(e?.ecommerce_site_id);
                          }
                        }}
                      >
                        <div className="text-danger">
                          {e?.is_disabled ? "Delete" : "Disable"}
                        </div>
                      </Dropdown.Item>
                      {/* <Dropdown.Item
                          onClick={() => {
                            history.push(
                              `/edit/ecomProduct/${product?.ecommerce_site_id}`
                            );
                          }}
                        >
                          <div className="text-dark">Edit</div>
                        </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            );
          })}
        </>
      ) : isLoading ? (
        <LoadingScreen />
      ) : (
        <div>No new products in your territory</div>
      )}
      <ExistingProductModal
        show={showProduct}
        onHide={() => setShowProduct(false)}
        product={product}
      />
      <PriceChangeModal
        product={product}
        show={showPriceChangeModal}
        onHide={() => setShowPriceChangeModal(false)}
      />

      <ImageChangeModal
        product={product}
        show={showImageChangeModal}
        onHide={() => setShowImageChangeModal(false)}
      />
    </>
  );
}
