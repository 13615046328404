import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatTime } from "../../utils/functions";
import "./style.css";

const statusColor = {
  PLACED: "secondary",
  APPROVED: "primary",
  DISPATCHED: "warning",
  DELIVERED: "success",
  CANCELLED: "danger",
  ECOM_ORDER_CANCELLED: "danger",
};

export default function Index({ order }) {
  return (
    <div className="sales-order">
      <div className="sales-order-product-image">
        <img src={order?.image_url} alt={order?.display_name} />
      </div>
      <div className="sales-order-content">
        <div className="sales-order-title">{order?.display_name}</div>
        {/* <div className="sales-order-description text-muted">
              {order?.productDesc}
            </div> */}
        <div className="sales-order-status">
          Status -{" "}
          <span className={`badge badge-${statusColor[order?.order_status]}`}>{order?.order_status}</span>
        </div>
        <div className="sales-order-status">
          Ordered on -{" "}
          <span className="status">{formatTime(order?.ordered_at)}</span>
        </div>
        {order?.req_delivery_time.split("-")[0] !== "0000" && (
          <div className="sales-order-status">
            Requested Delivery Time -{" "}
            <span className="status">
              {formatTime(order?.req_delivery_time)}
            </span>
          </div>
        )}
      </div>
      <div className="sales-order-view-btn">
        <Link to={`/viewOrderDetail/${order?.sales_order_id}`}>
          <Button variant="outline-success">View Details</Button>
        </Link>
      </div>
    </div>
  );
}
