export const jobPositions = {
  AssociateTraineeSales: {
    title: "Associate Trainee Sales",
    "Job Description":
      "Repsonsible to analyze and develop end to end solutions for client needs Collaborate with Team members regularlly to develop product / solutions which is sustainable for long term Manage delivery timelines and deliver high quality outputs Engage with cross funcatioinal teams effectively to produce end result in a timely manner Manage across project teams expectations and commitment to clients Demonstrates Project Management Skills Proficient in MS office tools.",
    Qualification: ["Bachelor's/Masters"],
    "Years Of Experience": "Fresher or Experienced",
    "Technical Skills": [
      "POS System",
      "Ms Office",
      "Mathematical Knowledge",
      "Credit Debit management"
    ],
    "Other Skills": [
      "Agile",
      "Passionate",
      "Colloborative",
      "Willing to Learn",
      "Innovative"
    ],

    "People Skills": [
      "Knowledge about Product",
      "Local Marketing",
      "Team player",
      "Result Orienter",
      "Excellent Communication"
    ],
    "Job Location": "Tenkasi"
  },

  AssociateSales: {
    title: "Associate Sales",
    "Job Description":
      "Repsonsible to analyze and develop end to end solutions for client needs Collaborate with Team members regularlly to develop product / solutions which is sustainable for long term Manage delivery timelines and deliver high quality outputs Engage with cross funcatioinal teams effectively to produce end result in a timely manner Manage across project teams expectations and commitment to clients Demonstrates Project Management Skills Proficient in MS office tools.",
    Qualification: ["Bachelor's/Masters"],
    "Years Of Experience": "Fresher or Experienced",
    "Technical Skills": [
      "POS System",
      "Ms Office",
      "Mathematical Knowledge",
      "Credit Debit management"
    ],
    "Other Skills": [
      "Agile",
      "Passionate",
      "Colloborative",
      "Willing to Learn",
      "Innovative"
    ],

    "People Skills": [
      "Knowledge about Product",
      "Local Marketing",
      "Team player",
      "Result Orienter",
      "Excellent Communication"
    ],
    "Job Location": "Tenkasi"
  },

  AssociateTraineeProduction: {
    title: "Associate Trainee Production",
    "Job Description":
      "Repsonsible to analyze and develop end to end solutions for client needs Collaborate with Team members regularlly to develop product / solutions which is sustainable for long term Manage delivery timelines and deliver high quality outputs Engage with cross funcatioinal teams effectively to produce end result in a timely manner Manage across project teams expectations and commitment to clients Demonstrates Project Management Skills Proficient in MS office tools.",
    Qualification: ["Bachelor's/Masters"],
    "Years Of Experience": "Fresher or Experienced",
    "Technical Skills": [
      "POS System",
      "Ms Office",
      "Mathematical Knowledge",
      "Credit Debit management"
    ],
    "Other Skills": [
      "Agile",
      "Passionate",
      "Colloborative",
      "Willing to Learn",
      "Innovative"
    ],

    "People Skills": [
      "Knowledge about Product",
      "Local Marketing",
      "Team player",
      "Result Orienter",
      "Excellent Communication"
    ],
    "Job Location": "Tenkasi"
  },
  TerritoryBusinessManager: {
    title: "Territory Business Manager",
    "Job Description":
      "Managing organizational sales by developing a business plan that covers sales, revenue, and expense controls. \n Meeting planned sales goals.\n \n Setting individual sales targets with the sales team. \n Tracking sales goals and reporting results as necessary. \n Overseeing the activities and performance of the sales team. \n Coordinating with marketing on lead generation. \n Developing your sales team through motivation, counselling, and product knowledge education. \n Promoting the organization and products. \n Understand our ideal customers and how they relate to our products.",
    Qualification: [
      "Bachelor’s degree in business or related field.",
      "Experience in planning and implementing sales strategies."
    ],
    "Years Of Experience": "2 Years’ Experience",
    "Technical Skills": [
      "Experience managing and directing a sales team.",
      "Excellent written and verbal communication skills.",
      "Dedication to providing great customer service.",
      "Ability to lead a sales team."
    ],
    "Other Skills": [
      "Agile",
      "Passionate",
      "Colloborative",
      "Willing to Learn",
      "Innovative"
    ],

    "People Skills": [
      "Knowledge about Product",
      "Local Marketing",
      "Team player",
      "Result Orienter",
      "Excellent Communication"
    ],
    "Job Location":
      "Tenkasi, Kadayanallur, Sankarankovi, Pavoorchatram, Alangulam"
  },

  AssociateProduction: {
    title: "Associate Production",
    "Job Description":
      "Repsonsible to analyze and develop end to end solutions for client needs Collaborate with Team members regularlly to develop product / solutions which is sustainable for long term Manage delivery timelines and deliver high quality outputs Engage with cross funcatioinal teams effectively to produce end result in a timely manner Manage across project teams expectations and commitment to clients Demonstrates Project Management Skills Proficient in MS office tools.",
    Qualification: ["Bachelor's/Masters"],
    "Years Of Experience": "Fresher or Experienced",
    "Technical Skills": [
      "POS System",
      "Ms Office",
      "Mathematical Knowledge",
      "Credit Debit management"
    ],
    "Other Skills": [
      "Agile",
      "Passionate",
      "Colloborative",
      "Willing to Learn",
      "Innovative"
    ],

    "People Skills": [
      "Knowledge about Product",
      "Local Marketing",
      "Team player",
      "Result Orienter",
      "Excellent Communication"
    ],
    "Job Location": "Tenkasi"
  },

  AssociateTraineeProcurement: {
    title: "Associate Trainee Procurement",
    "Job Description":
      "Repsonsible to analyze and develop end to end solutions for client needs Collaborate with Team members regularlly to develop product / solutions which is sustainable for long term Manage delivery timelines and deliver high quality outputs Engage with cross funcatioinal teams effectively to produce end result in a timely manner Manage across project teams expectations and commitment to clients Demonstrates Project Management Skills Proficient in MS office tools.",
    Qualification: ["Bachelor's/Masters"],
    "Years Of Experience": "Fresher or Experienced",
    "Technical Skills": [
      "POS System",
      "Ms Office",
      "Mathematical Knowledge",
      "Credit Debit management"
    ],
    "Other Skills": [
      "Agile",
      "Passionate",
      "Colloborative",
      "Willing to Learn",
      "Innovative"
    ],

    "People Skills": [
      "Knowledge about Product",
      "Local Marketing",
      "Team player",
      "Result Orienter",
      "Excellent Communication"
    ],
    "Job Location": "Tenkasi"
  },

  AssociateProcurement: {
    title: "Associate Procurement",
    "Job Description":
      "Repsonsible to analyze and develop end to end solutions for client needs Collaborate with Team members regularlly to develop product / solutions which is sustainable for long term Manage delivery timelines and deliver high quality outputs Engage with cross funcatioinal teams effectively to produce end result in a timely manner Manage across project teams expectations and commitment to clients Demonstrates Project Management Skills Proficient in MS office tools.",
    Qualification: ["Bachelor's/Masters"],
    "Years Of Experience": "Fresher or Experienced",
    "Technical Skills": [
      "POS System",
      "Ms Office",
      "Mathematical Knowledge",
      "Credit Debit management"
    ],
    "Other Skills": [
      "Agile",
      "Passionate",
      "Colloborative",
      "Willing to Learn",
      "Innovative"
    ],

    "People Skills": [
      "Knowledge about Product",
      "Local Marketing",
      "Team player",
      "Result Orienter",
      "Excellent Communication"
    ],
    "Job Location": "Tenkasi"
  },

  SalesManager: {
    title: "Sales Manager",
    "Job Description":
      "Repsonsible to analyze and develop end to end solutions for client needs Collaborate with Team members regularlly to develop product / solutions which is sustainable for long term Manage delivery timelines and deliver high quality outputs Engage with cross funcatioinal teams effectively to produce end result in a timely manner Manage across project teams expectations and commitment to clients Demonstrates Project Management Skills Proficient in MS office tools.",
    Qualification: ["Bachelor's/Masters"],
    "Years Of Experience": "Bachelor's - 3 years, Masters - 2 years",
    "Technical Skills": [
      "POS System",
      "Ms Office",
      "Mathematical Knowledge",
      "Credit Debit management"
    ],
    "Other Skills": [
      "Agile",
      "Passionate",
      "Colloborative",
      "Willing to Learn",
      "Innovative"
    ],

    "People Skills": [
      "Knowledge about Product",
      "Local Marketing",
      "Team player",
      "Result Orienter",
      "Excellent Communication"
    ],
    "Job Location": "Tenkasi"
  },

  ProcurementManager: {
    title: "Procurement Manager",
    "Job Description":
      "Repsonsible to analyze and develop end to end solutions for client needs Collaborate with Team members regularlly to develop product / solutions which is sustainable for long term Manage delivery timelines and deliver high quality outputs Engage with cross funcatioinal teams effectively to produce end result in a timely manner Manage across project teams expectations and commitment to clients Demonstrates Project Management Skills Proficient in MS office tools.",
    Qualification: ["Bachelor's/Masters"],
    "Years Of Experience": "Bachelor's - 3 years, Masters - 2 years",
    "Technical Skills": [
      "POS System",
      "Ms Office",
      "Mathematical Knowledge",
      "Credit Debit management"
    ],
    "Other Skills": [
      "Agile",
      "Passionate",
      "Colloborative",
      "Willing to Learn",
      "Innovative"
    ],

    "People Skills": [
      "Knowledge about Product",
      "Local Marketing",
      "Team player",
      "Result Orienter",
      "Excellent Communication"
    ],
    "Job Location": "Tenkasi"
  },
  ProductionManager: {
    title: "Product Manager",
    "Job Description":
      "Repsonsible to analyze and develop end to end solutions for client needs Collaborate with Team members regularlly to develop product / solutions which is sustainable for long term Manage delivery timelines and deliver high quality outputs Engage with cross funcatioinal teams effectively to produce end result in a timely manner Manage across project teams expectations and commitment to clients Demonstrates Project Management Skills Proficient in MS office tools.",
    Qualification: ["Bachelor's/Masters"],
    "Years Of Experience": "Bachelor's - 3 years, Masters - 2 years",
    "Technical Skills": [
      "POS System",
      "Ms Office",
      "Mathematical Knowledge",
      "Credit Debit management"
    ],
    "Other Skills": [
      "Agile",
      "Passionate",
      "Colloborative",
      "Willing to Learn",
      "Innovative"
    ],

    "People Skills": [
      "Knowledge about Product",
      "Local Marketing",
      "Team player",
      "Result Orienter",
      "Excellent Communication"
    ],
    "Job Location": "Tenkasi"
  },
  BusinessManager: {
    title: "Business Manager",
    "Job Description":
      "Repsonsible to analyze and develop end to end solutions for client needs Collaborate with Team members regularlly to develop product / solutions which is sustainable for long term Manage delivery timelines and deliver high quality outputs Engage with cross funcatioinal teams effectively to produce end result in a timely manner Manage across project teams expectations and commitment to clients Demonstrates Project Management Skills Proficient in MS office tools.",
    Qualification: ["Bachelor's/Masters"],
    "Years Of Experience": "Bachelor's - 5 years, Masters - 4 years",
    "Technical Skills": ["Ms Office", "Invoice Maintanance"],
    "Other Skills": [
      "Agile",
      "Passionate",
      "Colloborative",
      "Willing to Learn",
      "Innovative"
    ],

    "People Skills": ["Managing Team", "Situation handling", "Self motivated"],
    "Job Location": "Tenkasi"
  },
  RegionalDirector: {
    title: "Regional Director",
    "Job Description":
      "Repsonsible to analyze and develop end to end solutions for client needs Collaborate with Team members regularlly to develop product / solutions which is sustainable for long term Manage delivery timelines and deliver high quality outputs Engage with cross funcatioinal teams effectively to produce end result in a timely manner Manage across project teams expectations and commitment to clients Demonstrates Project Management Skills Proficient in MS office tools.",
    Qualification: ["Bachelor's/Masters"],
    "Years Of Experience": "Bachelor's - 8 years, Masters - 7 years",
    "Technical Skills": ["Ms Office", "Invoice Maintanance"],
    "Other Skills": [
      "Agile",
      "Passionate",
      "Colloborative",
      "Willing to Learn",
      "Innovative"
    ],

    "People Skills": ["Managing Team", "Situation handling", "Self motivated"],
    "Job Location": "Tenkasi"
  },
  SalesVP: {
    title: "SalesVP",
    "Job Description":
      "Repsonsible to analyze and develop end to end solutions for client needs Collaborate with Team members regularlly to develop product / solutions which is sustainable for long term Manage delivery timelines and deliver high quality outputs Engage with cross funcatioinal teams effectively to produce end result in a timely manner Manage across project teams expectations and commitment to clients Demonstrates Project Management Skills Proficient in MS office tools.",
    Qualification: ["Bachelor's/Masters"],
    "Years Of Experience": "9 years",
    "Technical Skills": ["Ms Office", "Invoice Maintanance"],
    "Other Skills": [
      "Agile",
      "Passionate",
      "Colloborative",
      "Willing to Learn",
      "Innovative"
    ],

    "People Skills": ["Managing Team", "Situation handling", "Self motivated"],
    "Job Location": "Tenkasi"
  },
  Accountant: {
    title: "Accountant",
    "Job Description":
      "Repsonsible to analyze and develop end to end solutions for client needs Collaborate with Team members regularlly to develop product / solutions which is sustainable for long term Manage delivery timelines and deliver high quality outputs Engage with cross funcatioinal teams effectively to produce end result in a timely manner Manage across project teams expectations and commitment to clients Demonstrates Project Management Skills Proficient in MS office tools.",
    Qualification: ["Bachelor's/Masters"],
    "Years Of Experience": "3 years",
    "Technical Skills": ["Tally", "GST"],
    "Other Skills": [
      "Agile",
      "Passionate",
      "Colloborative",
      "Willing to Learn",
      "Innovative"
    ],

    "People Skills": ["Accounting", "GST Filling"],
    "Job Location": "Tenkasi"
  },
  WeldingPlumbingElectrician: {
    title: "Welding, Plumbing, Electrician",
    "Job Description":
      "Repsonsible to analyze and develop end to end solutions for client needs Collaborate with Team members regularlly to develop product / solutions which is sustainable for long term Manage delivery timelines and deliver high quality outputs Engage with cross funcatioinal teams effectively to produce end result in a timely manner Manage across project teams expectations and commitment to clients Demonstrates Project Management Skills Proficient in MS office tools.",
    Qualification: ["IT/Diploma"],
    "Years Of Experience": "Fresher or Experienced",
    "Technical Skills": ["Welding", "Plumbing", "Electrical"],
    "Other Skills": [
      "Agile",
      "Passionate",
      "Colloborative",
      "Willing to Learn",
      "Innovative"
    ],

    "People Skills": ["Managing Team", "Situation handling", "Self motivated"],
    "Job Location": "Tenkasi"
  }
};
