import { apiEndPoint } from "../const";
// import {t} from '../../context/StateContext'
export const post = async (path, data, token) => {
  const res = await fetch(`${apiEndPoint}${path}`, {
    method: "POST",
    mode: "no-cors",
    body: JSON.stringify(data),
  })
    .then(async (res) => {
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in post api ${path} >`, error);
      return [200, error];
    });
  return res;
};

export const patch = async (apiUri, data, token) => {
  const res = await fetch(`${apiEndPoint}${apiUri}`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "text/html",
    },
  })
    .then(async (res) => {
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in patch ${apiUri} >`, error);
      return [500, error];
    });
  return res;
};
export const _delete = async (apiUri, token) => {
  const res = await fetch(`${apiEndPoint}${apiUri}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "text/html",
    },
  })
    .then(async (res) => {
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in delete ${apiUri} >`, error);
      return [500, error];
    });
  return res;
};

export const put = async (apiUri, data, token) => {
  const res = await fetch(`${apiEndPoint}${apiUri}`, {
    method: "PUT",
    mode: "no-cors",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      console.log(res);
      // const json = await res.json()
      // return [res?.status, json];
    })
    .catch((error) => {
      console.log(`error in put ${apiUri} >`, error);
    });
  return res;
};

export const get = async (apiUri, token) => {
  const res = await fetch(`${apiEndPoint}${apiUri}`, {
    method: "GET",
    mode: "cors",
  })
    .then(async (res) => {
      // console.log(t)
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in api ${apiUri} >`, error);
      return [500, error]
    });
  return res;
};

export const uploadImage = async (newFileName, image) => {
  const getFileExtension = (fileName) => {
    const nameList = fileName?.split(".");
    return nameList[nameList?.length - 1];
  };
  const fileName = `${newFileName}.${getFileExtension(image?.name)}`;
  const contentType = image?.type;
  return await fetch(`${apiEndPoint}/upload-image`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({
      fileName: fileName,
      contentType: contentType,
    }),
  })
    .then((res) => res.json())
    .then((response) => {
      const bucketUrl = response.url;
      const headers = new Headers();
      return fetch(bucketUrl, {
        method: "PUT",
        body: image,
        headers: {
          ...headers,
          "Content-Type": contentType,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            console.log(
              `image upload success ${res.status} - ${res.statusText}`
            );
          } else {
            alert("Failed");
            console.log(
              `error while uploading file ${res.status} - ${res.statusText}`
            );
          }
          return [res?.status, res?.statusText]
        })
        .catch((err) => {
          console.log(err);
          console.log(`error on bucket url ${err.status} - ${err.statusText}`);
          return [err?.status, err?.statusText]

        });
    })
    .catch((err) => {console.log(err);
      return [500, err]
    });
};

export const uploadResume = async (file, userData) => {
  const contentType = "application/pdf";
  await fetch(`${apiEndPoint}/upload-resume`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({
      ...userData,
      contentType: contentType,
    }),
  })
    .then((res) => res.json())
    .then((response) => {
      const bucketUrl = response.url;
      const headers = new Headers();
      fetch(bucketUrl, {
        method: "PUT",
        body: file,
        headers: {
          ...headers,
          "Content-Type": contentType,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            console.log("form submitted successfully");
          } else {
            alert("Failed");
            console.log("error while uploading file");
          }
          console.log(`success ${res.status} - ${res.statusText}`);
        })
        .catch((err) => {
          console.log(err);
          console.log(`error on bucket url ${err.status} - ${err.statusText}`);
        });
    })
    .catch((err) => console.log("error while fetching api endpoint", err));
};
