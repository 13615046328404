export function formatTime (dateTime) {
if(dateTime){

    let [_date,_time] = dateTime.split(' ')
    _time = _time.split('.')[0]
    
    // Check correct time format and split into components
    let time = _time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [_time];
    
    if (time.length > 1) { // If time format correct
        time = time.slice (1);  // Remove full string match value
        time[3] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    _time = time.join (''); // return adjusted time or original string
    
    return `${_date} ${_time}`
}
    
}


export async function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "vct_illustration.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    await downloadLink.click();
}