import React, { useContext, useState } from "react";
import { Key, People } from "react-bootstrap-icons";
import "./style.css";
import { AuthContext } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";

export default function Index() {
  const history = useHistory();
  const { signIn, setIsAuthenticated } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const initCred = {
    username: "",
    password: "",
  };
  const [cred, setCred] = useState(initCred);

  const handleCredChange = (prop) => (event) => {
    // console.log(cred)
    setCred({ ...cred, [prop]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (cred.password !== "") {
      await signIn(cred.username, cred.password).then((res) => {
        if (res?.statusCode === 200) {
          history.push("/dashboard");
        } else if (res.statusCode === 400) {
          setError(res?.error?.message || "Something Went Wrong");
        } else if (res.statusCode === 201) {
          history.push("/setNewPassword");
        }
      });
    } else {
      setError("Password cannot be empty");
    }
  };

  return (
    <div className="my_container w-100">
      <div className="d-flex justify-content-center h-100 w-100">
        <div className="card">
          {/* <div className="card-header">
            <h3>Sign In</h3>
            <div className="d-flex justify-content-end social_icon">
              <span>
                
                <i className="fab fa-facebook-square"></i>
              </span>
              <span>
                <i className="fab fa-google-plus-square"></i>
              </span>
              <span>
                <i className="fab fa-twitter-square"></i>
              </span>
            </div>
          </div> */}
          <div className="card-body">
            <div className="login-logo">
              <img
                src="https://d3rnckbcoykp.cloudfront.net/logo.png"
                alt="Muthiahs logo"
              />
            </div>
            <form onSubmit={handleSubmit}>
              {error && (
                <div className=" alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <People />
                    <i className="fas fa-user"></i>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="username"
                  onChange={handleCredChange("username")}
                />
              </div>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <Key />
                    <i className="fas fa-key"></i>
                  </span>
                </div>
                <input
                  type="password"
                  className="form-control"
                  placeholder="password"
                  onChange={handleCredChange("password")}
                />
              </div>
              {/* <div className="row align-items-center remember">
                <input type="checkbox" />
                Remember Me
              </div> */}
              <div className="row">
                <div className="form-group col-12">
                  <input
                    type="submit"
                    value="Login"
                    className="btn float-right login_btn w-100"
                  />
                </div>
              </div>
            </form>
          </div>
          {/* <div className="card-footer">
            <div className="d-flex justify-content-center links">
              Don't have an account?<a href="#">Sign Up</a>
            </div>
            <div className="d-flex justify-content-center">
              <a href="#">Forgot your password?</a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
