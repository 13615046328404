import React from "react";
import { Form } from "react-bootstrap";

interface Props {
  name: string;
  hideErrorText?: boolean;
  formGroupProp?: any;
  errors: any;
  placeholder?: string;
  inputProp?: any;
  reg: any;
  required?: boolean;
  label?: string;
}
export default function Index({
  name,
  hideErrorText = false,
  required,
  label,
  placeholder,
  formGroupProp,
  errors,
  inputProp,
  reg
}: Props) {
  const asterisk = <span className="form-error-text ml-1">*</span>;
  return (
    <Form.Group className="col-xs-12 col-sm-6 col-md-6" {...formGroupProp}>
      <Form.Label>
        {label}
        {required && asterisk}
      </Form.Label>
      <Form.Control
        type="text"
        placeholder={placeholder || label}
        {...reg(name)}
        name={name}
        className={`${errors?.[name] && "form-error-field"}`}
        {...inputProp}
      />
      {!hideErrorText && (
        <Form.Text className="form-error-text">
          {errors?.[name]?.message}
        </Form.Text>
      )}
    </Form.Group>
  );
}
