import { Button } from 'react-bootstrap'
import React from 'react'
import { Link } from 'react-router-dom'
import UnitList from "./UnitList"
import { useEffect } from 'react'
import { useContext } from 'react'
import { StateContext } from '../../context/StateContext'

export default function Index() {
    const {fetchUnitList} = useContext(StateContext)
    useEffect(()=>{
        fetchUnitList()
    },[])
    return (
        <>
            <div className="m-page-title mb-3">Units</div>
            <Link to="/addUnit">
            <Button variant="outline-dark" block>
            + Add Unit
          </Button>
            </Link>
            <hr />
            <UnitList />
        </>
    )
}
