import { Button } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import ProductTypeList from "./ProductLIst";

export default function Index() {
  
  return (
    <div>
      <div className="m-page-title mb-3">Product Type</div>
      <Link to="/addProductType">
        <Button variant="outline-dark" block>
          + Add Product Type
        </Button>
      </Link>
      <hr />
      <ProductTypeList />
    </div>
  );
}
