import React, { useContext } from "react";
import NavigationBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import { AuthContext } from "../context/AuthContext";
import { Redirect } from "react-router-dom";
import LoadingScreenV1 from '../components/LoadingScreen/V1'
import "./style.css";

export default function Index({ children }) {
  const { isAuthenticated, isLoading } = useContext(AuthContext);
  return (
    <div className="muthiahs-main">
      {isAuthenticated ? (
        <>
          <nav>
            <NavigationBar />
          </nav>
          <div className="muthiahs-container">
            <aside className="side-bar">
              <SideBar />
            </aside>
            <main>{!isLoading ? children : <LoadingScreenV1 />}</main>
          </div>
        </>
      ) : (
        <Redirect to="/logIn" />
      )}
    </div>
  );
}
