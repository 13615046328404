import { apiEndPoint } from "../const";

const path = `/admin/supplier`;

export const getSupplierList = async (token) => {
  const res = await fetch(`${apiEndPoint}${path}`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api getSupplierList >", error));
  return res;
};

export const getSupplierByKey = async (supplier_key) => {
  const path = `/admin/supplier/view`;
  const res = await fetch(`${apiEndPoint}${path}?supplier_key=${supplier_key}`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api getSupplierByKey >", error));
  return res;
};

export const addSupplier = async (data) => {
  const finalData = {
    supplier_name: "",
    email: "",
    phone: "",
    supplier_type: null,
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    country: "",
    postalcode: "",
    site: null,
    product_name: "",
    product_type: null,
    price: "",
    quantity: "",
    currency: "",
    advance_amount: "",

    ...data,
  };
  const res = await fetch(`${apiEndPoint}${path}`, {
    method: "POST",
    mode: "no-cors",
    body: JSON.stringify(finalData),
  })
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api addSupplier >", error));
  return res
};

