import { apiEndPoint } from "../const";

export const getCustomerType = async (token) => {
  const path = `/admin/customer/type`;
  const res = await fetch(`${apiEndPoint}${path}`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api getCustomerType >", error));
  return res;
};


// export const getCustomerList = async (token) => {
//   const path = `/ecom/customer`;
//   const res = await fetch(`${apiEndPoint}${path}`, {
//     method: "GET",
//     mode: "cors",
//   })
//     .then((res) => res.json())
//     .then((res) => {
//       return res;
//     })
//     .catch((error) => console.log("error in api getCustomerList >", error));
//   console.log(res)
//   return res;
// };


export const addCustomer = async (data) => {
  const path = `/admin/customer`;
  const finalData = {
    customer_type_id: null,
    first_name: "",
    last_name: "",
    salutation: "",
    mail: "",
    phone: "",
    ...data,
  };
  console.log(finalData);
  const res = await fetch(`${apiEndPoint}${path}`, {
    method: "POST",
    mode: "no-cors",
    body: JSON.stringify(finalData),
  })
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api addCustomer >", error));
  return res;
};

export const getCustomerList = async (token) => {
  const path = `/admin/customer`;
  const res = await fetch(`${apiEndPoint}${path}`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api getCustomerList >", error));
  return res;
};

export const getCustomer = async (customerKey, token) => {
  const path = `/admin/customer`;
  const res = await fetch(`${apiEndPoint}${path}?customerKey=${customerKey}`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
  return res;
};

export const addAddress = async (data) => {
  const path = `/admin/customer/address`;
  const finalData = {
    ...data,
  };
  console.log(finalData);
  const res = await fetch(`${apiEndPoint}${path}`, {
    method: "POST",
    mode: "no-cors",
    body: JSON.stringify(finalData),
  })
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api addAddress >", error));
  return res;
};

export const deleteAddress = async (addressId) => {
  const path = `/admin/customer/address`;
  const query = `?id=${addressId}`
  console.log(query)
  const res = await fetch(`${apiEndPoint}${path}${query}`, {
    method: "DELETE",
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api deleteAddress >", error));
  return res;
};

export const getSalesOrderByCustomer = async (customer_key) => {
  const path = `/admin/sales-orders`;
  const res = await fetch(`${apiEndPoint}${path}?customer_key=${customer_key}`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
  return res;
};
