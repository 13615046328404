import React, { useEffect, useState } from "react";
import { jobPositions } from "../../../data/jobPosition";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import Header from "../../../components/Header-dashboard";
import "./style.css";
import AdminCareerPage from "../../../assets/images/adminCareerPage.jpg";

export default function Index({ position }) {
  const history = useHistory();
  const [job, setJob] = useState({});
  useEffect(() => {
    setJob(jobPositions?.[position] || {});
  }, [position]);
  return (
    <>
      <Header />
      <div style={{ margin: "0rem" }}>
        <div>
          <h1
            style={{
              backgroundImage: `url(${AdminCareerPage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              
              fontSize: "53px",
              // background: "radial-gradient(#147AAB, #1D889D)",
              textAlign: "left",
              color: "white",
              fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
              padding: "180px",
              textShadow: "2px 2px 4px #000000",
              borderBottomLeftRadius: "15%",
              borderBottomRightRadius: "45%",
              opacity: "0.75",
            }}
          >
            {job.title}
            <h1
              className="display-5"
              style={{
                fontSize: "20px",
                textAlign: "match-parent",
                color: "white",
                fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                display: "flex",
              }}
            >
              Years Of Experience: {job["Years Of Experience"]}&nbsp; | &nbsp;
              Qualification:&nbsp;
              <p style={{ display: "inline-block" }}>
                {job.Qualification?.map((_, idx) => (
                  <p key={idx}>{_}</p>
                ))}
              </p>
            </h1>
            <Button
              style={{
                boxShadow:
                  " 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)",
                width: "110px",
              }}
              onClick={() => history.push("/apply")}
            >
              Apply
            </Button>
          </h1>

          <hr></hr>
        </div>
        <div className="d-flex flex-row justify-content-center">
          <div
            style={{
              width: "90vw",
              minHeight: "30vh",
              border: "1px solid rgba(100,100,100,.3)",
              padding: "2rem",
              //boxShadow: "5px 5px 5px grey",
            }}
            class="jobdes"
          >
            <div>
              <h5>Job Description</h5>
              <div style={{ marginLeft: "0rem" }}>
                <p className="lead ">
                  <ul className="">{job["Job Description"]}</ul>
                </p>
              </div>
            </div>

            <div>
              <h5>Qualification</h5>
              <div style={{ marginLeft: "3rem" }}>
                <p className="lead ">
                  <ul className="">
                    {job.Qualification?.map((_, idx) => (
                      <li key={idx}>{_}</li>
                    ))}
                  </ul>
                </p>
              </div>
            </div>

            <div>
              <h5>Years Of Experience</h5>
              <div style={{ marginLeft: "3rem" }}>
                <p className="lead ">{job["Years Of Experience"]}</p>
              </div>
            </div>

            <div>
              <h5>Technical Skills</h5>
              <div style={{ marginLeft: "3rem" }}>
                <p className="lead ">
                  <ul className="">
                    {job["Technical Skills"]?.map((_, idx) => (
                      <li key={idx}>{_}</li>
                    ))}
                  </ul>
                </p>
              </div>
            </div>

            <div>
              <h5>Other Skills</h5>
              <div style={{ marginLeft: "3rem" }}>
                <p className="lead ">
                  <ul className="">
                    {job["Other Skills"]?.map((_, idx) => (
                      <li key={idx}>{_}</li>
                    ))}
                  </ul>
                </p>
              </div>
            </div>
            <div>
              <h5>People Skills</h5>
              <div style={{ marginLeft: "3rem" }}>
                <p className="lead ">
                  <ul className="">
                    {job["People Skills"]?.map((_, idx) => (
                      <li key={idx}>{_}</li>
                    ))}
                  </ul>
                </p>
              </div>
            </div>

            <div>
              <h5>Job Location</h5>
              <div style={{ marginLeft: "3rem" }}>
                <p className="lead ">{job["Job Location"]}</p>
              </div>
            </div>

            {/* <div>
              <h5>Functions</h5>
              <div style={{ marginLeft: "3rem" }}>
                <p className="lead ">
                  {job["Functions:"]}
                </p>
              </div>
            </div> */}

            {/* <center> */}
            <Button
              style={{
                boxShadow:
                  " 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)",
                width: "110px",
                marginTop: "60px",
              }}
              onClick={() => history.push("/apply")}
            >
              Apply
            </Button>
            {/* </center> */}
            <br />
            <br />
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
}
