import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthContext } from "../../../context/AuthContext";
import { AreaContext } from "../../../context/AreaContext";
import NumberField from "../../../components/InputField/Number";
import TextField from "../../../components/InputField/Text";
import TextArea from "../../../components/InputField/TextArea";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { v4 } from "uuid";
import { get, post, uploadImage } from "../../../utils/apiFunctions";

export default function Index(props) {
  const [image, setImage] = useState(null);
  const [productTypeList, setProductTypeList] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [selectedTerritory, setSelectedTerritory] = useState(null);
  const [inventoryList, setInventoryList] = useState([]);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [image_key, setImageKey] = useState(v4());
  const { userAttributes } = useContext(AuthContext);
  const { territoryList, fetchTerritoryList } = useContext(AreaContext);
  const [product, setProduct] = useState(null);
  const schema = yup.object().shape({
    display_name: yup
      .string()
      .required("Field required")
      .min(3, "minimum 3 char required")
      .max(50, "can not exceed 50 char"),
    image_url: yup.string(),
    image_list: yup.string(),
    currency: yup.string().required("Field required"),
    product_price: yup
      .number()
      .min(1)
      .max(9999999999, "Enter value below 1 Billion"),
    desc: yup.string().required("Field required"),
    offer_price: yup
      .number()
      .required("enter 0 if there is no offer")
      .typeError("number only"),
    offer_start_date: yup.string(),
    offer_end_date: yup.string(),
    is_offer_active: yup.number(),
    territory_id: yup.number().required("Field required"),
    employee_id: yup.string().required().default(userAttributes?.sub),
    product_type_id: yup.string().required("Field required"),
    min_order_qty: yup
      .number()
      .required("Field required")
      .min(1, "Min Value is 1")
      .default(0),
    max_order_qty: yup.number().required("Field required").default(0),
    inventory_id:
      selectedProductType?.inventory_based === 1
        ? yup.number().required("field required")
        : yup.string().default("")
  });

  const [localImageUrl, setLocalImageUrl] = useState(null);
  useEffect(() => {
    setImageKey(v4());
  }, []);
  const getFileExtension = (fileName) => {
    const nameList = fileName?.split(".");
    return nameList[nameList?.length - 1];
  };
  const fetchProductTypeList = async () => {
    await get(`/admin/product-type`).then(([status, res]) => {
      if (status === 200) {
        setProductTypeList(res);
      } else {
        console.log(status);
      }
    });
  };

  const fetchInventoryList = async (query) => {
    await get(`/admin/inventory?is_approved=1&${query}`).then(
      ([status, res]) => {
        if (status === 200) {
          setInventoryList(res);
        } else {
          console.log(status);
        }
      }
    );
  };

  const fetchProduct = async (query) => {
    await get(`/admin/inventory?is_approved=1&${query}`).then(
      ([status, res]) => {
        if (status === 200) {
          setInventoryList(res);
        } else {
          console.log(status);
        }
      }
    );
  };

  const handleImageChange = (files) => {
    const file = files[0];
    const fileExt = getFileExtension(file?.name);
    setLocalImageUrl(URL.createObjectURL(file));
    setImage(file);
    console.log(file);
    setValue("image_url", image_key);
    setValue(
      "image_url",
      `https://d3rnckbcoykp.cloudfront.net/${image_key}.${fileExt}`
    );
    setIsBtnDisabled(false);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
    getValues
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur"
  });
  const clearForm = () => {
    setLocalImageUrl("");
    reset();
  };

  useEffect(() => {
    fetchTerritoryList();
    fetchProductTypeList();
  }, []);
  useEffect(() => {
    if (selectedProductType?.inventory_based === 1 && selectedTerritory) {
      fetchInventoryList(
        `product_type_id=${selectedProductType?.product_type_id}`
      );
    } else {
      setValue("inventory_id", "");
    }
  }, [selectedProductType, selectedTerritory]);
  useEffect(() => {
    setValue("employee_id", userAttributes?.sub);
  }, [props]);

  useEffect(() => {
    setValue("display_name", props?.display_name);
    setValue("image_url", props?.image_url);
    setValue("image_list", props?.image_list);
    setValue("currency", props?.currency);
    setValue("product_price", props?.product_price);
    setValue("desc", props?.desc);
    setValue("offer_price", props?.offer_price);
    setValue("offer_start_date", props?.offer_start_date);
    setValue("offer_end_date", props?.offer_end_date);
    setValue("is_offer_active", props?.is_offer_active);
    setValue("territory_id", props?.territory_id);
    setValue("employee_id", props?.employee_id);
    setValue("product_type_id", props?.product_type_id);
    setValue("min_order_qty", props?.min_order_qty);
    setValue("max_order_qty", props?.max_order_qty);
    setValue("inventory_id", props?.inventory_id);
  }, [product]);

  const hookFormProp = { errors: errors, reg: register };

  const onSubmit = async (formData, event) => {
    if (image) {
      // await uploadImageToS3(
      //   event,
      //   image_key,
      //   image,
      //   getFileExtension(image?.name)
      // );
      await uploadImage(image_key, image);
    }
    await post("/admin/ecommerce", formData).then((_) => {
      clearForm();
    });
  };
  return (
    <>
      <div className="m-page-title mb-3">
        {props?.ecomSiteId ? "Edit Product" : "Add To Ecom"}
      </div>
      <Link to="/products">
        <Button variant="outline-danger" block>
          Products in ecom
        </Button>
      </Link>
      <hr />
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* <Modal.Body> */}
        {/* territory */}

        {/* display name, qty */}
        <Form.Row>
          <TextField
            name="display_name"
            label="Display Name"
            {...hookFormProp}
          />

          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Product Type</Form.Label>
            <Form.Control
              as="select"
              type="number"
              placeholder="Site"
              name="product_type_id"
              {...register("product_type_id")}
              className={`${errors?.product_type_id && "form-error-field"}`}
              onChange={(_) => {
                setSelectedProductType(
                  productTypeList?.filter(
                    (productType) =>
                      productType?.product_type_id === _.target.value
                  )[0]
                );
              }}
            >
              <option value="" key="0">
                Select Product type
              </option>
              {productTypeList?.map((product, index) => (
                <option value={product?.product_type_id} key={index}>
                  {product?.name} ({product?.product_type_id})
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Add to</Form.Label>
            <Form.Control
              as="select"
              type="number"
              placeholder="Site"
              name="territory_id"
              {...register("territory_id")}
              className={`${errors?.territory_id && "form-error-field"}`}
              onChange={(_) => {
                setSelectedTerritory(
                  territoryList?.filter(
                    (territory) => territory?.territory_id == _.target.value
                  )[0]
                );
              }}
            >
              <option value="" key="0">
                Select Territory
              </option>
              {territoryList?.map((territory, index) => (
                <option value={territory?.territory_id} key={index}>
                  {territory?.territory_name} ({territory?.territory_code})
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {selectedProductType?.inventory_based === 1 && selectedTerritory ? (
            <Form.Group className="col-xs-12 col-sm-6 col-md-6">
              <Form.Label>Inventory</Form.Label>
              <Form.Control
                as="select"
                type="number"
                placeholder="Inventory"
                name="inventory_id"
                {...register("inventory_id")}
                className={`${errors?.inventory_id && "form-error-field"}`}
              >
                <option value="" key="0">
                  Select Inventory
                </option>
                {inventoryList?.map((inventory, index) => (
                  <option value={inventory?.inventory_id} key={index}>
                    {inventory?.lot_key}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          ) : (
            ""
          )}
        </Form.Row>

        <Form.Row>
          <NumberField
            name="min_order_qty"
            label="Minimum Order Qty"
            {...hookFormProp}
          />
          <NumberField
            name="max_order_qty"
            label="Maximum Order Qty"
            {...hookFormProp}
            inputProp={{
              onChange: (_) => {
                if (_.target.value < getValues("min_order_qty")) {
                  console.log(errors);
                  console.log(
                    "Max order should be equal or greater than min order qty"
                  );
                  setError(
                    "max_order_qty",
                    "Max order should be equal or greater than min order qty"
                  );
                }
              }
            }}
          />
        </Form.Row>

        {/*currency, product price */}
        <Form.Row>
          {/* currency */}
          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Currency</Form.Label>
            <Form.Control
              as="select"
              type="number"
              placeholder="Currency"
              name="currency"
              {...register("currency")}
              className={`${errors?.currency && "form-error-field"}`}
            >
              <option value="₹" key="0">
                INR ₹
              </option>
            </Form.Control>
          </Form.Group>

          {/* product price */}
          <NumberField
            name="product_price"
            label="Product Price"
            inputProp={{ step: "0.01" }}
            {...hookFormProp}
          />
        </Form.Row>

        {/* is_offer_active, offer price */}
        <Form.Row>
          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Activate Offer</Form.Label>
            <Form.Control
              as="select"
              type="number"
              placeholder="Activate Offer"
              name="is_offer_active"
              {...register("is_offer_active")}
              className={`${errors?.is_offer_active && "form-error-field"}`}
            >
              <option value={0} key="0">
                No
              </option>
              <option value={1} key="1">
                Yes
              </option>
            </Form.Control>
          </Form.Group>
          <NumberField
            name="offer_price"
            label="Offer Price"
            inputProp={{ step: "0.01" }}
            {...hookFormProp}
          />
        </Form.Row>

        {/* offer start and end date */}
        <Form.Row>
          <TextField
            inputProp={{ type: "date" }}
            name="offer_start_date"
            label="Offer Start Date"
            {...hookFormProp}
          />
          <TextField
            inputProp={{ type: "date" }}
            name="offer_end_date"
            label="Offer End Date"
            {...hookFormProp}
          />
        </Form.Row>

        {/* description */}
        <Form.Row>
          <TextArea name="desc" label="Description" {...hookFormProp} />
        </Form.Row>

        {/* image */}
        {image ? (
          <div className="d-flex justify-content-center">
            <img
              style={{ height: "150px", overflow: "hidden" }}
              src={localImageUrl}
              alt=""
            />
          </div>
        ) : (
          ""
        )}

        <Form.Row className="mb-2">
          <Form.Label>Select Image</Form.Label>
          <Form.File
            name="productImage"
            label="Product image"
            onChange={(_) => handleImageChange(_.target.files)}
            custom
          />
        </Form.Row>

        <Button
          disabled={isBtnDisabled}
          className="float-right"
          type="submit"
          variant="success"
        >
          Add
        </Button>
      </Form>
    </>
  );
}
