import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { jobPositions } from "../../data/jobPosition";
import "./Card.css";

// const useStyles = makeStyles({
//     root: {
//       maxWidth: 345,
//       position:'relative',
//       marginLeft:20,
//     },
//     media: {
//       height: 140,
//     },
//   });

export default function Index() {
  const history = useHistory();

  return (
    <>
      {Object.entries(jobPositions)?.map((entry) => (
        <Card
          className="card1"
          onClick={() => {
            history.push(`/career/${entry[0]}`);
          }}
        >
          <CardContent className="content">
            <Typography gutterBottom variant="h5" component="h2">
              {entry[1].title}
            </Typography>
          </CardContent>

          <CardActions>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                history.push(`/career/${entry[0]}`);
              }}
            >
              Open Position
            </Button>
          </CardActions>
        </Card>
      ))}
      {console.log(jobPositions)}
    </>
  );
}
