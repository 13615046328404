const apiURL =
  "https://vux58mju8e.execute-api.ap-southeast-1.amazonaws.com/dev";

export const getHomeWidgetData = async (idToken) => {
  return await fetch(`${apiURL}/home/widgets`, {
    mode: "cors",
    method: "GET",
    headers:{

        Authorization:idToken
    }
})
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
};
