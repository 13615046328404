import React, { useContext } from "react";
import { ArrowLeft, MenuApp, PersonCircle } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { StateContext } from "../../context/StateContext";
import "./style.css";

export default function Index() {
  const { isSideBarCollapsed, setIsSideBarCollapsed } =
    useContext(StateContext);
  return (
    <div className="nav-bar-wrapper ">
      <div
        onClick={() => setIsSideBarCollapsed(!isSideBarCollapsed)}
        className=" nav-bar-menu-icon"
      >
        {!isSideBarCollapsed ? <ArrowLeft size={30} color='#adadad' /> : <MenuApp size={30} style={{
          color:'#adadad'
        }} />}
      </div>
      <div className="nav-bar-brand ml-5 ">       
          <img
            src="https://d3rnckbcoykp.cloudfront.net/logo.png"
            alt="Muthiahs logo"
          />
        </div>
      <div className="nav-bar-profile-icon">
        <Link to="/myProfile">
          <PersonCircle size={30} />
        </Link>
      </div>
    </div>
  );
}
