import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
  SidebarFooter
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {
  Box,
  House,
  Calendar2Check,
  BoxSeam,
  People,
  Map,
  BoxArrowLeft,
  Tools
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { StateContext } from "../../context/StateContext";
import "./style.css";
import { AuthContext } from "../../context/AuthContext";

export default function SideBar() {
  const { isSideBarCollapsed } = useContext(StateContext);
  const { signOut } = useContext(AuthContext);

  return (
    <>
      <ProSidebar
        color="#fff"
        collapsed={isSideBarCollapsed}
        width="200px"
        collapsedWidth="70px"
      >
        {/* <SidebarHeader>
            Menu
        </SidebarHeader> */}

        <SidebarContent>
          <Menu iconShape="square" popperArrow={true}>
            <MenuItem icon={<House />}>
              Dashboard
              <Link to="/dashboard" />
            </MenuItem>
            <SubMenu title="Catalogue" icon={<Box />}>
              <MenuItem>
                Category
                <Link to="/category" />
              </MenuItem>
              <MenuItem>
                Unit
                <Link to="/unit" />
              </MenuItem>
              <MenuItem>
                Product Type
                <Link to="/productType" />
              </MenuItem>
            </SubMenu>

            <SubMenu title="Inventory" icon={<Box />}>
              <MenuItem>
                Inventory
                <Link to="/inventory" />
              </MenuItem>
              {/* <MenuItem>
                Products (old)
                <Link to="/o/products" />
              </MenuItem> */}
              <MenuItem>
                Products ecom
                <Link to="/products" />
              </MenuItem>
              <MenuItem>
                Production
                <Link to="/production" />
              </MenuItem>
              <MenuItem>
                Inventory History
                <Link to="/inventory/history" />
              </MenuItem>
            </SubMenu>

            <SubMenu title="Orders" icon={<BoxSeam />}>
              <MenuItem>
                New Orders
                <Link to="/salesOrder/new" />
              </MenuItem>

              <MenuItem>
                Approved Order
                <Link to="/salesOrder/approved" />
              </MenuItem>
              <MenuItem>
                Dispatched Order
                <Link to="/salesOrder/dispatched" />
              </MenuItem>
              <MenuItem>
                Completed Order
                <Link to="/salesOrder/delivered" />
              </MenuItem>

              <MenuItem>
                Cancelled Order
                <Link to="/salesOrder/cancelled" />
              </MenuItem>
            </SubMenu>

            {/* <SubMenu title="Employee Management" icon={<BoxSeam />}>
              <MenuItem>
                Employee
                <Link to="/employee" />
              </MenuItem>
              <MenuItem>
                Role
                <Link to="/employee/role" />
              </MenuItem>
            </SubMenu> */}
            {/* 
            <SubMenu title="Customer Management" icon={<BoxSeam />}>
              <MenuItem>
                Customer
                <Link to="/customer" />
              </MenuItem>
            </SubMenu> */}

            <MenuItem icon={<People />}>
              Applicants
              <Link to="/applicants" />
            </MenuItem>

            {/* <SubMenu title="Area" icon={<Map />}>
              <MenuItem>
                Site
                <Link to="/site" />
              </MenuItem>
              <MenuItem>
                Region
                <Link to="/region" />
              </MenuItem>
              <MenuItem>
                Territory
                <Link to="/territory" />
              </MenuItem>
              <MenuItem>
                District
                <Link to="/district" />
              </MenuItem>
              <MenuItem>
                Pincode
                <Link to="/pincode" />
              </MenuItem>
            </SubMenu> */}

            {/* <SubMenu title="Procurement" icon={<Map />}>
              <MenuItem>
                Supplier
                <Link to="/supplier" />
              </MenuItem>
              <MenuItem>
                Purchase
                <Link to="/purchase" />
              </MenuItem>
            </SubMenu> */}

            <SubMenu title="E-Com Settings" icon={<Map />}>
              <MenuItem>
                Carousal
                <Link to="/websiteSetting/carousal" />
              </MenuItem>
            </SubMenu>

            {/* <MenuItem icon={<Calendar2Check />}>Calender</MenuItem> */}
            {/* <SubMenu title="Tools" icon={<Tools />}>
              <MenuItem icon={<Calendar2Check />}>
                Coconut Sampling
                <Link to="/tools/coconutSampling" />
              </MenuItem>
            </SubMenu> */}
          </Menu>
        </SidebarContent>

        <SidebarFooter>
          <Menu
            iconShape="square"
            popperArrow={true}
            style={{ height: "30px" }}
          >
            <MenuItem icon={<BoxArrowLeft />}>
              <span onClick={() => signOut()}>Sign out</span>
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
    </>
  );
}
