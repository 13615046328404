import React, { useContext } from "react";
import { useEffect } from "react";
import ApplicantList from "./ApplicantList";

export default function Index() {
  
  return (
    <>
      <div className="m-page-title mb-3">Applicants</div>
      <ApplicantList />
    </>
  );
}
