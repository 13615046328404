import React, { useState } from "react";
import { useContext } from "react";
import { Button, Modal, Table, Row, Col, Container } from "react-bootstrap";
import { Download, Eye, ThreeDotsVertical } from "react-bootstrap-icons";
import { StateContext } from "../../../context/StateContext";
import LoadingScreen from "../../../components/LoadingScreen/V1";
import { useEffect } from "react";
import { get, patch } from "../../../utils/apiFunctions";
import "./style.css";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

export default function Index() {
  const history = useHistory();
  const { applicantList, isLoading, fetchApplicants } =
    useContext(StateContext);
  const [applicant, setApplicant] = useState({});
  const [show, setShow] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const handleClose = () => {
    setShow(false);
    setApplicant({});
  };
  useEffect(() => {
    fetchApplicants();
  }, []);

  const schema = yup.object().shape({
    status: yup.string().required("Field Required")
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur"
  });

  // useEffect(() => {
  //   if (applicantKey) {
  //     const temp = applicantList?.filter(
  //       (applicants) => applicants?._key == applicantKey
  //     )[0];
  //     setValue("status", temp?.status || "");
  //   }
  // }, [applicantKey]);
  const hookFormProp = { errors: errors, reg: register };

  const handleShow = async (applicantKey) => {
    await get(`/admin/applicants?key=${applicantKey}`).then(([status, res]) => {
      setApplicant(res);
      setShow(true);
    });
  };

  const onSubmit = async (formData, e) => {
    await patch(`/admin/applicants?key=${applicant?.key}`, formData).then(
      (_) => {
        console.log("applicant update", applicant?.key);
        handleClose();
      }
    );
    console.log(formData);
  };

  const ApplicantModal = (data) => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        size="lg"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Applicant Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table id="view">
              <tr className="tablerow">
                <th className="row-header">
                  <strong>Name</strong>
                </th>
                <td className="data">
                  {applicant?.firstName} {applicant?.lastName}
                </td>
              </tr>
              <tr className="tablerow">
                <th className="row-header">
                  <strong>Email</strong>
                </th>
                <td className="data">{applicant?.email}</td>
              </tr>
              <tr className="tablerow">
                <th className="row-header">
                  <strong>Contact Number</strong>
                </th>
                <td className="data">{applicant?.phoneNumber}</td>
              </tr>
              <tr className="tablerow">
                <th className="row-header">
                  <strong>Stream</strong>
                </th>
                <td className="data">{applicant?.stream}</td>
              </tr>
              <tr className="tablerow">
                <th className="row-header">
                  <strong>Degree</strong>
                </th>
                <td className="data">{applicant?.degree}</td>
              </tr>
              <tr className="tablerow">
                <th className="row-header">
                  <strong>College</strong>
                </th>
                <td className="data">{applicant?.collegeName}</td>
              </tr>
              <tr className="tablerow">
                <th className="row-header">
                  <strong>Experience</strong>
                </th>
                <td className="data">{applicant?.experience}</td>
              </tr>
              <tr className="tablerow">
                <th className="row-header">
                  <strong>Looking For</strong>
                </th>
                <td className="data">{applicant?.lookingFor}</td>
              </tr>
              <tr className="tablerow">
                <th className="row-header">
                  <strong>Address</strong>
                </th>
                <td>
                  <td className="data">
                    <span>{applicant?.addressLine1}</span>
                    <br />

                    <span>{applicant?.addressLine2}</span>
                  </td>
                </td>
              </tr>
              <tr className="tablerow">
                <th className="row-header">
                  <strong>City</strong>
                </th>
                <td className="data">
                  {applicant?.city}, {applicant?.state}
                </td>
              </tr>
              <tr className="tablerow">
                <th className="row-header">
                  <strong>Country</strong>
                </th>
                <td className="data">
                  {applicant?.country}, {applicant?.zipCode}
                </td>
              </tr>
              {applicant?.pdf && (
                <tr className="tablerow">
                  <th className="row-header">
                    <strong>Resume</strong>
                  </th>
                  <td className="data">
                    <div
                      // href={applicant?.pdf}
                      onClick={() => window.open(applicant?.pdf, "_blank")}
                      // target="_blank"
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      Download
                    </div>
                  </td>
                </tr>
              )}
              <tr>
                <td>
                  <Form.Row>
                    <Form.Group className="col-xs-12 col-sm-6 col-md-6">
                      <Form.Label>Status</Form.Label>

                      <Form.Control
                        as="select"
                        type="text"
                        placeholder="Status"
                        name="status"
                        {...register("status")}
                        className={`${errors?.status && "form-error-field"}`}
                      >
                        <option value="" key="0">
                          Select Status
                        </option>
                        <option value="interviewed">Interviewed</option>
                        <option value="selected">Selected</option>
                        <option value="cancelled">Cancelled</option>
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                </td>
              </tr>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Close</Button>
            <Button type="submit" variant="success">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  return (
    <>
      {applicantList?.length > 0 ? (
        <>
          <Table striped hover responsive>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {applicantList?.map((applicant, idx) => {
                return (
                  <tr key={applicant?.key}>
                    <td>{idx + 1}</td>
                    <td>{applicant?.firstName}</td>
                    <td>{applicant?.lastName}</td>
                    <td>{applicant?.email}</td>
                    <td>{applicant?.status}</td>
                    <td>
                      {/* <a href={applicant?.pdf}>
                        <Download size={24} />
                      </a> */}

                      <Dropdown>
                        <Dropdown.Toggle variant="dark" id="dropdown-basic">
                          <ThreeDotsVertical />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              // getAllApplicants(applicant?.key);
                              handleShow(applicant?.key);
                            }}
                          >
                            <div className="text-dark">View</div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <ApplicantModal />
        </>
      ) : isLoading ? (
        <LoadingScreen />
      ) : (
        <div>no Applicants</div>
      )}
    </>
  );
}
