import { v4 as uuid } from "uuid";
import { apiEndPoint } from "../const";

export const add_product = async (productObj, imgExt) => {
  const path = "/products";

  const uniqueKey = uuid();
  const product = {
    productKey: uniqueKey,
    productName: "",
    categoryID: "",
    currency: "",
    productPrice: 0,
    offerPrice: 0,
    inventoryCount: 1,
    productDesc: "",
    productImgName: `${uniqueKey}.${imgExt}`,
  };
  await fetch(`${apiEndPoint}${path}?function=create_product`, {
    method: "POST",
    mode: "no-cors",
    body: JSON.stringify({
      data: { ...product, ...productObj },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
    })
    .catch((err) => console.log("error - ", err));

  return uniqueKey;
};

export const getProducts = async (category = null) => {
  const path = "/products";

  if (!category) {
    const products = await fetch(
      `${apiEndPoint}${path}?function=get_all_product`,
      {
        method: "GET",
        mode: "cors",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        const data = JSON.parse(res)?.products;
        // data.map(_=>console.log(_))
        // console.log(data)
        return data;
      })
      .catch((err) => console.log(err));
    return products;
  }
};

export const updateProducts = async (key, productObj, imgExt) => {
  const path = "/products";

  const product = {
    productKey: key,
    productName: "",
    categoryID: "",
    currency: "",
    productPrice: 0,
    offerPrice: 0,
    inventoryCount: 1,
    productDesc: "",
    productImgUrl: `https://d3rnckbcoykp.cloudfront.net/${key}.${imgExt}`,
  };
  await fetch(`${apiEndPoint}${path}?function=update_product`, {
    method: "POST",
    mode: "no-cors",
    body: JSON.stringify({
      data: { ...product, ...productObj, productKey: key },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
    })
    .catch((err) => console.log("error - ", err));
};

export const getOrders = async (status, limit = null) => {
  if (!limit) {
    const products = await fetch(
      `${apiEndPoint}/muthiahs-rds-mysql-purchaseOrders?function=get_all_Order&status=${status}`,
      {
        method: "GET",
        mode: "cors",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        const data = JSON.parse(res);
        return data;
      })
      .catch((err) => console.log(err));
    return products;
  }
};

// -----------------
export const getProductTypeList = async () => {
  const path = "/admin/product-type";
  const res = await fetch(`${apiEndPoint}${path}`, {
    method: "GET",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api getProductTypeList >", error));
  console.log(res);
  return res;
};

export const addProductType = async (data) => {
  const finalData = {
    category_id: "",
    name: "",
    unit_id: "null",
    image_url: "",
    attributes: [],
    ...data,
  };
  const path = "/admin/product-type";
  const res = await fetch(`${apiEndPoint}${path}`, {
    method: "POST",
    mode: "no-cors",
    body: JSON.stringify(finalData),
  })
    .then((res) => {
      return res;
    })
    .catch((error) => console.log("error in api addProductType >", error));
  console.log(res);
  return res;
};
