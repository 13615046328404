import React from "react";
import { Button, Table } from "react-bootstrap";
import { Form, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "../../../components/InputField/Text";
import NumberField from "../../../components/InputField/Number";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useState } from "react";
import { StateContext } from "../../../context/StateContext";
import { useEffect } from "react";
import { get, post } from "../../../utils/apiFunctions";
// import { AuthContext } from "AuthContext";

export default function Index() {
  const history = useHistory();
  const { userAttributes } = useContext(AuthContext);
  const { fetchProductTypeList, productTypeList } = useContext(StateContext);
  const [tempQty, setTempQty] = useState("");
  const [showInventoryListModal, setShowInventoryListModal] = useState(false);
  const [showInventoryItemModal, setShowInventoryItemModal] = useState(false);
  const [selectedInventoryItem, setSelectedInventoryItem] = useState({});
  const [productList, setProductList] = useState([]);
  const schema = yup.object().shape({
    product_type_id: yup.string().required(),
    production_qty: yup.number().typeError("Qty must be a number"),
    added_by: yup.string(),
    production_cost_per_unit: yup
      .number()
      .required("field required")
      .typeError("Qty must be a number"),
    expiry_date: yup.string(),
    for_sales: yup.number(),
    site_id: yup.number().required("field required")
  });
  const [siteList, setSiteList] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);
  // const [selectedSite, setSelectedSite] = useState(null);

  async function fetchInventoryById(siteId) {
    if (siteId) {
      await get(
        `/admin/inventory?site_id=${siteId}&product_type_id=${getValues(
          "product_type_id"
        )}`
      ).then(([status, res]) => {
        console.log(status);
        setInventoryList(res);
      });
    }
  }

  async function fetchSiteList() {
    await get(`/admin/site`).then(([status, res]) => {
      console.log(status);
      setProductList([]);
      setSiteList(res);
    });
  }

  const removeFromProduct = (inventoryId) => {
    const temp = productList?.filter((_) => _?.inventory_id !== inventoryId);
    setProductList(temp);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur"
  });
  const onSubmit = async (formData, event) => {
    console.log({ ...formData, products: [...productList] });
    await post(`/admin/inventory/production`, {
      ...formData,
      products: [...productList]
    }).then((_) => {
      history.push("/production");
    });
  };

  useEffect(() => {
    setValue("added_by", userAttributes?.sub);
    fetchProductTypeList();
    fetchSiteList();
  }, []);
  // useEffect(()=>{

  //   // setInventoryList([])
  // },[productList])
  const hookFormProp = { errors, reg: register };
  return (
    <>
      <div className="m-page-title mb-3">Create New Product</div>
      <Link to="/production">
        <Button variant="outline-danger" block>
          Production History
        </Button>
      </Link>
      <hr />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Product Type</Form.Label>
            <Form.Control
              as="select"
              placeholder="Product Type"
              name="product_type_id"
              {...register("product_type_id")}
              className={`${errors?.product_type_id && "form-error-field"}`}
            >
              <option value="" key="0">
                Select Product Type
              </option>
              {productTypeList.map((productType, index) => (
                <option value={productType?.product_type_id} key={index}>
                  {productType?.name} ({productType?.product_type_id})
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <NumberField name="production_qty" label="Qty" {...hookFormProp} />
        </Form.Row>
        <Form.Row>
          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Expiry date</Form.Label>

            <Form.Control
              type="date"
              placeholder="Expiry Date"
              name="expiry_date"
              {...register("expiry_date")}
              className={`${errors?.expiry_date && "form-error-field"}`}
            />
          </Form.Group>
          <NumberField
            inputProp={{ step: "0.01" }}
            name="production_cost_per_unit"
            label="Process or Production cost / unit"
            {...hookFormProp}
          />
        </Form.Row>
        <Form.Row>
          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>For Sales</Form.Label>

            <Form.Control
              as="select"
              type="number"
              placeholder="For Sales"
              name="for_sales"
              {...register("for_sales")}
              className={`${errors?.for_sales && "form-error-field"}`}
            >
              <option value={0} key="0">
                No
              </option>
              <option value={1} key="1">
                Yes
              </option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="col-xs-12 col-sm-6 col-md-6">
            <Form.Label>Site</Form.Label>
            <Form.Control
              as="select"
              placeholder="Site"
              name="site_id"
              {...register("site_id")}
              onChange={(_) => {
                console.log(_.target.value);
                setValue("site_id", _.target.value);
                fetchInventoryById(_.target.value);
              }}
            >
              <option value="" key="0">
                Select Site
              </option>
              {siteList.map((site, index) => (
                <option value={site?.site_id} key={index}>
                  {site?.site_name} ({site?.site_code})
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <hr />
        <Button
          variant="primary"
          className="mb-2"
          onClick={() => setShowInventoryListModal(true)}
        >
          + Add Product
        </Button>
        {productList?.length > 0 && (
          <Table striped hover responsive>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Lot key</th>
                <th> Name</th>
                <th>Total Qty</th>
                <th>Qty Used</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {productList?.map((product, idx) => {
                return (
                  <tr key={product?.inventory_id}>
                    <td>{idx + 1}</td>
                    <td>{product?.lot_key}</td>
                    <td>{product?.product_type_name}</td>
                    <td>{product?.qty}</td>
                    <td>{product?.used_qty}</td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => {
                          removeFromProduct(product?.inventory_id);
                        }}
                      >
                        -
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
        <hr />
        {productList?.length > 0 && (
          <Button type="submit" variant="success" className="float-right">
            Save
          </Button>
        )}
      </Form>

      {/* inventoryList modal */}
      <Modal
        show={showInventoryListModal}
        onHide={() => setShowInventoryListModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Inventory List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {inventoryList?.length > 0 && (
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Lot key</th>
                  <th> Name</th>
                  <th>Quantity</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {inventoryList
                  .filter(
                    ({ inventory_id: id1 }) =>
                      !productList.some(({ inventory_id: id2 }) => id2 === id1)
                  )
                  ?.map((product, idx) => {
                    return (
                      <tr key={product?.inventory_id}>
                        <td>{idx + 1}</td>
                        <td>{product?.lot_key}</td>
                        <td>{product?.product_type_name}</td>
                        <td>{product?.qty}</td>
                        <td>
                          <Button
                            onClick={() => {
                              setSelectedInventoryItem(product);
                              setShowInventoryItemModal(true);
                              setTempQty(
                                productList?.filter(
                                  (_) =>
                                    _.inventory_id === product?.inventory_id
                                )
                              );
                            }}
                            variant="primary"
                          >
                            +
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          )}
        </Modal.Body>
      </Modal>

      {/* inventory item */}
      <Modal
        show={showInventoryItemModal}
        onHide={() => {
          setShowInventoryItemModal(false);
          setTempQty("");
        }}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedInventoryItem?.product_type_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped hover responsive>
            <thead>
              <tr>
                <th>Lot key</th>
                <th>Quantity </th>
              </tr>
            </thead>

            <tbody>
              <tr key={selectedInventoryItem?.inventory_id}>
                <td>{selectedInventoryItem?.lot_key}</td>
                <td>{selectedInventoryItem?.qty}</td>
              </tr>
            </tbody>
          </Table>
          <Form.Row>
            <Form.Control
              type="number"
              onChange={(_) => {
                console.log(_);
                setTempQty(_.target.value);
              }}
              value={tempQty}
            />
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              !tempQty || tempQty > selectedInventoryItem?.qty || tempQty == 0
            }
            variant="success"
            onClick={() => {
              setShowInventoryItemModal(false);
              setShowInventoryListModal(false);
              setProductList([
                ...productList,
                {
                  inventory_id: selectedInventoryItem?.inventory_id,
                  product_type_name: selectedInventoryItem?.product_type_name,
                  used_qty: parseInt(tempQty),
                  lot_key: selectedInventoryItem?.lot_key,
                  qty: selectedInventoryItem?.qty
                }
              ]);

              setTempQty("");
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
