import React, { createContext, useContext, useState } from "react";
import {
  getOrders,
  getProducts,
  getProductTypeList,
} from "../utils/apiFunctions/products";
import {
  getEmployeeRoles,
  getEmployeesList,
} from "../utils/apiFunctions/employeemanagement";

import { AuthContext } from "./AuthContext";
import { getUnitList } from "../utils/apiFunctions/unit";
import { getCategoryList } from "../utils/apiFunctions/category";
import { getSupplierList } from "../utils/apiFunctions/supplier";
import { getSupplierByKey } from "../utils/apiFunctions/supplier";
import { getPurchaseList } from "../utils/apiFunctions/purchase";
import { getPurchaseByKey } from "../utils/apiFunctions/purchase";
import { getCustomerList, getCustomerType, getSalesOrderByCustomer } from "../utils/apiFunctions/customermanagement";

import { get } from "../utils/apiFunctions";
import { getInventoryHistory } from "../utils/apiFunctions/inventoryHistory";

export const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const { tokens } = useContext(AuthContext);
  const initCount = {
    Products: 0,
    "Pending Order": 0,
  };
  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isProductsUpdated, setIsProductsUpdated] = useState(false);

  const [count, setCount] = useState(initCount);
  const [products, setProducts] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [orderListByCustomer, setOrderListByCustomer] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeRoleList, setEmployeeRoleList] = useState([]);
  const [applicantList, setApplicantList] = useState([]);

  const [unitList, setUnitList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [supplierByKey, setSupplierByKey] = useState({});
  const [purchaseList, setPurchaseList] = useState([]);
  const [purchaseByKey, setPurchaseByKey] = useState({});
  const [customerTypeList, setCustomerTypeList] = useState([]);
  const [customerList, setCustomerList] = useState([]);

  async function fetchProducts() {
    setIsLoading(true);
    await getProducts().then((res) => {
      setProducts(res);
      setIsLoading(false);
    });
  }
  async function fetchCustomerTypeList() {
    setIsLoading(true);
    await getCustomerType().then((res) => {
      setCustomerTypeList(res);
      setIsLoading(false);
    });
  }

  async function fetchCustomerList() {
    setIsLoading(true);
    await getCustomerList().then((res) => {
      setCustomerList(res);
      setIsLoading(false);
    });
  }



  async function fetchOrdersByCustomer(customer_key) {
    setIsLoading(true);
    await getSalesOrderByCustomer(customer_key).then((res) => {
      setOrderListByCustomer(res);
      setIsLoading(false);
      console.log(res);
    });
  }

  async function fetchEmployees() {
    setIsLoading(true);
    await get(`/admin/employee`,).then(([status,res]) => {
      setEmployeeList(res);
      setIsLoading(false);
    });
  }
  async function fetchEmployeeRoleList() {
    setIsLoading(true);
    await get(`/admin/employee/role`,).then(([status,res]) => {
      setEmployeeRoleList(res);
      setIsLoading(false);
    });
  }
  async function fetchSupplierList() {
    setIsLoading(true);
    await getSupplierList(tokens?.idToken).then((res) => {
      setSupplierList(res);
      setIsLoading(false);
    });
  }

  async function fetchSupplierByKey(supplier_key) {
    await getSupplierByKey(supplier_key).then((res) => {
      setSupplierByKey(res);
    });
  }

  async function fetchPurchaseList() {
    setIsLoading(true);
    await getPurchaseList(tokens?.idToken).then((res) => {
      setPurchaseList(res);
      setIsLoading(false);
    });
  }

  async function fetchPurchaseByKey(purchase_key) {
    await getPurchaseByKey(purchase_key).then((res) => {
      setPurchaseByKey(res);
    });
  }

  async function fetchUnitList() {
    setIsLoading(true);
    await getUnitList(tokens?.idToken).then((res) => {
      setUnitList(res);
      setIsLoading(false);
    });
  }

  async function fetchCategoryList() {
    setIsLoading(true);
    await getCategoryList(tokens?.idToken).then((res) => {
      setCategoryList(res);
      setIsLoading(false);
    });
  }
  async function fetchProductTypeList() {
    setIsLoading(true);
    await getProductTypeList(tokens?.idToken).then((res) => {
      setProductTypeList(res);
      setIsLoading(false);
    });
  }
  async function fetchInventoryList(query_list = []) {
    setIsLoading(true);
    const query = [...query_list].join('&')
    await get(`/admin/inventory?${query}`).then(([status, res]) => {
      setInventoryList(res)
      setIsLoading(false)
    })
  }

  
 

  async function fetchApplicants() {
    setIsLoading(true);
    await get(`/admin/applicants`)
      .then(([status, res]) => {
        setApplicantList(res);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     fetchProduct();
  //     fetchOrders();
  //     fetchEmployees()
  //   }
  //   // eslint-disable-next-line
  // }, [isAuthenticated]);

  // useEffect(() => {
  //   if (isProductsUpdated) {
  //     fetchProduct();
  //     setIsProductsUpdated(false);
  //   }
  // }, [isProductsUpdated]);

  return (
    <StateContext.Provider
      value={{
        setCount,
        count,
        products,
        setProducts,
        isProductsUpdated,
        setIsProductsUpdated,
        orderList,
        orderListByCustomer,
        fetchOrdersByCustomer,
        setOrderList,
        isSideBarCollapsed,
        setIsSideBarCollapsed,
        employeeList,
        fetchProducts,
        fetchEmployees,
        isLoading,
        setIsLoading,
        applicantList,
        fetchApplicants,
        unitList,
        fetchUnitList,
        categoryList,
        fetchCategoryList,
        productTypeList,
        fetchProductTypeList,
        employeeRoleList,
        fetchEmployeeRoleList,
        inventoryList,
        fetchInventoryList,
        supplierList,
        fetchSupplierList,
        supplierByKey,
        fetchSupplierByKey,
        purchaseList,
        fetchPurchaseList,
        purchaseByKey,
        fetchPurchaseByKey,
        customerTypeList,
        fetchCustomerTypeList,
        customerList,
        fetchCustomerList,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
// export const t = 'ddddddd'