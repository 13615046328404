import React from "react";
import Header from "../../components/Header-dashboard";
import Form from "./forms";
import Window from "../../components/HomePage/Hero/Window";
import "./style.css";
export default function Index() {
  return (
    <>
      <Header />
      <Window _style={{ height: "auto" }}>
        <div className="m-5" id="">
          <div className="mb-5 apply_banner">
            <h1
              style={{
                background: "radial-gradient(#147AAB, #1D889D)",
                textAlign: "center",
                color: "white",
                textShadow: "2px 2px 4px #000000",
                fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                padding: "30px",
              }}
            >
              Application Form
              <p
                style={{
                  padding: "10px",
                  fontSize: "8px",
                  textAlign: "center",
                  color: "white",
                  textShadow: "2px 2px 4px #000000",
                  fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
                }}
              >
                *Please fill the details below. We will contact you as soon as
                possible
              </p>
            </h1>
            &nbsp;{" "}
          </div>
          <Form />
        </div>
      </Window>
    </>
  );
}
