import React, { useContext } from "react";
import {
  // BrowserRouter as Router,
  Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import History from "../components/History";
// auth
import LogIn from "../pages/LogIn";
import SetNewPassword from "../pages/LogIn/SetNewPassword";
// home
import Home from "../pages/Home";
// inventory
import ProductForm from "../pages/Inventory/Products -depricated/ProductForm";
import ProductsDepricated from "../pages/Inventory/Products -depricated";

import Products from "../pages/Products";
import AddToEcom from "../pages/Products/AddToEcom";
import Inventory from "../pages/Inventory";
import AddProduct from "../pages/Inventory/AddProduct";
import InventoryHistory from "../pages/Inventory/InventoryHistory";
import Production from "../pages/Production";
import CreateProduct from "../pages/Production/CreateProduct";
// tools
import ToolsCoconutSampling from "../pages/Tools/Coconut/Sampling";

import SalesOrders from "../pages/salesOrders";
import Profile from "../pages/Profile";
import Region from "../pages/Area/Region";
import AddRegion from "../pages/Area/Region/AddRegion";
import Territory from "../pages/Area/Territory";
import AddTerritory from "../pages/Area/Territory/AddTerritory";
import District from "../pages/Area/District";
import AddDistrict from "../pages/Area/District/AddDistrict";
import Pincode from "../pages/Area/Pincode";
import AddPincode from "../pages/Area/Pincode/AddPincode";
import Site from "../pages/Area/Site";
import AddSite from "../pages/Area/Site/AddSite";
import Category from "../pages/Category";
import AddCategory from "../pages/Category/AddCategory";
import Unit from "../pages/Unit";
import AddUnit from "../pages/Unit/AddUnit";
import ProductType from "../pages/Product Type";
import AddProductType from "../pages/Product Type/AddProductType";
import CarousalSettings from "../pages/WebsiteSettings/carousal";
import EmployeeList from "../pages/EmployeeManagement";
import AddEmployee from "../pages/EmployeeManagement/AddEmployee";
import EmployeeRoleList from "../pages/EmployeeRole";
import AddEmployeeRole from "../pages/EmployeeRole/AddRole";
import AddSupplier from "../pages/Procurement/Supplier/AddSupplier";
import Supplier from "../pages/Procurement/Supplier";
import AddPurchase from "../pages/Procurement/PurchaseOrder/AddPurchase";
import PurchaseOrder from "../pages/Procurement/PurchaseOrder";
import Approval from "../pages/Procurement/Approval";
import SupplierDetails from "../pages/Procurement/Supplier/SupplierList/SupplierView";
import PurchaseDetails from "../pages/Procurement/PurchaseOrder/Purchase";

import CustomerDetails from "../pages/CustomerManagement/Customer";
import Customer from "../pages/CustomerManagement";
import AddCustomer from "../pages/CustomerManagement/AddCustomer";
import Applicants from "../pages/Applicants";
import Layout from "../layout";
import ViewOrderDetail from "../pages/salesOrders/ViewDetail";
import { AuthContext } from "../context/AuthContext";
import PrivateRoute from "./PrivateRoute";
import LoadingScreenV1 from "../components/LoadingScreen/V1";
import LandingPage from "../pages/Landing";

//career and apply
import Career from "../pages/career";
import JobDescription from "../pages/career/JobDescription";
import Apply from "../pages/apply";
export default function Index() {
  const { isAuthenticated, user, isAuthenticating } = useContext(AuthContext);

  return (
    <>
      {!isAuthenticating ? (
        <Router history={History}>
          <Switch>
            <Route exact path="/career" component={() => <Career />} />
            <Route
              exact
              path="/career/:position"
              component={({ match }) => (
                <JobDescription position={match.params.position} />
              )}
            />

            <Route exact path="/apply" component={() => <Apply />} />

            <Route exact path="/" component={() => <LandingPage />} />

            <Route exact path="/logIn">
              {isAuthenticated ? <Redirect to="/dashboard" /> : <LogIn />}
            </Route>

            <Route exact path="/setNewPassword">
              {isAuthenticated ? (
                <Redirect to="/dashboard" />
              ) : user === null ? (
                <Redirect to="/logIn" />
              ) : (
                <SetNewPassword />
              )}
            </Route>

            <Layout>
              <PrivateRoute
                exact
                path="/dashboard"
                component={() => <Home />}
              />

              {/* Inventory */}

              <PrivateRoute
                exact
                path="/inventory"
                component={() => <Inventory />}
              />
              <PrivateRoute
                exact
                path="/inventory/addProduct"
                component={() => <AddProduct />}
              />
              <PrivateRoute
                exact
                path="/production"
                component={() => <Production />}
              />
              <PrivateRoute
                exact
                path="/production/createProduct"
                component={() => <CreateProduct />}
              />
              <PrivateRoute
                exact
                path="/inventory/history"
                component={() => <InventoryHistory />}
              />
              {/* ------------------------------------------------ */}
              {/* <PrivateRoute
                exact
                path="/o/addProduct"
                component={() => <ProductForm />}
              />
              <PrivateRoute
                exact
                path="/o/products"
                component={() => <ProductsDepricated />}
              />

              <PrivateRoute
                exact
                path="/editProduct/:productKey"
                component={({ match }) => (
                  <ProductForm productKey={match.params.productKey} />
                )}
              /> */}
              {/* ------------------------------------------------ */}

              {/* ecommerce */}
              <PrivateRoute
                exact
                path="/products"
                component={() => <Products />}
              />
              <PrivateRoute
                exact
                path="/edit/ecomProduct/:ecommerce_site_id"
                component={({match}) => <AddToEcom ecomSiteId={match.params.ecommerce_site_id}/>}
              />
              <PrivateRoute
                exact
                path="/addToEcom"
                component={() => <AddToEcom />}
              />

              {/* end Inventory */}

              <PrivateRoute
                exact
                path="/myProfile"
                component={() => <Profile />}
              />

              <PrivateRoute
                exact
                path="/viewOrderDetail/:orderKey"
                component={({ match }) => (
                  <ViewOrderDetail orderKey={match.params.orderKey} />
                )}
              />

              <PrivateRoute
                exact
                path="/salesOrder/:orderStatus"
                component={({ match }) => (
                  <SalesOrders orderStatus={match.params.orderStatus} />
                )}
              />

              {/* <PrivateRoute
                exact
                path="/employee"
                component={() => <EmployeeList />}
              /> */}

              {/* <PrivateRoute
                exact
                path="/employee/role"
                component={() => <EmployeeRoleList />}
              /> */}

              {/* <PrivateRoute
                exact
                path="/employee/addRole"
                component={() => <AddEmployeeRole />}
              />
              <PrivateRoute
                exact
                path="/employee/editRole/:roleId"
                component={({ match }) => <AddEmployeeRole roleId={match?.params?.roleId} />}
              /> */}
              {/* <PrivateRoute
                exact
                path="/addEmployee"
                component={() => <AddEmployee />}
              /> */}
              {/* <PrivateRoute
                exact
                path="/editEmployee/:employeeId"
                component={({ match }) => <AddEmployee employeeId={match?.params?.employeeId} />}
              /> */}

              {/* customer management */}

              {/* <PrivateRoute
                exact
                path="/customer"
                component={() => <Customer />}
              /> */}

              {/* <PrivateRoute
                exact
                path="/customer/:customerKey"
                component={({ match }) => (
                  <CustomerDetails customerKey={match.params.customerKey} />
                )}
              />
              <PrivateRoute
                exact
                path="/editCustomer/:customerKey"
                component={({ match }) => (
                  <AddCustomer customerKey={match.params.customerKey} />
                )}
              /> */}

              {/* <PrivateRoute
                exact
                path="/addCustomer"
                component={() => <AddCustomer />}
              /> */}

              {/* applicants */}
              <PrivateRoute
                exact
                path="/applicants"
                component={() => <Applicants />}
              />

              {/* areas */}
              {/* <PrivateRoute
                exact
                path="/territory"
                component={() => <Territory />}
              />
              <PrivateRoute
                exact
                path="/addTerritory"
                component={() => <AddTerritory />}
              />
              <PrivateRoute
                exact
                path="/editTerritory/:id"
                component={({ match }) => (
                  <AddTerritory territory_id={match.params.id} />
                )}
              /> */}
              {/* district */}
              {/* <PrivateRoute
                exact
                path="/district"
                component={() => <District />}
              />
              <PrivateRoute
                exact
                path="/addDistrict"
                component={() => <AddDistrict />}
              />
              <PrivateRoute
                exact
                path="/editDistrict/:id"
                component={({ match }) => (
                  <AddDistrict district_id={match.params.id} />
                )}
              /> */}
              {/* pincode */}
              {/* <PrivateRoute
                exact
                path="/pincode"
                component={() => <Pincode />}
              />
              <PrivateRoute
                exact
                path="/addPincode"
                component={() => <AddPincode />}
              />
              <PrivateRoute
                exact
                path="/editPincode/:id"
                component={({ match }) => (
                  <AddPincode pincode_id={match.params.id} />
                )}
              /> */}
              {/* Region */}
              {/* <PrivateRoute exact path="/region" component={() => <Region />} />
              <PrivateRoute
                exact
                path="/addRegion"
                component={() => <AddRegion />}
              />
              <PrivateRoute
                exact
                path="/editRegion/:regionId"
                component={({ match }) => <AddRegion regionId={match?.params?.regionId} />}
              />
              <PrivateRoute exact path="/site" component={() => <Site />} />
              <PrivateRoute
                exact
                path="/addSite"
                component={() => <AddSite />}
              />
              <PrivateRoute
                exact
                path="/editSite/:siteId"
                component={({ match }) => <AddSite siteId={match?.params?.siteId} />}
              /> */}
              {/* product type */}
              {/*---------------- category=-------------------- */}
              <PrivateRoute
                exact
                path="/category"
                component={() => <Category />}
              />
              <PrivateRoute
                exact
                path="/addCategory"
                component={() => <AddCategory />}
              />
              <PrivateRoute
                exact
                path="/editCategory/:categoryId"
                component={({ match }) => (
                  <AddCategory categoryId={match?.params?.categoryId} />
                )}
              />
              {/*---------------- category=-------------------- */}
              <PrivateRoute exact path="/unit" component={() => <Unit />} />
              <PrivateRoute
                exact
                path="/addUnit"
                component={() => <AddUnit />}
              />
              <PrivateRoute
                exact
                path="/editUnit/:unitId"
                component={({ match }) => <AddUnit unitId={match?.params?.unitId} />}
              />
              <PrivateRoute
                exact
                path="/productType"
                component={() => <ProductType />}
              />
              <PrivateRoute
                exact
                path="/addProductType"
                component={() => <AddProductType />}
              />
              <PrivateRoute
                exact
                path="/editProductType/:productTypeId"
                component={({ match }) => (
                  <AddProductType productTypeId={match.params.productTypeId} />
                )}
              />

              {/* Procurement */}

              {/* <PrivateRoute
                exact
                path="/supplier"
                component={() => <Supplier />}
              />

              <PrivateRoute
                exact
                path="/addSupplier"
                component={() => <AddSupplier />}
              />

              <PrivateRoute
                exact
                path="/editSupplier/:supplierKey"
                component={({ match }) => <AddSupplier supplierKey={match?.params?.supplierKey} />}
              />

              <PrivateRoute
                exact
                path="/addpurchase"
                component={() => <AddPurchase />}
              />

              <PrivateRoute
                exact
                path="/purchase"
                component={() => <PurchaseOrder />}
              />

              <PrivateRoute
                exact
                path="/purchaseview/:purchase_key"
                component={({ match }) => (
                  <PurchaseDetails purchase_key={match.params.purchase_key} />
                )}
              />

              <PrivateRoute
                exact
                path="/approve"
                component={() => <Approval />}
              />

              <PrivateRoute
                exact
                path="/view/:supplier_key"
                component={({ match }) => (
                  <SupplierDetails supplier_key={match.params.supplier_key} />
                )}
              /> */}

              {/* WebsiteSettings */}
              <PrivateRoute
                exact
                path="/websiteSetting/carousal"
                component={() => <CarousalSettings />}
              />

              {/* tools */}
              {/* <PrivateRoute
                exact
                path="/tools/coconutSampling"
                component={() => <ToolsCoconutSampling />}
              /> */}
              {/* tools end */}
            </Layout>
          </Switch>
        </Router>
      ) : (
        <LoadingScreenV1 />
      )}
    </>
  );
}
